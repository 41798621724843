import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { frontEnd_API } from '../../Config/Config';

function HomeSlider() {
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 30000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     vertical: true,
    //     verticalSwiping: true,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     cssEase: 'linear',
    //     pauseOnHover: false
    // };

    const [domainText, setDomainText] = useState('');
    const [domainValue, setDomainValue] = useState('');
    const [message, setMessage] = useState();
    const [options, setOptions] = useState([]);

    const checkDomain = async () => {
        if (domainText.length > 0) {
            try {
                const { data } = await axios.put(frontEnd_API?.checkDomain, {
                    "domain": domainText
                }, {
                    headers: {
                        'token': 'essentials',
                        'Content-Type': 'application/json'
                    }
                })
                setMessage({
                    value: `"${data?.data?.domain}" is available !`,
                    style: 'bg-success'
                })
                setDomainValue(data?.data?.domain);
                setOptions([]);
                console.log("data::", data);
            }
            catch (e) {
                console.log("err::", e);
                if (e?.response?.status === 406) {
                    toast(`"${e?.response?.data?.data?.domain} !"`);
                    setMessage();
                }
                if (e?.response?.status === 400) {
                    setMessage({
                        value: `${e?.response?.data?.message} !`,
                        style: 'bg-danger'
                    })
                    setOptions(e?.response?.data?.data?.suggestionDomain)
                }
            }
        }
        else {
            toast('Fill value first !');
        }
    }

    const handleSelection = (item) => {
        setDomainText(item.label);
        setMessage({
            value: `"${item.value}" is available !`,
            style: 'bg-success'
        })
        setDomainValue(item.value);
        setOptions([]);
    }

    useEffect(() => {
        console.log("domainText::", domainText);
        console.log(window.location.host);

    }, [domainText])

    return (
        <div className='custom-slider'>
            {/* <Container>
                <Row className='py-0'>
                    <Col className='col-12 m-0 col-lg-7 custom-slider-content'>
                        <section className='d-grid'>
                            <div>
                                <h1 className='fw-900 py-2 py-sm-3 fs-md-20'>Expand with <span>Printfuse's</span> <br /> On-Demand <span>Products</span></h1>
                            </div>
                            <div>
                                <h5 className='fs-md-14'>Design and market custom on-demand products without inventory with <span>Printfuse</span></h5>
                            </div>
                            <div className='d-flex gap-2 py-3'>
                                <div className='flex-start-align gap-2'>
                                    <i className="bi bi-check2 color-info fs-18 fs-md-14"></i>
                                    <h5 className='fs-md-14 fs-14 m-0'>Verified suppliers</h5>
                                </div>
                                <div className='flex-start-align gap-2'>
                                    <i className="bi bi-check2 color-info fs-18 fs-md-14"></i>
                                    <h5 className='fs-md-14  fs-14 m-0'>Produced in the IND</h5>
                                </div>
                                <div className='flex-start-align gap-2'>
                                    <i className="bi bi-check2 color-info fs-18 fs-md-14"></i>
                                    <h5 className='fs-md-14  fs-14 m-0'>Delivered in 3 days</h5>
                                </div>
                            </div>
                            <div className='d-grid d-sm-flex gap-2 custom-slider-btn pe-lg-3'>
                                <Link to={'/'} className='custom-slider-start fw-semibold'>Customized now</Link>
                                <Link to={'/'} className='custom-slider-end fw-semibold'>How It Works</Link>
                            </div>
                        </section>
                    </Col>
                    <Col className='col-12 m-0 col-lg-5 custom-slider-container-style pt-4 pt-lg-0'>
                        <Slider {...settings}>
                            {
                                sliderData.map((item, index) => {
                                    return (
                                        <div key={index} className='custom-slide-style'>
                                            <img src={item.image} alt="" />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </Col>
                </Row>
            </Container> */}
            <Container fluid className='p-0 p-lg-3'>
                <Container fluid className='custom-img-container p-0'>
                    <Container className='custom-img-section'>
                        <div className='custom-img-section-contain-color'></div>
                        <div className='custom-img-section-contain'></div>
                        <div className='custom-img-section-contain-blend-center'></div>
                        <div className='custom-img-section-contain-blend-left'></div>
                        <div className='custom-img-section-contain-blend-right'></div>
                        <div className='custom-slider-content pt-5 gap-4 gap-sm-4 d-grid'>
                            {/* Content Part */}
                            <div className='custom-slider-content-list flex-center-align align-items-start'>
                                <div className='custom-slider-content-list-first lg-max-w-750px max-w-600px gap-4 d-grid' data-aos="fade-up" data-aos-delay="500">
                                    <img src={require('../../Assets/Images/hover-image-1.png')} className='hover-image-first' alt="" />
                                    <img src={require('../../Assets/Images/hover-image-2.png')} className='hover-image-second' alt="" />
                                    <div className='flex-center-align'>
                                        <div className='custom-home-title'>
                                            <GoDotFill className='fs-12' />
                                            <span className='fs-12'>Beta 2.0 Available Now</span>
                                        </div>
                                    </div>
                                    <div className='text-center custom-slider-heading d-grid gap-4 px-4 px-sm-0'>
                                        <h1 className='display-5 m-0'>
                                            Build websites <br />
                                            <span>effortlessly</span><br />
                                            now or never!
                                        </h1>
                                        <p className='m-0 d-none d-md-block'>Easy-to-use and powerful page builder, your gateway to intuitive tools and expansive customization options that transform how you build websites.</p>
                                    </div>
                                    <div className='d-grid custom-search-list-box gap-2 gap-sm-3'>
                                        {/* <Link className='gap-2 fw-medium custom-started-btn fs-18 fs-sm-16'>Get Started <i className="bi bi-arrow-right"></i></Link> */}
                                        <div className='flex-center-align'>
                                            <div className="searchBox">
                                                <input
                                                    className="searchInput fs-20 fs-sm-16 p-2 px-3 p-sm-3 px-sm-4"
                                                    type="text"
                                                    placeholder="Search domain..."
                                                    value={domainText}
                                                    onChange={(e) => {
                                                        setDomainText(e?.target?.value);
                                                        setMessage()
                                                    }
                                                    }
                                                />
                                                <button
                                                    className="searchButton"
                                                    onClick={checkDomain}
                                                >
                                                    <i className="bi bi-search fs-20 text-light"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='d-grid custom-success-alert gap-3'>
                                            {
                                                (message) &&
                                                <div className='flex-center-align'>
                                                    <span className={`alert-message fw-medium fs-14 fs-sm-12 py-1 px-3 flex-center-align gap-2 lh-sm ${message?.style}`}
                                                    >
                                                        <i className={`bi ${(message?.style === 'bg-success' ? 'bi-check' : 'bi-x-lg text-danger')} fs-12`}></i>
                                                        {message?.value}
                                                    </span>
                                                </div>
                                            }

                                            {
                                                (options?.length > 0) &&
                                                <>
                                                    <div className='flex-center-align'>
                                                        <span className='text-center text-light'>----- OR -----</span>
                                                    </div>
                                                    <div className='flex-center-align'>
                                                        <div className='option-list p-2 gap-2'>
                                                            <h6 className='fw-medium text-center my-0 py-1'>Select from below option : </h6>
                                                            <ul className='p-0 gap-2 d-grid'>
                                                                {
                                                                    options.map((item, index) => {
                                                                        return (
                                                                            <li key={index} className='p-2 fs-16 fs-sm-14 text-center fw-medium lh-sm'
                                                                                onClick={() => {
                                                                                    handleSelection(item)
                                                                                }}
                                                                            >
                                                                                {item?.value}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {
                                                (message?.style === "bg-success") &&
                                                <div className='flex-center-align'>
                                                    <a
                                                        className='next-btn px-3 py-2 fw-semibold fs-20 fs-sm-16 flex-center-align gap-2'
                                                        href={(window.location.hostname === 'localhost')
                                                            ? `http://localhost:3001/from-home/${domainValue}`
                                                            : `https://app.printfuse.in/from-home/${domainValue}`
                                                        }
                                                    >
                                                        Next
                                                        <i className="bi bi-arrow-right-short"></i>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <span className='color-light-main text-center fs-14'>No credit cards required - 14 days free trial.</span>
                                </div>
                            </div>
                            {/* Image Part */}
                            <Container className='custom-slider-content-list-second cs-container-lg p-0' data-aos="fade-up">
                                <div className='img-holder'>
                                    <img src={require('../../Assets/Images/template/app-dashboard.png')} alt="" />
                                </div>
                            </Container>
                        </div>
                    </Container>
                </Container>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default HomeSlider