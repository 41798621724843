import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IoSparklesSharp } from "react-icons/io5";
import { AiOutlineProduct } from "react-icons/ai";
import { AiFillShop } from "react-icons/ai";
import { MdOutlineSavings } from "react-icons/md";
import { TbShirt } from "react-icons/tb";
import { CgWebsite } from "react-icons/cg";
import { BsTruck } from "react-icons/bs";
import { BsShop } from "react-icons/bs";
import { IoWalletOutline } from "react-icons/io5";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import Review from '../../Components/Review';

function SwitchToPrintfuse() {

    const benifits = [
        {
            image: <TbShirt />,
            title: 'High-quality products',
            description: 'Printfuse stands out as your go-to print provider with an unwavering commitment to high-quality products'
        },
        {
            image: <CgWebsite />,
            title: 'Ecommerce made sustainable',
            description: 'Using an on-demand fulfillment process means no product goes to waste'
        },
        {
            image: <BsTruck />,
            title: 'Fast shipping times',
            description: 'Offer the best delivery speed for both domestic and international shipping'
        },
        {
            image: <BsShop />,
            title: 'No order minimums',
            description: 'Flexibility is key to a stress-free ecommerce experience—enjoy the freedom of no minimum order requirements'
        },
        {
            image: <IoWalletOutline />,
            title: 'No upfront costs',
            description: 'Get started, design products, and sell online—all for free'
        },
        {
            image: <MdOutlineWorkspacePremium />,
            title: 'Premium options',
            description: 'If you want more, become a Printfuse member and unlock exclusive perks'
        },
    ];

    const points = [
        {
            title: 'Superior quality and happy customers',
            description: 'Provide premium quality products to customers around the world, with no differences in products or designs.'
        },
        {
            title: 'Premium designs in under an hour',
            description: 'Create elaborate designs with our free Design Maker. Whether you’re a novice or a pro, great designs are accessible to everyone.'
        },
        {
            title: 'Fast fulfillment with no extra work',
            description: 'When you get an order, it’s automatically imported into our system and sent to the optimal facility for fulfillment.'
        },
        {
            title: 'Power to build your brand',
            description: 'We don’t put our name anywhere and your brand can shine on product labels, packaging inserts, tracking pages, and more.'
        },
        {
            title: 'Global customer base at your fingertips',
            description: 'Sell anywhere—we’ve got 13 facilities across the globe to bring you closer to your audience.'
        },
        {
            title: 'Diverse range of product categories',
            description: 'Grow a versatile product catalog with 5 types of printing and embroidery—from the most popular techniques to unique innovations.'
        },
    ]

    const rating = [
        {
            name: 'Alex Martinez',
            website: 'Urban Threads Co.',
            description: "Printfuse has been a game-changer for our business over the past two years. The ease of use, high-quality products, and reliable shipping have made it incredibly simple for us to deliver exceptional clothing and accessories to our customers. We appreciate the continuous updates and new features that keep enhancing our experience with Printfuse.",
            image: require('../../Assets/Images/Andris-Mucenieks.jpg')
        },
        {
            name: 'Priya Kapoor',
            website: 'Artistic Impressions',
            description: "We've been partnering with Printfuse for about a year now, and it has significantly boosted our brand's reach. The ability to customize a wide range of products, coupled with efficient shipping, has delighted our customers. We are thrilled with the new product additions and features that Printfuse keeps introducing.",
            image: require('../../Assets/Images/animal-rescues.png')
        },
        {
            name: "Liam O'Sullivan",
            website: 'Celtic Creations',
            description: "For the past 18 months, Printfuse has been instrumental in helping us provide top-notch merchandise to our audience. The platform's user-friendly interface, combined with their high-quality print-on-demand products and timely shipping, has made our business thrive. We love how Printfuse is always innovating and adding new features to support our growth.",
            image: require('../../Assets/Images/Anita-Njoki.jpg')
        }
    ]

    return (
        <div className='switch-to-printfuse'>

            <div className='pb-5'>
                <div className='switch-to-printfuse-slider'>
                    <Container>
                        <Row className='pf-flex-md-col-reverse'>
                            <Col className='col-12 col-md-7 d-grid align-content-center py-5'>
                                <div className='d-grid gap-4'>
                                    <div className='d-grid gap-3 gap-md-2 text-center text-md-start pe-md-5'>
                                        <h1 className='fw-bold m-0'>Switch to Printfuse</h1>
                                        <h5 className='m-0 '>Make the switch from subpar providers and work with a reliable partner</h5>
                                    </div>
                                    <div className='custom-click-btn d-grid d-md-flex gap-3'>
                                        <a
                                            href={(window.location.hostname === 'localhost')
                                                ? `http://localhost:3001/signup`
                                                : `https://app.printfuse.in/signup`
                                            }
                                            className='fw-semibold btn-second'>Sign up<i className="bi bi-person-circle" /></a>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-5 pt-3 pt-sm-5 pt-md-0'>
                                <div className='custom-big-img-container'>
                                    <img src={require('../../Assets/Images/9b8b601ecddf88436ad8b1be0465ed6e_l__1200.jpeg')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className='py-5'>
                <div className='d-grid gap-2 text-center px-3 pt-0 pt-sm-4'>
                    <h2 className='m-0 fw-semibold'>Secure long-term success for your brand</h2>
                    <p className='p-color fs-18 m-0 fs-sm-16'>Four reasons to go all in with Printfuse</p>
                </div>
                <div className='flex-center-align'>
                    <div className='custom-switch-info cs-container-lg flex-center-align py-3 pb-4 px-1 px-md-0'>
                        <Container className='custom-switch-info-container py-5 px-3 px-sm-5 m-0'>
                            <Row className='gy-4'>
                                <Col className='col-12 col-md-6'>
                                    <div className='d-grid gap-2 d-md-flex'>
                                        <div className='text-center'>
                                            <IoSparklesSharp className='fs-45' />
                                        </div>
                                        <div className='d-grid gap-2 text-center text-md-start'>
                                            <h6 className='m-0 fw-semibold fs-sm-16'>Unmatched product quality</h6>
                                            <p className='p-color m-0 fs-14 fs-sm-14'><b>Only 0.24% of our orders</b> have to be re-shipped due to quality concerns</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <div className='d-grid gap-2 d-md-flex'>
                                        <div className='text-center'>
                                            <AiOutlineProduct className='fs-45' />
                                        </div>
                                        <div className='d-grid gap-2 text-center text-md-start'>
                                            <h6 className='m-0 fw-semibold fs-sm-16'>Unmatched product quality</h6>
                                            <p className='p-color m-0 fs-14 fs-sm-14'><b>Only 0.24% of our orders</b> have to be re-shipped due to quality concerns</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <div className='d-grid gap-2 d-md-flex'>
                                        <div className='text-center'>
                                            <AiFillShop className='fs-45' />
                                        </div>
                                        <div className='d-grid gap-2 text-center text-md-start'>
                                            <h6 className='m-0 fw-semibold fs-sm-16'>Unmatched product quality</h6>
                                            <p className='p-color m-0 fs-14 fs-sm-14'><b>Only 0.24% of our orders</b> have to be re-shipped due to quality concerns</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6'>
                                    <div className='d-grid gap-2 d-md-flex'>
                                        <div className='text-center'>
                                            <MdOutlineSavings className='fs-45' />
                                        </div>
                                        <div className='d-grid gap-2 text-center text-md-start'>
                                            <h6 className='m-0 fw-semibold fs-sm-16'>Unmatched product quality</h6>
                                            <p className='p-color m-0 fs-14 fs-sm-14'><b>Only 0.24% of our orders</b> have to be re-shipped due to quality concerns</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <div className='py-5'>
                <div>
                    <Container className='custom-store-banner'>
                        <Row className='gy-4 pf-flex-md-col-reverse'>
                            <Col className='col-12 col-md-7 d-grid align-content-center'>
                                <div className='custom-btn-holder d-grid p-3 p-sm-4 pe-3 pe-sm-5 gap-3 gap-md-4 text-center text-md-start'>
                                    <h2 className='m-0 fw-semibold'>Personal approach</h2>
                                    <p className='m-0 p-color fs-20 fs-sm-16 fs-md-20'>Moving your products feels time-consuming and daunting? We’ll do it for you. Apply below, and we’ll be in touch.</p>
                                    <div className='flex-center-align justify-content-center justify-content-md-start'>
                                        <button className='fw-semibold'>Claim offer</button>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-5 d-grid p-0'>
                                <div className='custom-img-holder'>
                                    <img src={require('../../Assets/Images/0b95044ce4d81b67965a8d308f812acb__562.jpeg')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Container className='py-5'>
                <Row className='align-items-center gy-3'>
                    <Col className='col-12 col-md-6'>
                        <div className='text-center text-md-start'>
                            <h2 className='fw-semibold'>We integrate with:</h2>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-6'>
                        <Row className='gy-3'>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (37).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (42).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (38).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (39).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (40).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-4 col-md-2'>
                                <div className='d-grid store-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (41).png')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <div className='py-5'>
                <div className='custom-back-dark py-4'>
                    <Container className='py-5 cs-container-xl'>
                        <Row className='gy-3 gy-sm-4'>
                            <h2 className='fw-semibold text-center px-3 m-0 py-2 py-sm-5'>Why print with Printfuse and not others</h2>
                            {
                                benifits.map((item, index) => {
                                    return (
                                        <Col key={index} className='col-12 col-sm-6 col-lg-4 d-grid'>
                                            <div className={`custom-bg-color color-${index + 1} d-flex gap-3 pf-flex-sm-row-reverse p-3 p-md-4`}>
                                                <div className='d-grid gap-2 custom-cnt align-content-between'>
                                                    <h5 className='fw-semibold m-0'>{item?.title}</h5>
                                                    <p className='m-0 fs-14'>{item?.description}</p>
                                                </div>
                                                <div className='custom-img d-grid align-items-start fs-sm-45'>{item?.image}</div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                    <Container className='px-4 py-5 cs-container-xl'>
                        <Row className='custom-info-banner py-4 py-sm-5 px-3 px-sm-5'>
                            <Col className='col-12 col-lg-6 d-grid align-content-center'>
                                <h2 className='m-0 fw-bold text-center text-lg-start fs-sm-20'>The best print-on-demand provider for a reason</h2>
                            </Col>
                            <Col className='col-12 col-lg-6 d-grid pt-4 pt-lg-0'>
                                <Row className='gy-5'>
                                    <Col className='col-6 d-grid'>
                                        <h2 className='fw-bold m-0 fs-sm-20'>348+</h2>
                                        <p className='m-0 fw-lighter fs-14 fs-sm-12'>Product count</p>
                                    </Col>
                                    <Col className='col-6 d-grid'>
                                        <h2 className='fw-bold m-0 fs-sm-20'>86M+</h2>
                                        <p className='m-0 fw-lighter fs-14 fs-sm-12'>Items shipped since 2013</p>
                                    </Col>
                                    <Col className='col-6 d-grid'>
                                        <h2 className='fw-bold m-0 fs-sm-20'>99%</h2>
                                        <p className='m-0 fw-lighter fs-14 fs-sm-12'>Customer quality satisfaction</p>
                                    </Col>
                                    <Col className='col-6 d-grid'>
                                        <h2 className='fw-bold m-0 fs-sm-20'>1M+</h2>
                                        <p className='m-0 fw-lighter fs-14 fs-sm-12'>Items fulfilled every month</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className='py-5'>
                <div className='d-grid gap-2 text-center px-3 pt-0 py-sm-4'>
                    <h2 className='m-0 fw-semibold'>Why choose Printfuse?</h2>
                </div>
                <div className='flex-center-align'>
                    <div className='custom-points-info cs-container-lg flex-center-align py-3 pb-4 px-1 px-md-0'>
                        <Container className='custom-points-info-container py-5 px-3 px-sm-5 m-0'>
                            <Row className='gy-4'>
                                {
                                    points.map((item, index) => {
                                        return (
                                            <Col key={index} className='col-12 col-md-6'>
                                                <div className='gap-2 d-flex'>
                                                    <div className='text-center d-flex align-items-start'>
                                                        <i className="bi bi-check-lg fs-25 d-flex align-items-start color-info" />
                                                    </div>
                                                    <div className='d-grid gap-2 text-start'>
                                                        <h6 className='m-0 fw-semibold fs-sm-14'>{item.title}</h6>
                                                        <p className='p-color m-0 fs-14 fs-sm-12'>{item.description}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <div className='pt-5'>
                <Review review={rating} />
            </div>
        </div>
    )
}

export default SwitchToPrintfuse
