import UserAccountDetail from "../View/UserPanel/UserAccountDetail";
import UserAddressPage from "../View/UserPanel/UserAddressPage";
import UserDashboard from "../View/UserPanel/UserDashboard";
import UserOrder from "../View/UserPanel/UserOrder";
import UserCart from "../View/UserPanel/UserCart";
import UserPassword from "../View/UserPanel/UserPassword";

const userRoutes = [
    {
        path: '/',
        element: <UserDashboard />
    },
    {
        path: '/account',
        element: <UserAccountDetail />
    },
    {
        path: '/address',
        element: <UserAddressPage />
    },
    {
        path: '/orders',
        element: <UserOrder />
    },
    {
        path: '/cart',
        element: <UserCart />
    },
    {
        path: '/password',
        element: <UserPassword />
    }
]

export default userRoutes;
