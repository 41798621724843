import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slices/UserSlice';
import storeReducer from './Slices/StoreSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    store: storeReducer
  },
});
