import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { GoDotFill } from 'react-icons/go';

function CategorySlider() {

    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 2,
    //     slidesToScroll: 2,
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //     ],
    // };
    // const catagorySliderData = [
    //     {
    //         title: 'Printed T-shirts',
    //         image: require('../../Assets/Images/card-templates-personal.jpg'),
    //     },
    //     {
    //         title: 'Printed Full Sleeve T-shirts',
    //         image: require('../../Assets/Images/catalog-thumb-homepage.jpg'),
    //     },
    //     {
    //         title: 'Printed T-shirts',
    //         image: require('../../Assets/Images/lega-fundraising.png'),
    //     },
    //     {
    //         title: 'Printed T-shirts',
    //         image: require('../../Assets/Images/card-templates-personal.jpg'),
    //     },
    // ];
    
    return (
        <div className='custom-features py-5'>
            {/* Profit Info Items */}
            {/* <Container className='py-5'>
                <Row className='g-4'>
                    <Col className='col-12 col-md-4 px-0 pe-md-2'>
                        <div className='d-grid text-center text-md-start gap-3'>
                            <div className='d-flex justify-content-center justify-content-md-start'>
                                <div className='custom-contain-img-banner'>
                                    <img src={require('../../Assets/Images/coin.jpg')} alt="" />
                                </div>
                            </div>
                            <h5 className='fw-semibold fs-sm-16 m-0'>Increased Profits</h5>
                            <p className='p-color fs-sm-12 m-0 fs-14'>Our competitive pricing, driven by print providers vying for your business, ensures you achieve higher margins.</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4 px-0 px-md-2'>
                        <div className='d-grid text-center text-md-start gap-3'>
                            <div className='d-flex justify-content-center justify-content-md-start'>
                                <div className='custom-contain-img-banner'>
                                    <img src={require('../../Assets/Images/rocket.jpg')} alt="" />
                                </div>
                            </div>
                            <h5 className='fw-semibold fs-sm-16 m-0'>Efficient Scaling</h5>
                            <p className='p-color fs-sm-12 m-0 fs-14'>Seamlessly manage peak periods, thanks to our extensive partner network and automated routing capabilities.</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4 px-0 ps-md-2'>
                        <div className='d-grid text-center text-md-start gap-3'>
                            <div className='d-flex justify-content-center justify-content-md-start'>
                                <div className='custom-contain-img-banner'>
                                    <img src={require('../../Assets/Images/phone.jpg')} alt="" />
                                </div>
                            </div>
                            <h5 className='fw-semibold fs-sm-16 m-0'>Premier Selection</h5>
                            <p className='p-color fs-sm-12 m-0 fs-14'>Choose from over 900 high-quality products and top-tier brands to best meet your business needs.</p>
                        </div>
                    </Col>
                </Row>
            </Container> */}

            {/* Category Slider */}
            {/* <Container className='pb-5 custom-new-cat-slider-container'>
                <Slider {...settings}>

                    {
                        catagorySliderData.map((item, index) => {
                            return (
                                <div className='custom-new-cat-slider p-3 d-grid gap-2 gap-sm-3 align-content-start' key={index} style={{ backgroundImage: `url(${item.image})` }}>
                                    <h4 className='fw-semibold m-0'>{item.title}</h4>
                                    <div>
                                        <Link className='fw-semibold fs-14 fs-sm-10 py-1 px-2 py-sm-2 px-sm-3'>Explore now</Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </Container> */}
            <Container className='pt-4 py-sm-5 cs-container-xl'>
                <div className='flex-center-align pb-4 pb-sm-5' data-aos="fade-up">
                    <div className='lg-max-w-750px max-w-600px text-center d-grid gap-3 gap-sm-4'>
                        <div className='flex-center-align'>
                            <div className='custom-home-title-light'>
                                <GoDotFill className='fs-12' />
                                <span className='fs-12'>Main features</span>
                            </div>
                        </div>
                        <h2 className='m-0 h1 fw-semibold d-grid d-sm-flex justify-content-sm-center gap-2'>
                            <span className='flex-center-align gap-2'>
                                No
                                <span className='custom-diff-title '> coding skills </span>
                            </span>
                            required.
                        </h2>
                        <p className='m-0 p-color'>Unleash your creativity with our builder's powerful features and intuitive interface, experience the difference.</p>
                    </div>
                </div>
                <Row className='gy-4'>
                    <Col className='col-12 col-lg-7 d-grid' data-aos="fade-up">
                        <div className='custom-feature-list custom-feature-list-first d-grid border-rad-md-half'>
                            <div className='custom-feature-list-cnt p-4 p-sm-5 gap-4'>
                                <p className='m-0 fs-20 fs-sm-16'>Intuitive interface Drag-and-Drop for building pages by dragging and dropping elements.</p>
                                <div className='d-flex'>
                                    <Link className='flex-center-align gap-2'>Try it now <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                            <div className='custom-feature-list-img ps-4 ps-sm-5'>
                                <img src={require('../../Assets/Images/template/app-dashboard.png')} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-5 d-grid' data-aos="fade-up" data-aos-delay="500">
                        <div className='custom-feature-list custom-feature-list-second d-grid gap-4 border-rad-md-half'>
                            <div className='custom-feature-list-img p-4 p-sm-4 pb-0'>
                                <img src={require('../../Assets/Images/template/home-06-dashboard-components.png')} alt="" />
                            </div>
                            <div className='custom-feature-list-cnt p-4 gap-4'>
                                <h4 className='fw-meduim m-0'>Dashboard Components</h4>
                                <p className='m-0 fs-16 fs-sm-14'>Unlock your productivity potential with our intuitive and powerful Dashboard UI Components.</p>
                                <div className='d-flex'>
                                    <Link className='flex-center-align gap-2'>Let's find out <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-5 d-grid' data-aos="fade-up">
                        <div className='custom-feature-list custom-feature-list-third d-grid gap-4 border-rad-md-half'>
                            <div className='custom-feature-list-img p-4 p-sm-5 pt-0 pt-sm-0'>
                                <img src={require('../../Assets/Images/template/home-06-components.png')} alt="" />
                            </div>
                            <div className='custom-feature-list-cnt p-4 p-sm-5 gap-4'>
                                <h4 className='fw-meduim m-0'>UI Components</h4>
                                <p className='m-0 fs-16 fs-sm-14'>Easy-to-use and powerful page builder, your gateway to intuitive tools and expansive customization options that transform how you build websites.</p>
                                <div className='d-flex'>
                                    <Link className='flex-center-align gap-2'>Let's find out <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-7 d-grid' data-aos="fade-up" data-aos-delay="500">
                        <div className='custom-feature-list custom-feature-list-fourth d-grid border-rad-md-half'>
                            <div className='custom-feature-list-cnt p-4 p-sm-5 gap-4'>
                                <h4 className='m-0 fw-medium text-center'>Drag-and-Drop Interface</h4>
                                <p className='m-0 fs-20 fs-sm-16 text-center'>Unleash your creativity with our builder's intuitive UI Components, experience the difference.</p>
                            </div>
                            <div className='custom-feature-list-img p-4 p-sm-5 pt-0 pt-sm-0'>
                                <img src={require('../../Assets/Images/template/home-06-builder-tools.png')} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CategorySlider
