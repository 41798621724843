import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaRankingStar } from "react-icons/fa6";
import { RiTShirtAirLine } from "react-icons/ri";
import { FaShop } from "react-icons/fa6";
import { MdOutlineAutoGraph } from "react-icons/md";
import { TbTransactionRupee } from "react-icons/tb";
import { FaMobileAlt } from "react-icons/fa";
import { CiGift } from "react-icons/ci";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { LuLineChart } from "react-icons/lu";
import { Link } from 'react-router-dom';

function SellWithPrintfuse() {

    const featuresList = [
        {
            icon: <MdOutlineAutoGraph />,
            title: 'CUSTOM CAMPAIGNS',
            description: 'It’s easy to create a public campaign page that’s as unique as your community.'
        },
        {
            icon: <TbTransactionRupee />,
            title: 'SMART PROFIT MARGINS',
            description: 'As your sales increase, so do your profits. We’ll pass any savings we get on to you.'
        },
        {
            icon: <FaMobileAlt />,
            title: 'MOBILE FRIENDLY REPORTING',
            description: 'Track sales and orders with our mobile-friendly seller dashboard and email alerts.'
        },
        {
            icon: <CiGift />,
            title: 'FLEXIBLE FUNDRAISING OPTIONS',
            description: 'Turn any campaign into a fundraiser for a favorite cause with donations and goals.'
        },
        {
            icon: <AiOutlineSafetyCertificate />,
            title: 'TOP QUALITY PRINTS',
            description: 'Our designers ensure that your artwork prints exactly how you intended, 100% guaranteed.'
        },
        {
            icon: <LuLineChart />,
            title: 'SCALABLE PRODUCTION',
            description: "Whether you sell 10 or 10,000 items, we'll fulfill any volume of orders quickly and accurately."
        }
    ]

    const steps = [
        {
            title: 'DESIGN OR UPLOAD ARTWORK',
            description: 'Upload custom artwork or create your design right on Printfuse.',
            image: require('../../Assets/Images/campaign-hiw.jpg')
        },
        {
            title: 'PROMOTE AND SELL',
            description: 'Spread the word on social media while we handle everything else.',
            image: require('../../Assets/Images/homepage-sell-online.jpg')
        },
        {
            title: 'SHIRTS ARE SHIPPED',
            description: 'Sit back as orders are printed and sent directly to your buyers.',
            image: require('../../Assets/Images/hompage-order-custom.jpg')
        },
        {

            title: 'GET PAID',
            description: 'Get a fast payout of 100% of the campaign profits.',
            image: require('../../Assets/Images/trusted-1.jpg')
        }
    ];

    const whySell = [
        {
            title: 'Ecommerce platforms and marketplaces',
            description: 'Create a store website using platforms with premade templates, plug-ins, and more. Or create a storefront and sell to the established audiences of online marketplaces like Etsy and Amazon.',
            image: require('../../Assets/Images/step-promote-1.jpg')
        },
        {
            title: 'Social commerce',
            description: 'Make money by selling directly to your target audience on social media. Monetize your Facebook, Instagram, TikTok, and other channels.',
            image: require('../../Assets/Images/supply-hero.jpg')
        },
        {
            title: 'Custom API',
            description: 'Have an advanced ecommerce website? Connect your custom store to Printfuse with our API.',
            image: require('../../Assets/Images/Sell-T-Shirts-on-Etsy-1024x576.jpg')
        },
    ];


    return (
        <div className='sell-online-printfuse'>

            <div className='pb-5'>
                <div className='sell-online-printfuse-slider'>
                    <Container>
                        <Row className='pf-flex-md-col-reverse'>
                            <Col className='col-12 col-md-6 d-grid align-content-center py-5'>
                                <div className='d-grid gap-4'>
                                    <div className='d-grid gap-3 gap-md-2 text-center text-md-start'>
                                        <h1 className='fw-bold m-0'>Sell with Printfuse</h1>
                                        <h5 className='m-0 '>Start a business selling custom print-on-demand merchandise</h5>
                                    </div>
                                    <div className='custom-click-btn d-grid d-md-block'>
                                        <button className='fw-semibold'>Get Started<i class="bi bi-caret-right-fill" /></button>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-md-6 pt-3 pt-sm-5 pt-md-0'>
                                <div className='custom-big-img-container'>
                                    <img src={require('../../Assets/Images/slider back 1.png')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Container className='py-5 custom-info-items'>
                <Row className='gy-4 gy-md-0'>
                    <Col className='col-12 col-md-4'>
                        <div className='d-flex gap-3 px-2 custom-info-list'>
                            <FaRankingStar />
                            <p className='p-color fs-14 m-0 w-100'>Products rated <b>4.6/5</b> average out of 60,761 reviews</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4'>
                        <div className='d-flex gap-3 px-2 custom-info-list'>
                            <RiTShirtAirLine />
                            <p className='p-color fs-14 m-0 w-100'>Choose from <b>346 products</b> and create an unforgettable catalog</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4'>
                        <div className='d-flex gap-3 px-2 custom-info-list'>
                            <FaShop />
                            <p className='p-color fs-14 m-0 w-100'>Create your store on the world’s most <b>trusted ecommerce platforms and marketplaces</b></p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="custom-steps-container px-sm-5 py-5">
                <div className='flex-center-align custom-steps-container-width'>
                    <div className='d-grid'>
                        <h4 className='fw-semibold text-center py-4 py-sm-5'>How to sell shirts online with Printfuse</h4>
                        <div className='custom-steps ms-2 ms-sm-0'>
                            {
                                steps.map((item, index) => {
                                    return (
                                        <div key={index} className='custom-steps-item p-3 px-sm-4'>
                                            <div className='d-flex gap-2'>
                                                <div className='custom-img-hold'>
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className='d-grid custom-cnt-hold align-content-start gap-2'>
                                                    <h6 className='fw-semibold m-0'>{item?.title}</h6>
                                                    <p className='p-color fs-14 m-0'>{item?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='custom-steps-btn flex-center-align pt-3 pt-sm-4'>
                            <button className='fw-semibold'>Get started</button>
                        </div>
                    </div>
                </div>
            </Container>

            <div className='py-5'>
                <div className='custom-sell-feature py-5'>
                    <Container className='py-5'>
                        <Row className='gy-5'>
                            <h3 className='fw-semibold m-0 py-4 pb-2 pb-sm-4 text-center px-4'>Features to help you sell your shirts online</h3>
                            {
                                featuresList.map((item, index) => {
                                    return (
                                        <Col key={index} className='col-12 col-sm-6 col-lg-4'>
                                            <div className='d-grid text-center gap-3'>
                                                <div className='flex-center-align custom-sell-feature-icon'>
                                                    {item.icon}
                                                </div>
                                                <h6 className='fw-semibold m-0 font-capitalize'>{item.title}</h6>
                                                <p className='m-0 fs-14'>{item.description}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </div>
            </div>

            <div className='py-5'>
                <Container className='custom-place-banner'>
                    <Row>
                        <Col className='col-12 d-grid d-md-none pt-3 pt-sm-0'>
                            <div className='custom-img-holder'>
                                <img src={require('../../Assets/Images/Untitled design (34).png')} alt="" />
                            </div>
                        </Col>
                        <Col className='col-12 col-md-8 col-lg-9 d-grid align-content-center'>
                            <div className='d-grid p-3 p-sm-4 gap-2 text-center text-md-start'>
                                <h2 className='m-0 fw-semibold'>Choose a place for your online business</h2>
                                <p className='m-0  p-color fs-20 fs-md-16'>Use our ecommerce integrations to lay the groundwork for your business</p>
                            </div>
                        </Col>
                        <Col className='col-md-4  col-lg-3 d-none d-md-grid'>
                            <div className='custom-img-holder'>
                                <img src={require('../../Assets/Images/Untitled design (34).png')} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className='py-5 custom-why-sell'>
                <Row className='gy-4 gy-md-0'>
                    <h4 className='fw-semibold m-0 py-3 pb-4 text-center'>Why should you sell?</h4>
                    {
                        whySell.map((item, index) => {
                            return (
                                <Col className='col-12 col-md-4 d-grid'>
                                    <Link className='d-grid custom-why-sell-item'>
                                        <div className='custom-why-sell-item-img'>
                                            <img src={item?.image} alt="" />
                                        </div>
                                        <div className='p-3 text-center text-md-start d-grid gap-1 align-content-start'>
                                            <h6 className='fw-semibold m-0 fs-sm-14'>{item.title}</h6>
                                            <p className='p-color m-0 fs-14 fs-sm-12'>{item.description}</p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

            <div className='py-5'>
                <div className='custom-store-banner'>
                    <Container>
                        <Row className='gy-4'>
                            <Col className='col-12 col-md-5 d-grid pt-3 pt-sm-0'>
                                <div className='custom-img-holder'>
                                    <img src={require('../../Assets/Images/Untitled design (35).png')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-12 col-md-7 d-grid align-content-center'>
                                <div className='custom-btn-holder d-grid p-3 p-sm-4 gap-3 gap-md-4 text-center text-md-start'>
                                    <h2 className='m-0 fw-semibold'>Compare integrations for your ecommerce store</h2>
                                    <div className='flex-center-align justify-content-center justify-content-md-start'>
                                        <button className='fw-semibold'>Explore Integration</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className='py-5'>
                <Container className='custom-place-banner'>
                    <Row>
                        <Col className='col-12 d-grid d-md-none pt-3 pt-sm-0'>
                            <div className='custom-img-holder'>
                                <img src={require('../../Assets/Images/Untitled design (36).png')} alt="" />
                            </div>
                        </Col>
                        <Col className='col-12 col-md-8 col-lg-9 d-grid align-content-center'>
                            <div className='d-grid p-3 p-sm-4 gap-2 text-center text-md-start'>
                                <h2 className='m-0 fw-semibold'>Not sure where to sell? Take a quiz!</h2>
                                <p className='m-0  p-color fs-20 fs-md-16'>Find out which ecommerce platform or marketplace fits your business the best</p>
                                <div className='custom-btn-holder flex-center-align justify-content-center justify-content-md-start'>
                                    <button className='fw-semibold'>Take the quiz</button>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-md-4  col-lg-3 d-none d-md-grid'>
                            <div className='custom-img-holder'>
                                <img src={require('../../Assets/Images/Untitled design (36).png')} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SellWithPrintfuse
