import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

function BuyInBulkSlider(props = {}) {

    const [activeSlide, setActiveSlide] = useState(0);
    const [basePrice, setBasePrice] = useState(props.products[0]?.startPrice);
    const [rangeValue, setRangeValue] = useState([50]);
    const [disPer, setDisPer] = useState(0.07);
    const [disPrice, setDisPrice] = useState(parseFloat(basePrice * disPer).toFixed(2))

    const handleRangeChange = (e) => {
        if (!e.target.value) {
            setRangeValue(0);
        }
        else {
            setRangeValue(parseInt(e.target.value));
        }
    };

    const newSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "100px",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "90px",
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: "125px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerPadding: "75px",
                }
            },
            {
                breakpoint: 400,
                settings: {
                    centerPadding: "50px",
                }
            },
        ]
    };

    useEffect(() => {
        setBasePrice(props?.products[activeSlide]?.startPrice);
        setDisPrice(parseFloat(basePrice * disPer).toFixed(2));
        if (rangeValue > 500) {
            setDisPer(0.28);
        } else if (rangeValue > 400) {
            setDisPer(0.25);
        } else if (rangeValue > 300) {
            setDisPer(0.21);
        } else if (rangeValue > 200) {
            setDisPer(0.18);
        } else if (rangeValue > 100) {
            setDisPer(0.14);
        } else if (rangeValue > 50) {
            setDisPer(0.11);
        } else if (rangeValue > 25) {
            setDisPer(0.07);
        } else {
            setDisPer(0);
        }
    }, [activeSlide, basePrice, disPer, rangeValue, props?.products])


    return (
        <div className='custom-home-bulk'>
            <Container className='custom-home-bulk-container p-2 py-5'>
                <h3 className='fw-semibold m-0 text-center pb-5 pt-4'>Buy in bulk and save up to 55%</h3>
                <div className='flex-center-align'>
                    <Row className='custom-home-bulk-container-row p-2 p-sm-4 py-4 py-sm-5'>
                        <Col className='col-12 col-lg-6 px-0 px-sm-2'>
                            <div className="slider-container">
                                <Slider {...newSettings}>
                                    {
                                        props.products.map((item, index) => {
                                            return (
                                                <div key={index} className='text-center custom-sld-items d-grid gap-3'>
                                                    <div className='d-grid gap-2'>
                                                        <h5 className='m-0 fw-semibold'>{item?.shortName}</h5>
                                                        <Link className='fs-12'>{item?.shortName} | Printfuse P127845</Link>
                                                    </div>
                                                    <div className='product-border'>
                                                        <img src={item?.thumbnail} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-6 d-grid align-content-between custom-home-bulk-container-col'>
                            <b className='fw-semibold fs-14 fs-sm-12'>Bulk price calculator</b>

                            <input
                                type="range"
                                min={0}
                                max={500}
                                value={rangeValue}
                                onChange={handleRangeChange}
                            />

                            <div className='flex-between-align'>
                                <div className='flex-between-align custom-input-box'>
                                    <input type="number" className='p-2 py-1' value={rangeValue} onChange={handleRangeChange} />
                                    <span className='p-2 py-1 text-center'>items</span>
                                </div>
                                <span className='fw-bold text-success'>{parseFloat(disPer * 100).toFixed(2)}% discount</span>
                            </div>
                            <hr className='my-4' />
                            <div className='d-grid gap-3'>
                                <div className='px-3 py-2 custom-bg-color flex-between-align'>
                                    <span>Base price</span>
                                    <span className='fw-bold'><span className='fs-12 fw-light'>from </span>₹{basePrice}</span>
                                </div>
                                <div className='custom-success-bg d-grid gap-2 px-3 py-2'>
                                    <div className="d-grid d-sm-flex flex-between-align">
                                        <span>Discounted price</span>
                                        <span className='fw-bold custom-color-success'><span className='fs-12 fw-light text-dark'>from </span>₹{disPrice}</span>
                                    </div>
                                    <div className="d-grid d-sm-flex flex-between-align">
                                        <span><b>Total savings</b> per {rangeValue} items</span>
                                        <span className='fw-bold custom-color-success'><span className='fs-12 fw-light text-dark'>from </span>₹{parseFloat((disPrice) * rangeValue).toFixed(2)}</span>
                                    </div>
                                </div>
                                <Link className='custom-link-btn fw-semibold text-center'>Browse products</Link>
                                <p className='p-color fs-12'>This is an example, bulk discounts also apply to other products in this category</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default BuyInBulkSlider
