import React, { useState, useEffect } from 'react';
import { Col, Container, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { headerContent } from '../Data/localData';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { frontEnd_API } from '../Config/Config';

function Header() {
    const user = useSelector((state) => state.user.value);
    const navigate = useNavigate();
    const location = useLocation();
    const [switchTab, setSwitchTab] = useState(false);
    const [category, setCategory] = useState();
    const [show, setShow] = useState(false);


    const handleSwitchTab = () => {
        setSwitchTab(prevTab => !prevTab);
    }

    useEffect(() => {
        if (location.pathname === '/self') {
            setSwitchTab(true);
        }
        if (location.pathname !== '/self') {
            setSwitchTab(false);
        }
    }, [])

    useEffect(() => {
        if (switchTab) {
            navigate('/self')
        }
    }, [switchTab])

    const getCategory = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getcategory, {
                headers: {
                    'token': 'essentials',
                    'Content-Type': 'application/json'
                }
            })
            console.log("res::", data);
            setCategory(data?.data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getCategory();
    }, [])


    return (
        <div>
            <section className='custom-main-header'>
                {['xl'].map((expand) => (
                    <Navbar key={expand} expand={expand} fixed='top' className="p-0 custom-row-bg-color" >
                        <Container>
                            <Row className='p-0 m-0'>
                                <Col className='custom-col col-12 p-0' data-aos="fade-down">

                                    <Navbar.Brand as={Link} to={'/'} className='pe-0 pe-lg-2 pe-xxl-4 m-0'>
                                        <img src={require('../Assets/Images/oglogo.png')} alt="" />
                                    </Navbar.Brand>
                                    <Navbar.Offcanvas
                                        show={show}
                                        id={`offcanvasNavbar-expand-${expand}`}
                                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                        placement="start"
                                        onHide={() => setShow(false)}
                                    >
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                <div className='canvas-logo'>
                                                    <img src={require('../Assets/Images/oglogo.png')} alt="" />
                                                </div>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body className='d-grid'>
                                            <Nav className="d-none d-xl-flex justify-content-start gap-xl-3 flex-grow-1 pe-3 text-capitalize custom-main-menu">
                                                {headerContent.map((item, index) => (
                                                    <Nav.Link key={index} className='py-0 px-xl-2 fs-18' style={{ cursor: 'default' }}>
                                                        <span className='fw-semibold fs-18 py-3' onClick={() => navigate(item?.path)} style={{ cursor: 'pointer' }}>{item.label}</span>
                                                        <MdOutlineKeyboardArrowDown className={(item?.data) ? 'fs-20 custom-rotate' : 'd-none'} /> <section className={(item?.data) ? 'custom-sub-menu' : "d-none"}>
                                                            {(item?.data) && item?.data.map((subItem, subIndex) => (
                                                                <div key={subIndex} className='sub-menu-item' onClick={() => navigate(subItem?.path)} style={{ cursor: 'pointer' }}>

                                                                    <Link className='custom-head-label text-start p-0'>
                                                                        <span className='fs-16'>{subItem.label}</span>
                                                                    </Link>
                                                                    {(subItem.data) && subItem?.data.map((petaItem, petaIndex) => (
                                                                        <Link to={petaItem.path} className='link-to-p fs-14 peta-menu-item fw-medium' key={petaIndex}>{petaItem.label}</Link>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </section>
                                                    </Nav.Link>
                                                ))}
                                                <Nav.Link className='py-0 px-xl-2' style={{ cursor: 'default' }}>
                                                    <span className='fw-semibold py-3 fs-18' onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>More</span>
                                                    <MdOutlineKeyboardArrowDown className={'fs-20 custom-rotate'} />
                                                    <section className={'custom-sub-menu'}>
                                                        {(category) && category.map((subItem, subIndex) => (
                                                            <div key={subIndex} className='sub-menu-item' onClick={() => navigate(subItem?.path)} style={{ cursor: 'pointer' }}>

                                                                <Link className='custom-head-label text-start p-0'>
                                                                    <span className='fs-16'>{subItem.label}</span>
                                                                </Link>
                                                                {(subItem?.children) && subItem?.children.map((petaItem, petaIndex) => (
                                                                    <Link to={petaItem.path} className='link-to-p fs-14 peta-menu-item fw-medium' key={petaIndex}>{petaItem.label}</Link>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </section>
                                                </Nav.Link>
                                            </Nav>
                                            <section className='d-grid gap-3 gap-sm-4 d-xl-none custom-header-offcavas'>
                                                <div className='d-grid gap-3 gap-sm-4 align-content-start'>
                                                    <div className='custom-header-offcavas-search flex-between-align p-2 gap-2'>
                                                        <label htmlFor='searchBtn' className="bi bi-search" onClick={() => setShow(false)}></label>
                                                        <input type="text" id='searchBtn' placeholder='Search..' className='w-100' />
                                                    </div>
                                                    <div className='d-grid gap-3'>
                                                        {headerContent.map((item, index) => (
                                                            <Link key={index} to={item.path} onClick={() => setShow(false)} className='text-capitalize fs-16 fw-semibold link-to-p'>
                                                                {item.label}
                                                            </Link>
                                                        ))}
                                                        <Accordion className='custom-accordian-header'>
                                                            <Accordion.Item eventKey={0} className='no-border'>
                                                                <Accordion.Header as='div' className='p-0 custom-accordian-header-p' style={{ backgroundColor: "transparent" }}>
                                                                    <Link to={'/'} className='text-capitalize fs-16 fw-semibold link-to-p'>
                                                                        More
                                                                    </Link>
                                                                </Accordion.Header>
                                                                <Accordion.Body className='px-0 py-2'>
                                                                    {(category) && category.map((subItem, subIndex) => (
                                                                        (subItem?.children)
                                                                            ? (<Accordion key={subIndex}>
                                                                                <Accordion.Item eventKey={subIndex} className='no-border'>
                                                                                    <Accordion.Header as='div' style={{ backgroundColor: "transparent" }}>
                                                                                        <Link to={subItem.path} className='text-capitalize fs-18 fs-sm-16 fw-semibold link-to-p'>
                                                                                            {subItem.label}
                                                                                        </Link>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <div className='d-grid '>
                                                                                            {(subItem?.children) && subItem?.children.map((petaItem, petaIndex) => (
                                                                                                <Link key={petaIndex} to={petaItem.path} className='text-capitalize pb-2 fs-sm-14 link-to-p'>{petaItem.label}</Link>
                                                                                            ))}
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>)
                                                                            : <Link key={subIndex} to={subItem.path} className='d-grid pb-2 text-capitalize link-to-p'>{subItem.label}</Link>
                                                                    ))}
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                                    <hr className='m-0' />
                                                    <div className='d-grid gap-2'>
                                                        <a
                                                            href={(window.location.hostname === 'localhost')
                                                                ? `http://localhost:3001/signup`
                                                                : `https://app.printfuse.in/signup`
                                                            }
                                                            className='text-capitalize fs-16 fw-semibold link-to-p'>
                                                            Create an account
                                                        </a>
                                                        <a href={(window.location.hostname === 'localhost')
                                                            ? `http://localhost:3001/signin`
                                                            : `https://app.printfuse.in/signin`
                                                        } className='text-capitalize fs-16 fw-semibold link-to-p'>
                                                            Log in
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <div className='custom-list-end text-center flex-between-align gap-2 gap-sm-0 pt-2'>
                                                    <span className='fw-bold fs-18 fs-sm-12 border-bg p-sm-2 p-1 half-border-rad'>Sell Online</span>
                                                    <div className='flex-center-align'>
                                                    <label
                                                        className={`switch`}
                                                    >
                                                        <input type="checkbox"
                                                            onChange={handleSwitchTab}
                                                            checked={switchTab}
                                                        />
                                                        <span className="slider" />
                                                    </label>
                                                    </div>
                                                    <span className='fw-bold fs-18 fs-sm-12 border-bg p-sm-2 p-1 half-border-rad'>Order for yourself</span>
                                                </div> */}
                                            </section>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                    <div className='d-flex justify-content-end gap-2 align-items-center custom-list-end'>
                                        {/* <OverlayTrigger
                                            placement={(!switchTab) ? "bottom" : "left"}
                                            // delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    Switch to
                                                    <span className='fs-18 fw-semibold'>
                                                        {(!switchTab)
                                                            ? ' order for self'
                                                            : ' sell online'}
                                                    </span>
                                                </Tooltip>
                                            }
                                        >
                                            <label
                                                className={`switch d-none d-xl-block`}
                                            >
                                                <input type="checkbox"
                                                    onChange={handleSwitchTab}
                                                    checked={switchTab}
                                                />
                                                <span className="slider" />
                                            </label>
                                        </OverlayTrigger> */}
                                        {(user) ?
                                            <Link to={'/user'} className=' ms-0 ms-sm-2 text-decoration-none m-0 flex-between-align custom-left-border py-1 py-sm-2 fs-18 fs-sm-14 px-2 px-sm-3 bi-border-rad'>
                                                <i className="bi bi-person-circle"></i>
                                            </Link>
                                            : <>
                                                <a
                                                    href={(window.location.hostname === 'localhost')
                                                        ? `http://localhost:3001/signin`
                                                        : `https://app.printfuse.in/signin`
                                                    }
                                                    className='custom-header-login-btn half-border-rad d-none d-xl-block py-1 py-sm-2 fs-14'>
                                                    Log In
                                                </a>
                                                <a
                                                    href={(window.location.hostname === 'localhost')
                                                        ? `http://localhost:3001/signup`
                                                        : `https://app.printfuse.in/signup`
                                                    }
                                                    className='custom-header-signup-btn half-border-rad d-none d-md-block fs-14'>
                                                    Get Started
                                                </a>
                                            </>
                                        }
                                        <Dropdown>
                                            <Dropdown.Toggle className='custom-header-lang-btn half-border-rad px-2 fs-14 fs-sm-12'>
                                                Eng
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item>English</Dropdown.Item>
                                                <Dropdown.Item>Hindi</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Navbar.Toggle onClick={() => setShow(true)} aria-controls={`offcanvasNavbar-expand-${expand}`} className='custom-toggle px-1 px-sm-2' />
                                    </div>
                                </Col>
                                {/* <hr className='mb-2 ' />
                                <Col className='col-12 p-0 d-grid d-lg-flex justify-content-lg-between pb-2 gap-2 gap-lg-0 custom-sub-header'>
                                    <div className='d-flex justify-content-between justify-content-lg-start w-100 gap-2 custom-sub-header-btn'>
                                        {subHeaderContent.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={item.path}
                                                className={(location.pathname === item.path)
                                                    ? 'theme-btn half-border-rad p-2 px-4 px-sm-4 py-sm-2 fs-sm-12 fs-14 fw-semibold fs-lg-12 '
                                                    : 'shadow-light-btn half-border-rad p-2 px-4 px-sm-4 py-sm-2 fs-sm-12 fs-14 fw-semibold fs-lg-12'
                                                }
                                                style={(location.pathname !== item.path)
                                                    ? { backgroundColor: `rgba(255, 255, 255)` }
                                                    : {}}
                                            >
                                                {item.label}
                                            </Link>
                                        ))}
                                    </div>
                                </Col>
                                <hr className='m-0' /> */}
                            </Row>
                        </Container>
                    </Navbar>
                ))}
            </section>
            <div className='custom-height-bg'></div>
        </div >
    )
}

export default Header;
