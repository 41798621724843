import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GoCodeReview } from 'react-icons/go'
import { Link } from 'react-router-dom'

function Review(props = {}) {
    return (
        <div>
            <div className='py-5 custom-rating'>
                <Container>
                    <Row className='gy-3 py-4'>
                        <div className='d-grid gap-3 text-center pb-4'>
                            <h2 className='m-0 fw-semibold px-3'>Hear from fellow entrepreneurs</h2>
                            <p className='m-0 p-color fs-18 fs-md-16 fs-sm-14'>What our customers say about working with Printfuse</p>
                        </div>
                        {
                            props?.review.map((item, index) => {
                                return (
                                    <Col key={index} className='col-12 col-md-4 d-grid'>
                                        <div className='seller-box d-grid gap-3 align-content-between p-3 p-sm-4'>
                                            <div className='d-grid'>
                                                <GoCodeReview className='fs-35 color-info' />
                                                <p className='m-0 p-color fs-14 fs-sm-12'>
                                                    <span className='ps-5'>" {item?.description} "</span>
                                                </p>
                                            </div>
                                            <div className='d-flex gap-2 gap-sm-3'>
                                                <div className='rating-img-holder'>
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className='d-grid align-content-around'>
                                                    <h6 className='fw-semibold m-0 fs-sm-14'>{item.name}</h6>
                                                    <Link className='text-decoration-none fs-md-14 fs-sm-12'>{item.website}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Review
