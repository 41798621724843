import React from 'react'
import { Accordion, Container, Row } from 'react-bootstrap'

function CommonFAQ() {

    const listData = [
        {
            question: 'Do I need to know about how to code?',
            answer: 'Yes, you need to have a fair amount of knowledge in dealing with HTML/CSS as well as JavaScript in order to be able to use Lexend.'
        },
        {
            question: 'Is Lexend free to use?',
            answer: 'Lexend offers both free and premium plans. The free plan includes basic features, while the premium plan provides access to advanced tools and customization options.'
        },
        {
            question: 'Can I integrate Lexend with my existing website?',
            answer: 'Yes, Lexend can be easily integrated into your existing website. It supports various platforms and provides plugins for seamless integration.'
        },
        {
            question: 'Is Lexend compatible with mobile devices?',
            answer: 'Absolutely! Lexend is fully responsive and works smoothly on all mobile devices, ensuring that your website looks great on any screen size.'
        },
        {
            question: 'Does Lexend offer customer support?',
            answer: 'Yes, Lexend offers 24/7 customer support through chat, email, and phone. There’s also an extensive knowledge base available for self-help.'
        }
    ];

    return (
        <Container className='cs-container-md custom-faq-list d-grid gap-5'>
            <h2 className='fw-semibold h1 m-0 text-center fs-sm-25'>Frequenlty asked questions:</h2>
            <Row className='gy-4 d-grid p-3 custom-faq-list-row'>
                <Accordion alwaysOpen className='m-0'>

                    {
                        listData.map((item, index) => (
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header> <span className='fs-18 fs-sm-16'>{item?.question}</span> </Accordion.Header>
                                <Accordion.Body>
                                    {item?.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                </Accordion>
            </Row>
        </Container>
    )
}

export default CommonFAQ