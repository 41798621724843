import React from 'react'
import { Container } from 'react-bootstrap'
import { BsQuote } from 'react-icons/bs';
import Slider from 'react-slick'

function CommonClient() {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        waitForAnimate: false
    };

    const clientData = [
        {
            thumbnail: require("../Assets/Images/template/post-1.jpg"),
            name: "Mark Zellers",
            role: "Director of Sales Operations",
            descriptionOne: "I can create a custom proposal with dynamic pricing tables, and get approval from my boss all within 36 minutes and it looks fantastic.",
            descriptionTwo: "Dolor sit amet consectetur adipisicing elit. Quas nisi consectetur adipisci eaque. Porro, atque.",
        },
        {
            thumbnail: require("../Assets/Images/template/post-2.jpg"),
            name: "Emily Johnson",
            role: "Chief Marketing Officer",
            descriptionOne: "The platform has transformed how we manage our marketing campaigns. The seamless integration and user-friendly interface are game-changers.",
            descriptionTwo: "Sit amet consectetur adipisicing elit. Minima molestiae facilis incidunt voluptatem optio.",
        },
        {
            thumbnail: require("../Assets/Images/template/post-3.jpg"),
            name: "James Doe",
            role: "Product Manager",
            descriptionOne: "Managing product lifecycle has never been easier. The efficiency and accuracy we have gained are remarkable.",
            descriptionTwo: "Consectetur adipisicing elit. Aliquam eveniet quidem dolorum nobis nihil soluta.",
        }
    ];

    return (
        <div className='integrations-client-list'>
            <Container className='cs-container-lg'>
                <h2 className='fw-semibold h1 fs-sm-25 text-center pb-3 pb-sm-4'>
                    What clients said:
                </h2>
                <div
                    className="slider-container integrations-client-list-container p-3 p-sm-4 p-lg-5 pb-5 pb-sm-5 pb-lg-5"
                >
                    <Slider {...sliderSettings}>
                        {
                            clientData.map((item, index) => (
                                <div key={index} className='integrations-client-list-item d-grid d-md-flex gap-3 gap-md-0'>
                                    <div className='img-holder'>
                                        <img src={item?.thumbnail} alt="" />
                                    </div>
                                    <div className='content-holder gap-3'>
                                        <div className='text-center d-grid align-content-start gap-3 gap-md-4'>
                                            <div className='flex-center-align'>
                                                <BsQuote className='display-4 color-temp' />
                                            </div>
                                            <p className='fw-semibold h5 px-md-4 ps-md-5 fs-md-14'>
                                                "{item?.descriptionOne}
                                                <br />
                                                <br />
                                                {item?.descriptionTwo}
                                            </p>
                                        </div>
                                        <div className='d-grid gap-1 text-center'>
                                            <h5 className='fw-semibold m-0 text-capitalize fs-sm-16'>{item?.name}</h5>
                                            <span className='text-capitalize p-color fs-sm-12'>{item?.role}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </Container>
        </div>
    )
}

export default CommonClient