import React from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io";
import { userSideBar } from '../../Data/localData';
import axios from 'axios';
import { frontEnd_API, header } from '../../Config/Config';
import { useDispatch, useSelector } from 'react-redux';
import { removeLoggedUser } from '../../Store/Slices/UserSlice';
import { Dropdown } from 'react-bootstrap';

function UserHeader() {

    const user = useSelector((state) => state.user.value);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUser = () => {
        axios.get(frontEnd_API.signout, header)
            .then((res) => {
                console.log("res::", res);
                dispatch(removeLoggedUser());
                navigate('/');
                window.location.reload();
            })
            .catch((e) => {
                console.log("e::", e);
            })
    }

    const date = new Date();
    const hour = date.getHours();
    let time = '';

    if (hour >= 0 && hour <= 11) {
        time = 'Good Morning';
    }
    else if (hour >= 12 && hour <= 17) {
        time = 'Good Afternoon';
    }
    else if (hour >= 18 && hour <= 23) {
        time = 'Good Evening';
    }

    return (
        <div>
            <div className='custom-user-header half-border-rad d-flex d-lg-grid align-items-center p-3 p-lg-0'>
                <div className='d-grid p-lg-3 align-content-center gap-2'>
                    <h5 className='m-0 fw-semibold text-capitalize'>
                        <span className='fw-light fs-sm-16'>{time} </span>
                        <span className='color-info'>{(user?.name.length > 6) ? user?.name.substring(0, 6) + '...' : user?.name}!</span>
                    </h5>
                    <p className='fs-14 fs-md-12 gap-1 m-0 flex-between-align justify-content-start'>
                        <span>{user?.email}</span>
                    </p>
                </div>
                <hr className='my-0 ' />
                <div className='d-none d-lg-grid header-link-list'>
                    {
                        userSideBar.map((item, index) => {
                            return (
                                <NavLink to={item?.link} key={index} className={(location.pathname === item?.link) ? "active-nav flex-between fw-semibold color-info" : "flex-between"}>
                                    <span className='gap-2 align-items-center d-flex justify-content-start'>
                                        <i className={`bi ${item?.icon} fs-20`}></i>
                                        <span className='text-capitalize'>{item?.label}</span>
                                    </span>
                                    <IoIosArrowForward />
                                </NavLink>
                            )
                        })
                    }
                    {
                        (user?.role !== 'SELLER')
                        && <NavLink to={'/create-store'} className={'custom-seller-banner-bg'}>
                            <div className='d-grid text-center p-2'>
                                <span className='text-capitalize fw-semibold'>Become a seller</span>
                            </div>
                        </NavLink>
                    }
                    <Link className='flex-between' onClick={handleUser}>
                        <span>Sign Out</span>
                    </Link>
                </div>
                <Dropdown className='d-lg-none custom-user-header-drp'>
                    <Dropdown.Toggle id="dropdown-basic">
                        <i className='bi bi-list custom-menu'></i>
                        <i className='bi bi-x-lg custom-close'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            userSideBar.map((item, index) => {
                                return (
                                    <Dropdown.Item as={Link} to={item?.link} key={index} className={`d-flex justify-content-start gap-2 ${(location.pathname === item?.link) && "custom-active fw-semibold"}`}>
                                        <i className={`bi ${item?.icon}`}></i>
                                        <span className={`text-capitalize`}>{item?.label}</span>
                                    </Dropdown.Item>
                                )
                            })
                        }
                        <Dropdown.Item as={Link} className='bg-light' onClick={handleUser}>
                            <span className={`text-capitalize text-danger`}>Sign Out</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default UserHeader
