import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FormComponent from '../../Components/FormComponent'
import { frontEnd_API } from '../../Config/Config'
import { pattern } from '../../Config/Constant'
import { ToastContainer } from 'react-toastify'

function UserPassword() {

    return (
        <div>
            <h3 className='p-0 fw-semibold'>Change Password</h3>
            <Row className='g-4 pt-3'>
                <Col xs='12'>
                    <div className='default-white-box p-3'>
                        <div className='d-grid align-content-start custom-login-form'>
                            <h5 className='fs-18 m-0'>Want to change Password?</h5>
                            <FormComponent
                                action={frontEnd_API.postUpdatePassword}
                                submitFullWidth={false}
                                submitBTNAlign={'justify-content-center'}
                                fields={[
                                    {
                                        type: 'password',
                                        divClass: 'col-12',
                                        name: 'Old Password',
                                        key: 'oldPassword',
                                        required: true,
                                    },
                                    {
                                        type: 'password',
                                        divClass: 'col-12',
                                        name: 'New Password',
                                        key: 'newPassword',
                                        required: true,
                                        pattern: pattern.password
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </div>
    )
}

export default UserPassword
