import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { frontEnd_API } from '../../Config/Config';
import FormComponent from '../../Components/FormComponent';
import { storeUser } from '../../Store/Slices/UserSlice';
import { pattern } from '../../Config/Constant';
import { ToastContainer } from 'react-toastify';

function SignupPage() {

    const points = [
        {
            title: 'Achieve Excellence',
            description: 'Connect seamlessly with top ecommerce platforms'
        },
        {
            title: 'Ignite Your Imagination',
            description: 'Use our cutting-edge Design Studio to create standout products'
        },
        {
            title: 'Discover 300+ High-Quality Products',
            description: 'T-shirts starts from just $349'
        },
    ]

    return (
        <div>
            <div className='d-grid align-items-center custom-signup-page'>
                <div className='flex-center-align px-3'>
                    <Container className='py-4'>
                        <Row className='px-2 py-4 px-sm-4 custom-signup-row'>
                            <div>
                                <Link className='bi bi-x-lg custom-close-btn' to={'/'} />
                            </div>
                            <Col className='col-12 col-lg-6 d-grid gap-2 gap-sm-4 px-2 pe-lg-3 align-content-center'>
                                <div className='d-grid gap-2 text-center text-lg-start'>
                                    <h2 className='fw-semibold m-0'>Create Account</h2>
                                    <p className='p-color m-0 fs-14 fs-sm-12'>Sign up and start earning for FREE Name</p>
                                </div>
                                <div className='fs-14'>
                                    <FormComponent
                                        action={frontEnd_API.signup}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={false}
                                        submitBTNAlign={'justify-content-start custom-submit-btn'}
                                        loadPage={true}
                                        formSubmit={"User Created Successfully !"}
                                        button={'Sign up'}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'name',
                                                key: 'name',
                                                required: true,
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'email',
                                                key: 'email',
                                                placeholder: 'sample@gmail.com',
                                                required: true,
                                                pattern: pattern.email,
                                            },
                                            {
                                                divClass: 'col-12 col-md-6',
                                                name: 'mobile',
                                                key: 'mobile',
                                                type: 'number',
                                                placeholder: 'Enter 10 digit mobile number',
                                                required: true,
                                                pattern: pattern.mobile,
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                pattern: pattern.password,
                                            }
                                        ]}
                                    />
                                    <div className='d-grid gap-3 pt-2'>
                                        <p className='fs-8 m-0'>
                                            By clicking Sign up you agree to Printfuse Terms of Service , Privacy Policy and Intellectual Property Policy
                                        </p>
                                        <span className='fs-14'>
                                            Already Have an Account? <Link className='text-decoration-none' to={'/signin'}>Sign in</Link>
                                        </span>
                                    </div>
                                    <hr className='border-bg' style={{ opacity: 1 }} />
                                    <div className='text-start'>
                                        Want to become a <Link className='text-decoration-none fw-semibold' target='_blank' to={'https://app.printfuse.in/signup'}>Seller?</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col className='d-none d-lg-grid col-lg-6 ps-0 ps-lg-3'>
                                <div className='custom-signup-img'>
                                    <img src={require('../../Assets/Images/signup-back.png')} alt="" />
                                </div>
                                <div className='flex-center-align justify-content-end pe-4 pb-4'>
                                    <div className='d-grid gap-2'>
                                        {
                                            points.map((item, index) => {
                                                return (
                                                    <div key={index} className='d-flex gap-2'>
                                                        <i class="bi bi-check-lg d-flex color-info fs-18" />
                                                        <div className='d-grid gap-1'>
                                                            <h6 className='fw-semibold m-0 fs-16'>{item.title}</h6>
                                                            <p className='m-0 p-color fs-14'>{item.description}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SignupPage