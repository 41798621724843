import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

function CommonInsights() {
    return (
        <div>
            <Container fluid className='py-5 pb-4 custom-insights-list'>
                <Container className='py-4 py-sm-5'>
                    <h2 className='fw-bold mb-5 text-center h1'>Gain valuable insights</h2>
                    <Row>
                        <Col className='col-12 col-md-4'>
                            <div className='custom-insights-list-item p-3 p-sm-4 d-grid gap-3 gap-sm-4'>
                                <Link className='img-holder-in'>
                                    <img src={require('../Assets/Images/img-11.jpg')} alt="" />
                                    <span className='fw-semibold p-2 fs-12'>Strategy</span>
                                </Link>
                                <div className='d-flex text-center'>
                                    <Link className='fw-semibold h4'>Top 5 reasons to invest in marketing</Link>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-4'>
                            <div className='custom-insights-list-item p-3 p-sm-4 d-grid gap-3 gap-sm-4'>
                                <Link className='img-holder-in'>
                                    <img src={require('../Assets/Images/img-12.jpg')} alt="" />
                                    <span className='fw-semibold p-2 fs-12'>Marketing</span>
                                </Link>
                                <div className='d-flex text-center'>
                                    <Link className='fw-semibold h4'>How can marketing help your business?</Link>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-4'>
                            <div className='custom-insights-list-item p-3 p-sm-4 d-grid gap-3 gap-sm-4'>
                                <Link className='img-holder-in'>
                                    <img src={require('../Assets/Images/img-13.jpg')} alt="" />
                                    <span className='fw-semibold p-2 fs-12'>Business</span>
                                </Link>
                                <div className='d-flex text-center'>
                                    <Link className='fw-semibold h4'>The ultimate guide to marketing success</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='flex-center-align pt-4 pt-sm-5'>
                        <Link className='flex-center-align gap-2 fw-semibold more-link'>
                            View more articles <FaArrowRightLong />
                        </Link>
                    </div>
                </Container>
            </Container>

        </div>
    )
}

export default CommonInsights