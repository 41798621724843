import Home from "../View/Home/Home";
import ErrorPage from "../Components/ErrorPage";
import SignupPage from "../View/SignUp_LogIn/SignupPage";
import SigninPage from "../View/SignUp_LogIn/SigninPage";
import HelpCenter from "../View/OtherPages/HelpCenter";
import ContactUs from "../View/OtherPages/ContactUs";
import HomeSelf from "../View/Home/HomeSelf";
import Index from "../View/UserPanel/Index";
import SellWithPrintfuse from "../View/SellOnlinePages/SellWithPrintfuse";
import SwitchToPrintfuse from "../View/SellOnlinePages/SwitchToPrintfuse";
import CompareToOther from "../View/SellOnlinePages/CompareToOther";
import BlogHome from "../View/OtherPages/Blog/BlogHome";
import BlogCategory from "../View/OtherPages/Blog/BlogCategory";
import About from "../View/OtherPages/About";
import Integrations from "../View/OtherPages/Integrations";
import FeaturesPage from "../View/OtherPages/FeaturesPage";
import SingleBlogDetails from "../View/OtherPages/Blog/SingleBlogDetails";

const routes = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/user/*',
        element: <Index />
    },
    {
        path: '*',
        element: <ErrorPage />
    },
    {
        path: '/self',
        element: <HomeSelf />
    },
    {
        path: '/help-center',
        element: <HelpCenter />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    },
    {
        path: '/insights',
        element: <BlogHome />
    },
    {
        path: '/insights/:insightsName',
        element: <BlogCategory />
    },
    {
        path: '/insights/:insightsName/:blogId',
        element: <SingleBlogDetails />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/page-integrations',
        element: <Integrations />
    },
    {
        path: '/features',
        element: <FeaturesPage />
    },
    {
        path: '/signin',
        element: <SigninPage />
    },
    {
        path: '/signup',
        element: <SignupPage />
    },
    {
        path: '/sell-with-printfuse',
        element: <SellWithPrintfuse />
    },
    {
        path: '/switch-to-printfuse',
        element: <SwitchToPrintfuse />
    },
    {
        path: '/compare-to-other',
        element: <CompareToOther />
    },
]


export default routes;