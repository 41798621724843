import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { FaShop } from "react-icons/fa6";

function UserDashboard() {

  const user = useSelector((state) => state.user.value);

  return (

    <div className='user-dashboard'>
      <h3 className='p-0 fw-semibold'>Dashboard</h3>
      <Row className='g-4 pt-3'>

        <Col className='col-12'>
          <div className='py-1 flex-center-align'>
            <h5 className='text-center fw-semibold custom-heading-back px-3 px-sm-4 py-2 fs-sm-18'>Create a store and start selling with <b className='color-info h4 fw-bold fs-sm-20'>Printfuse</b></h5>

          </div>
          <div className='user-banner-one d-grid d-md-flex'>
            <div className='w-100 d-grid user-banner-one-img'>
              <img src={require('../../Assets/Images/9e4eafac2e717a37a2e8d77e3d5e0988__930.jpg')} alt="" />
            </div>
            <div className='w-100 d-grid user-banner-one-cnt gap-2 align-content-around p-3'>
              <div className='d-flex gap-2'>
                <div>
                  <i className="bi bi-check-lg color-info"></i>
                </div>
                <div className='d-grid'>
                  <h6 className='fw-semibold m-0 fs-sm-12 fs-lg-14'>Variety of ecommerce integrations</h6>
                  <p className='p-color fs-14 m-0 fs-sm-10 fs-lg-12'>While you can't sell products directly on Printfuse, you can connect Printfuse to nearly any ecommerce platform or marketplace</p>
                </div>
              </div>
              <div className='d-flex gap-2'>
                <div>
                  <i className="bi bi-check-lg color-info"></i>
                </div>
                <div className='d-grid'>
                  <h6 className='fw-semibold m-0 fs-sm-12 fs-lg-14'>On-demand production partner</h6>
                  <p className='p-color fs-14 m-0 fs-sm-10 fs-lg-12'>You sell Printfuse products on your store and we fulfill your orders automatically as soon as they come in</p>
                </div>
              </div>
              <div className='d-flex gap-2'>
                <div>
                  <i className="bi bi-check-lg color-info"></i>
                </div>
                <div className='d-grid'>
                  <h6 className='fw-semibold m-0 fs-sm-12 fs-lg-14'>Product creation made easy</h6>
                  <p className='p-color fs-14 m-0 fs-sm-10 fs-lg-12'>Design your products with our built-in creative tools and free design elements</p>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col xs='12' lg='6'>
          <div className='default-white-box h-100 d-grid d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-start p-3'>
            <div className='d-flex justify-content-center justify-content-sm-start'>
              <div className='bgSubThemeColor icon-center-circle'>
                <FaShop />
              </div>
            </div>
            <div className='ps-2 ps-sm-3 d-grid align-content-start pt-2 pt-sm-0'>
              <h5 className='fs-18 fw-semibold'>Become a seller</h5>
              <h6 className='fs-14 fs-md-12'>"Your brand, just a few steps away."</h6>
              <div className='pt-2 change-color-link'>
                <Link to='/create-store' className='d-flex justify-content-center justify-content-sm-start fs-14 fs-md-12 gap-2'>
                  <span>Create Free Store</span>
                  <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xs='12' lg='6'>
          <div className='default-white-box h-100 d-grid d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-start p-3'>
            <div className='d-flex justify-content-center justify-content-sm-start'>
              <div className='bgSubThemeColor icon-center-circle'>
                <i className="bi bi-person"></i>
              </div>
            </div>
            <div className='ps-2 ps-sm-3 d-grid align-content-start pt-2 pt-sm-0'>
              <h5 className='fs-18 fw-semibold'>Contact Information</h5>
              <h6 className='fs-14 fs-md-12 text-capitalize'>{user?.name}</h6>
              <h6 className='fs-14 fs-md-12'>{user?.email}</h6>
              <div className='pt-2 change-color-link'>
                <Link to='/user/account' className='d-flex justify-content-center justify-content-sm-start fs-14 fs-md-12 gap-2'>
                  <span>Edit</span>
                  <i class="bi bi-arrow-right"></i>
                </Link>
                <Link to='/user/account' className='d-flex justify-content-center justify-content-sm-start fs-14 fs-md-12 gap-2'>
                  <span>Change Password</span>
                  <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Col>

        <Col xs='12'>
          <div className='default-white-box flex-between p-3'>
            <h5 className='m-0'>Addresses</h5>
            <Link to={'/user/address'} className='link-to-normal text-decoration-underline'>Manage Address</Link>
          </div>
        </Col>
        <Col xs='12' lg='6'>
          <div className='default-white-box h-100 d-grid d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-start p-3'>

            <div className='d-grid align-content-start pt-2 pt-sm-0'>
              <h5 className='fs-18'>Default Billing Address</h5>
              <h6 className='fs-14 fs-md-12 fst-italic'>You have not set a default billing address.</h6>
              <div className='pt-2 change-color-link'>
                <Link to='/user/address' className='d-flex justify-content-center justify-content-sm-start fs-14 fs-md-12 gap-2'>
                  <span>Edit Address</span>
                  <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xs='12' lg='6'>
          <div className='default-white-box h-100 d-grid d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-start p-3'>

            <div className='d-grid align-content-start pt-2 pt-sm-0'>
              <h5 className='fs-18'>Default Shipping Address</h5>
              <h6 className='fs-14 fs-md-12 fst-italic'>You have not set a default shipping address.</h6>
              <div className='pt-2 change-color-link'>
                <Link to='/user/address' className='d-flex justify-content-center justify-content-sm-start fs-14 fs-md-12 gap-2'>
                  <span>Edit Address</span>
                  <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default UserDashboard;