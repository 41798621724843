import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PiStarFourFill } from 'react-icons/pi'
import { Link } from 'react-router-dom';

function About() {

    const [year, setYear] = useState(0);
    const [group, setGroup] = useState(0);
    const [followers, setFollowers] = useState(0);
    const valueList = [
        {
            thumbnail: require('../../Assets/Images/template/back-tranparent-1.png'),
            name: "Make an impact",
            description: "We’re building something big. Something that has the power to change the trajectory of any sized business for the better."
        },
        {
            thumbnail: require('../../Assets/Images/template/back-tranparent-2.png'),
            name: "Learn",
            description: "Lexend team are masters of their craft. Even though we’re all experts in our respective fields, we always make time to expand our minds."
        },
        {
            thumbnail: require('../../Assets/Images/template/back-tranparent-3.png'),
            name: "Have fun",
            description: "We work hard and play harder. We believe in the importance of celebrating wins big or small, for the business or individuals."
        },
        {
            thumbnail: require('../../Assets/Images/template/back-tranparent-4.png'),
            name: "Empathy",
            description: "We strive to be empathetic to every customer and colleague and by doing so we can provide a better experience for all."
        },
    ]

    const clientList = [
        {
            thumbnail: require("../../Assets/Images/Screenshot 2024-08-29 123637.png"),
            description: "We’re looking for people who share our vision! most of our time used to be taken up by most of alternate administrative work whereas now we can focus on building out to help our employees.",
            avatar: require('../../Assets/Images/template/img-01.jpg'),
            name: "Mark Zellers",
            role: "CEO, Co-Founder"
        },
        {
            thumbnail: require("../../Assets/Images/Screenshot 2024-08-29 123708.png"),
            description: "This powerfull tool eliminates the need to leave Salesforce to get things done as I can create a custom proposal with dynamic pricing tables, and get approval from my boss all within 36 minutes.",
            avatar: require('../../Assets/Images/template/img-02.jpg'),
            name: "Natalia Larsson",
            role: "Director of Sales"
        },
        {
            thumbnail: require("../../Assets/Images/Screenshot 2024-08-29 123811.png"),
            description: "We are based in Europe and the latest Data Protection Regulation forces us to look for service suppliers than comply with this regulation and as we look to create our website and this builder just outstanding!",
            avatar: require('../../Assets/Images/template/img-03.jpg'),
            name: "Sarah Edrissi",
            role: "Lead Marketing"
        },
    ]

    const teamList = [
        {
            thumbnail: require("../../Assets/Images/template/team-01.jpg"),
            name: "Mark Zellers",
            role: "Founder & CEO"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-02.jpg"),
            name: "John Zellers",
            role: "Co-Founder"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-03.jpg"),
            name: "Kim Yun Son",
            role: "Engineering Manager"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-04.jpg"),
            name: "André Garcia",
            role: "Product Manager"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-05.jpg"),
            name: "Peter Lary",
            role: "UX Researcher"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-06.jpg"),
            name: "Henry Matt",
            role: "Customer Success"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-07.jpg"),
            name: "Natalia",
            role: "Lead of fun"
        },
        {
            thumbnail: require("../../Assets/Images/template/team-08.jpg"),
            name: "Larry",
            role: "Director of Joy"
        },
    ]

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 700 && year === 0) {
                for (let i = 0; i <= 2024; i++) {
                    setTimeout(() => {
                        setYear(i);
                    }, i * (1000 / 2024));
                }
            }
            if (window.scrollY >= 700 && group === 0) {
                for (let i = 0; i <= 50; i++) {
                    setTimeout(() => {
                        setGroup(i);
                    }, i * (1000 / 50));
                }
            }
            if (window.scrollY >= 700 && followers === 0) {
                for (let i = 0; i <= 123; i++) {
                    setTimeout(() => {
                        setFollowers(i);
                    }, i * (1000 / 123));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [year, group, followers]);

    return (
        <div className='custom-about'>
            <Container className='cs-container-xl about-title pb-4 pb-sm-5'>
                <Row className='gy-4 gy-md-5'>
                    <Col className='col-12 pb-0 pt-4 pt-sm-5 text-center about-style-peta d-grid'>
                        <div className='py-md-4 d-grid gap-4'>
                            <h1 className='display-4 m-0 fw-semibold'>About Printfuse</h1>
                            <PiStarFourFill className='first-icon d-none d-lg-flex' />
                            <PiStarFourFill className='second-icon d-none d-lg-flex' />
                            <div className='flex-center-align'>
                                <p className='p-color m-0 cs-container-md fs-sm-12 fs-md-14'>In 2014, Steven Smith have gotten so much of our time back that we're now able to put towards things that are actually helping our company as opposed to just throwing content out there. and the idea of Lexend was born. Today, Lexend empowers teams to easily communicate with customers through personalized documents that can be created in minutes, build meaningful relationships.</p>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12'>
                        <Row className='svg-holder-one'>
                            <Col className='col-4 d-grid pe-1 pe-sm-2'>
                                <div className='img-holder'>
                                    <img src={require('../../Assets/Images/template/hero-about-01.jpg')} alt="" />
                                </div>
                            </Col>
                            <Col className='col-8 d-grid ps-1 ps-sm-2'>
                                <div className='img-holder'>
                                    <img src={require('../../Assets/Images/template/hero-about-02.jpg')} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-12 flex-center-align'>
                        <div className='cs-container-lg d-flex gap-2 gap-md-3 gap-lg-4 gap-xl-5'>
                            <div className='w-100' style={{ height: "50px" }}>
                                <img
                                    src={require('../../Assets/Images/Screenshot 2024-08-29 123637.png')}
                                    alt=""
                                    style={{ objectFit: "contain", width: "100%", height: "100%", }}
                                />
                            </div>
                            <div className='w-100' style={{ height: "50px" }}>
                                <img
                                    src={require('../../Assets/Images/Screenshot 2024-08-29 123708.png')}
                                    alt=""
                                    style={{ objectFit: "contain", width: "100%", height: "100%", }}
                                />
                            </div>
                            <div className='w-100' style={{ height: "50px" }}>
                                <img
                                    src={require('../../Assets/Images/Screenshot 2024-08-29 123811.png')}
                                    alt=""
                                    style={{ objectFit: "contain", width: "100%", height: "100%", }}
                                />
                            </div>
                            <div className='w-100' style={{ height: "50px" }}>
                                <img
                                    src={require('../../Assets/Images/Screenshot 2024-08-29 123732.png')}
                                    alt=""
                                    style={{ objectFit: "contain", width: "100%", height: "100%", }}
                                />
                            </div>
                            <div className='w-100' style={{ height: "50px" }}>
                                <img
                                    src={require('../../Assets/Images/Screenshot 2024-08-29 123750.png')}
                                    alt=""
                                    style={{ objectFit: "contain", width: "100%", height: "100%", }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='py-4 py-sm-5 about-help'>
                <Container className='cs-container-lg text-center d-grid gap-3 gap-md-4 gap-lg-5 py-3 py-md-4'>
                    <h2 className='m-0 h1 fw-semibold'>How Printfuse helps</h2>
                    <div className='text-center d-grid gap-2 gap-md-3 gap-lg-4'>
                        <p className='m-0 fs-md-14 fs-sm-12 p-color'>
                            Teams use Lexend to improve document workflows, insights, and speed while delivering an amazing experience for their customers. Businesses trust Lexend’s all-in-one document automation software to streamline the process to create, approve, and eSign proposals, quotes, contracts, and more. With powerful document creation and workflow capabilities, teams can provide their customers with a more professional, timely, and engaging experience.
                        </p>
                        <p className='m-0 fs-md-14 fs-sm-12 p-color'>
                            In 2014, Steven Smith have gotten so much of our time back that we're now able to put towards things that are actually helping our company as opposed to just throwing content out there. and the idea of Lexend was born. Today, Lexend empowers teams to easily communicate with customers through personalized documents that can be created in minutes, build meaningful relationships..
                        </p>
                    </div>
                    <div className='flex-between-align justify-content-center justify-content-sm-between flex-wrap px-md-4 gap-3'>
                        <div className="d-grid gap-1">
                            <h2 className='h1 m-0 fw-semibold'>{year}</h2>
                            <p className='p-color m-0'>Printfuse founded.</p>
                        </div>
                        <div className="d-grid gap-1">
                            <h2 className='h1 m-0 fw-semibold'>{group}</h2>
                            <p className='p-color m-0'>Hardworking group.</p>
                        </div>
                        <div className="d-grid gap-1">
                            <h2 className='h1 m-0 fw-semibold'>{followers}k</h2>
                            <p className='p-color m-0'>Document workflows.</p>
                        </div>
                    </div>
                </Container>
            </Container>
            <Container className='cs-container-lg py-4 py-sm-5'>
                <Row className='gy-3 gy-md-4'>
                    <h2 className='pt-5 pb-3 pb-sm-4 pt-sm-5 fw-semibold m-0 h1 text-center fs-sm-23'>Our Values it’s Simple!</h2>
                    {
                        valueList.map((item, index) => (
                            <Col className='col-12 col-sm-6 d-grid' key={index}>
                                <div className='custom-value-list p-3 p-sm-4 d-grid gap-3 align-content-start'>
                                    <div className='img-holder'>
                                        <img src={item?.thumbnail} alt="" />
                                    </div>
                                    <h5 className='m-0 fw-semibold'>{item?.name}</h5>
                                    <p className='m-0 p-color fs-md-14 fs-sm-12'>
                                        {item?.description}
                                    </p>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                <Row className='gy-3 gy-md-4 pt-5'>
                    <h2 className='pt-5 pb-3 pb-sm-4 pt-sm-5 fw-semibold m-0 h1 text-center fs-sm-23'>
                        Some clients feedbacks
                    </h2>
                    <Row className='gy-3 gy-md-0 m-0 flex-center-align p-0'>
                        {
                            clientList.map((item, index) => (
                                <Col className='col-12 col-sm-6 col-md-4 d-grid' key={index}>
                                    <div className='custom-client-avatar-list p-3 p-sm-4 d-grid gap-3 align-content-between'>
                                        <div className='img-holder'>
                                            <img src={item?.thumbnail} alt="" />
                                        </div>
                                        <p className='m-0 p-color fs-md-14 fs-sm-12'>
                                            {`"${item?.description}"`}
                                        </p>
                                        <div className='d-flex gap-2 pt-3 pt-sm-4'>
                                            <div className='avatar-holder d-grid'>
                                                <img src={item?.avatar} alt="" />
                                            </div>
                                            <div className='d-grid align-content-center gap-1'>
                                                <h6 className="m-0 fs-sm-14">{item?.name}</h6>
                                                <span className='p-color fs-14 fs-sm-12'>{item?.role}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <div className='flex-center-align gap-2 text-center pt-2 pt-md-4'>
                        <Link className='fw-semibold d-flex gap-2 text-decoration-none color-temp'>
                            See all feedbacks
                            <i className="bi bi-arrow-right"></i>
                        </Link>
                    </div>
                </Row>
                <Row className='gy-3 gy-md-4 pt-5'>
                    <h2 className='pt-5 pb-3 pb-sm-4 pt-sm-5 fw-semibold m-0 h1 text-center fs-sm-23'>
                        Our Executive Team
                    </h2>
                    <Row className='gy-3 gy-md-4 m-0 flex-center-align p-0'>
                        {
                            teamList.map((item, index) => (
                                <Col className='col-6 col-sm-4 col-md-3 d-grid' key={index}>
                                    <div className='custom-team-list d-grid gap-2 align-content-between'>
                                        <div className='img-holder'>
                                            <img src={item?.thumbnail} alt="" />
                                        </div>
                                        <div className='d-grid'>
                                            <h6 className='m-0 fw-semibold fs-sm-12'>{item?.name}</h6>
                                            <span className='p-color fs-14 fs-sm-10'>{item?.role}</span>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Row>
            </Container>
            <Container fluid className='py-4 py-sm-5 about-help'>
                <Container className='cs-container-md text-center d-grid gap-3 gap-md-4 py-3 py-md-4'>
                    <h2 className='m-0 h1 fw-semibold'>
                        We’re looking for people who share our vision!
                    </h2>
                    <p className='p-color m-0'>Have what it takes to be one of us.</p>
                    <div className='flex-center-align'>
                        <button className='opn-btn fw-semibold lh-sm py-sm-3 px-4 fs-sm-14'>View current openings</button>
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default About