import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { siteConfig } from '../../Config/Config';
import { ToastContainer } from 'react-toastify';
import { PiStarFourFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { FaRegAddressBook } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdMessage } from "react-icons/md";
import { FaAt } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import CommonFAQ from '../../Components/CommonFAQ';
import Slider from 'react-slick';
import CommonInsights from '../../Components/CommonInsights';
import CommonBrands from '../../Components/CommonBrands';

function ContactUs() {
    document.title = `Contact us | ${siteConfig?.name}`;

    return (
        <div className='contact-us-style'>

            {/* Contact Heading Part */}
            {/* <div className='contact-us-style-head'>
                <h3 className='text-center fw-semibold m-0 py-4 py-sm-5'>Contact Us</h3>
                <Container className='pb-5'>
                    <Row className='d-flex gap-3 gap-lg-0'>
                        <Col className='col-12 col-lg-4'>
                            <div className='text-center d-grid gap-3 px-3 py-4 no-border-rad seller-box'>
                                <h5 className='fw-semibold m-0 flex-center-align gap-2'><i className="bi bi-envelope-fill color-info"></i>Email</h5>
                                <div className='d-grid fs-14'>
                                    <span>admincontact@gmail.com</span>
                                    <span>printfusecontact@gmail.com</span>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-4'>
                            <div className='text-center d-grid gap-3 px-3 py-4 no-border-rad seller-box'>
                                <h5 className='fw-semibold m-0 flex-center-align gap-2'><i className="bi bi-telephone-fill color-info"></i>Mobile</h5>
                                <div className='d-grid fs-14'>
                                    <span>+261 123 1234</span>
                                    <span>+261 123 1234</span>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-4'>
                            <div className='text-center d-grid gap-3 px-3 py-4 no-border-rad seller-box'>
                                <h5 className='fw-semibold m-0 flex-center-align gap-2'><i className="bi bi-geo-alt-fill color-info"></i>Address</h5>
                                <div className='d-grid fs-14'>
                                    <span>110, New Village Society, Sample Area, At Sample, India - 123456</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}

            {/* Contact Form */}
            <Container className='py-5 d-grid contact-us-style-peta cs-container-xl'>
                <PiStarFourFill className='first-icon d-none d-md-flex' />
                <PiStarFourFill className='second-icon d-none d-md-flex' />
                <div className='flex-center-align'>
                    <Container className='pb-4 pb-sm-5 d-grid gap-2 gap-md-4 cs-container-md'>

                        <h1 className='text-center fw-semibold m-0 display-4'>Contact Us</h1>
                        <p className='text-center m-0 p-color'>Feel free to reach out to us using the options below, and our dedicated team will respond to your inquiries promptly.</p>
                    </Container>
                </div>
                <Container className='cs-container-md custom-form-width p-3 p-md-5'>
                    <div className='flex-center-align'>
                        <div>
                            <p className='p-color m-0 pb-3 pb-md-4 fs-14'>Have a question or feedback? Fill out the form below, and we'll get back to you as soon as possible.</p>
                            <FormComponent
                                buttonClass={"custom-submit-btn"}
                                button={"Send Message"}
                                submitFullWidth={true}
                                fields={[
                                    {
                                        divClass: 'col-12 col-lg-6',
                                        name: 'full name',
                                        key: 'name',
                                        placeholder: 'Enter name',
                                        required: true,
                                    },
                                    {
                                        divClass: 'col-12 col-lg-6',
                                        name: 'Email',
                                        key: 'email',
                                        placeholder: 'sample@gmail.com',
                                        required: true,
                                        pattern: pattern.email,
                                    },
                                    {
                                        divClass: 'col-12',
                                        name: 'Subject',
                                        key: 'subject',
                                        placeholder: 'Ex. How Orders Processed',
                                        required: true,
                                    },
                                    {
                                        type: 'textarea',
                                        areaRow: '4',
                                        divClass: 'col-12',
                                        name: 'Message',
                                        key: 'message',
                                        placeholder: '',
                                        required: true,
                                    },
                                ]}
                            />
                            <p className='m-0 pt-3 pb-md-4 text-center'>
                                Or drop us a message via
                                <Link className={"email-btn ps-1"}>email.</Link>
                            </p>
                        </div>
                    </div>
                </Container>
            </Container>

            {/* Other ways to reach us */}
            <Container className='cs-container-lg custom-other-list py-sm-5'>
                <h2 className='fw-semibold h1 m-0 pb-4 pb-sm-5 text-center fs-sm-25'>Other ways to reach us</h2>
                <Row className='gy-4'>
                    <Col className="col-12 col-sm-6 d-grid">
                        <div className='custom-other-list-item p-3 p-md-4 gap-2 flex-between-align'>
                            <div className='d-flex gap-2 gap-md-3'>
                                <div className='icon-holder flex-center-align p-3'>
                                    <FaRegAddressBook className='display-4' />
                                </div>
                                <div className='d-grid align-content-center gap-1'>
                                    <h6 className='m-0 fw-semibold'>Our Office</h6>
                                    <p className='m-0 p-color fs-14'>Ahmedabad, surat</p>
                                </div>
                            </div>
                            <Link className='h-100 d-grid align-content-center link-text fw-semibold'>
                                <span className='gap-2 flex-center-align'>
                                    Visit us <FaArrowRightLong />
                                </span>
                            </Link>
                        </div>
                    </Col>
                    <Col className="col-12 col-sm-6 d-grid">
                        <div className='custom-other-list-item p-3 p-md-4 gap-2 flex-between-align'>
                            <div className='d-flex gap-2 gap-md-3'>
                                <div className='icon-holder flex-center-align p-3'>
                                    <MdMessage className='display-4' />
                                </div>
                                <div className='d-grid align-content-center gap-1'>
                                    <h6 className='m-0 fw-semibold'>Via chat</h6>
                                    <p className='m-0 p-color fs-14'>Get instant answers.</p>
                                </div>
                            </div>
                            <Link className='h-100 d-grid align-content-center link-text fw-semibold'>
                                <span className='gap-2 flex-center-align'>
                                    Let's chat <FaArrowRightLong />
                                </span>
                            </Link>
                        </div>
                    </Col>
                    <Col className="col-12 col-sm-6 d-grid">
                        <div className='custom-other-list-item p-3 p-md-4 gap-2 flex-between-align'>
                            <div className='d-flex gap-2 gap-md-3'>
                                <div className='icon-holder flex-center-align p-3'>
                                    <FaAt className='display-4' />
                                </div>
                                <div className='d-grid align-content-center gap-1'>
                                    <h6 className='m-0 fw-semibold'>Report Issue</h6>
                                    <p className='m-0 p-color fs-14'>Get priority support.</p>
                                </div>
                            </div>
                            <Link className='h-100 d-grid align-content-center link-text fw-semibold'>
                                <span className='gap-2 flex-center-align'>
                                    Send report <FaArrowRightLong />
                                </span>
                            </Link>
                        </div>
                    </Col>
                    <Col className="col-12 col-sm-6 d-grid">
                        <div className='custom-other-list-item p-3 p-md-4 gap-2 flex-between-align'>
                            <div className='d-flex gap-2 gap-md-3'>
                                <div className='icon-holder flex-center-align p-3'>
                                    <IoIosPeople className='display-4' />
                                </div>
                                <div className='d-grid align-content-center gap-1'>
                                    <h6 className='m-0 fw-semibold'>Our community</h6>
                                    <p className='m-0 p-color fs-14'>Connect with users.</p>
                                </div>
                            </div>
                            <Link className='h-100 d-grid align-content-center link-text fw-semibold'>
                                <span className='gap-2 flex-center-align'>
                                    Join us now <FaArrowRightLong />
                                </span>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* FAQ's */}
            <div className='py-5 px-3'>
                <CommonFAQ />
            </div>

            {/* Brands list */}
            <CommonBrands />

            {/* insights list */}
            <CommonInsights />


            <ToastContainer />
        </div>
    )
}

export default ContactUs
