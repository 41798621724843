import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Testimonials from './Testimonials';
import { IoShirtOutline } from "react-icons/io5";
import { IoPricetagsOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { GiKnot } from "react-icons/gi";
import { instaUserData, ProductSliderList } from '../../Data/localData';
import { siteConfig } from '../../Config/Config';
import BuyInBulkSlider from '../../Components/BuyInBulkSlider';

function HomeSelf() {

    document.title = `Home | ${siteConfig?.name}`;

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: "linear"
    };

    function splitIntoChunks(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return result;
    }

    const chunks = splitIntoChunks(instaUserData, 4);

    return (
        <div className='home-self-scss pt-5'>

            {/* Slider and Heading */}
            <Container className='text-center py-5 home-self-top-slider-container flex-center-align '>
                <div className='home-self-top-slider-container-width-fix'>
                    <div className='d-grid gap-2'>
                        <span className='p-color fs-20 fs-md-16'>Printfuse: #1 choice for print apparel</span>
                        <h1 className='fw-bold m-0'>Create custom products online</h1>
                        <span className='p-color fs-20 fs-md-16'>Turn your ideas into premium products that leave a lasting impression</span>
                    </div>
                    <div className="slider-container pt-4">
                        <Slider {...settings}>
                            <div className='custom-slider-items'>
                                <img src={require('../../Assets/Images/dtc-hero-animation-1.png')} alt="" />
                            </div>
                            <div className='custom-slider-items'>
                                <img src={require('../../Assets/Images/dtc-hero-animation-2.png')} alt="" />
                            </div>
                            <div className='custom-slider-items'>
                                <img src={require('../../Assets/Images/dtc-hero-animation-3.png')} alt="" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </Container>

            {/* Custom 3 info */}
            <Container className='py-5'>
                <Row className='gy-4 gy-sm-5 gy-md-0 '>
                    <Col className='col-12 col-md-4 d-grid gap-3 text-center px-sm-4'>
                        <div className='flex-center-align'>
                            <IoShirtOutline className='fs-35 color-info' />
                        </div>
                        <div className='d-grid gap-1'>
                            <h6 className='text-uppercase m-0 fw-semibold'>Easy Order</h6>
                            <p className='m-0 p-color fs-14'>Our intuitive Design Maker and fast ordering process makes it almost effortless</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4 d-grid gap-3 text-center px-sm-4'>
                        <div className='flex-center-align'>
                            <img src={require('../../Assets/Images/hoodie-sweatshirt-svgrepo-com.png')} style={{ height: '35px', width: '35px' }} alt="" />
                        </div>
                        <div className='d-grid gap-1'>
                            <h6 className='text-uppercase m-0 fw-semibold'>345 PREMIUM PRODUCTS</h6>
                            <p className='m-0 p-color fs-14'>Choose from our vast catalog of customisable premium products</p>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-4 d-grid gap-3 text-center px-sm-4'>
                        <div className='flex-center-align'>
                            <IoPricetagsOutline className='fs-35 color-info' />
                        </div>
                        <div className='d-grid gap-1'>
                            <h6 className='text-uppercase m-0 fw-semibold'>BULK ORDER DISCOUNTS</h6>
                            <p className='m-0 p-color fs-14'>Order products for your team, event, or organization and save up to 55%</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Steps to order */}
            <Container className='py-5'>
                <h3 className='fw-semibold m-0 text-center pb-5'>How to get your custom goodies</h3>
                <Row className='gy-5 gy-md-0'>
                    <Col className='px-sm-0 col-12 col-sm-6 col-md-4 custom-step-items'>
                        <div className='d-grid text-center gap-3'>
                            <div className='flex-center-align'>
                                <div className='custom-img-container'>
                                    <img src={require('../../Assets/Images/Screenshot 2024-07-19 123550.jpg')} alt="" />
                                    <div className='custom-top-steps d-flex d-md-none fw-semibold'>1</div>
                                </div>
                            </div>
                            <div className='flex-center-align custom-steps d-none d-md-flex'>
                                <span>1</span>
                            </div>
                            <h5 className='m-0 fw-semibold'>Pick a product</h5>
                            <p className='p-color m-0 px-sm-4 fs-sm-14'>Choose from 345 premium products</p>
                        </div>
                    </Col>
                    <Col className='px-sm-0 col-12 col-sm-6 col-md-4 custom-step-items'>
                        <div className='d-grid text-center gap-3'>
                            <div className='flex-center-align'>
                                <div className='custom-img-container'>
                                    <img src={require('../../Assets/Images/Screenshot 2024-07-19 123629.jpg')} alt="" />
                                    <div className='custom-top-steps d-flex d-md-none fw-semibold'>2</div>
                                </div>
                            </div>
                            <div className='flex-center-align custom-steps d-none d-md-flex'>
                                <span>2</span>
                            </div>
                            <h5 className='m-0 fw-semibold'>Create a design</h5>
                            <p className='p-color m-0 px-sm-4 fs-sm-14'>Use built-in tools to design directly on your product</p>
                        </div>
                    </Col>
                    <Col className='px-sm-0 col-12 col-sm-6 col-md-4 custom-step-items'>
                        <div className='d-grid text-center gap-3'>
                            <div className='flex-center-align'>
                                <div className='custom-img-container'>
                                    <img src={require('../../Assets/Images/Screenshot 2024-07-19 123659.jpg')} alt="" />
                                    <div className='custom-top-steps d-flex d-md-none fw-semibold'>3</div>
                                </div>
                            </div>
                            <div className='flex-center-align custom-steps d-none d-md-flex'>
                                <span>3</span>
                            </div>
                            <h5 className='m-0 fw-semibold'>Order the product</h5>
                            <p className='p-color m-0 px-sm-4 fs-sm-14'>Sit tight while we fulfill and ship your order</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* SubCategory  */}
            <Container className='py-5'>
                <h3 className='fw-semibold m-0 text-center'>Customize your desired products fast using our free Design Maker</h3>
                <p className='pb-4 p-color m-0 fs-sm-14 text-center pt-2'>Personalize outfits for yourself or for your company swag</p>
                <Row className='gy-5 gy-md-0'>
                    <Col className='col-12 col-md-6 d-grid custom-back-content-container'>
                        <div className='d-grid align-content-center gap-3 gap-sm-4 text-center p-4 custom-back-content'>
                            <h2 className='fw-bold m-0 fs-sm-20'>Explore Men's Clothing</h2>
                            <div className='flex-center-align'>
                                <Link className='fw-semibold flex-center-align fs-sm-14 px-2 py-1 px-sm-3 py-sm-2'>
                                    Browse catalog
                                    <i className="bi bi-caret-right-fill"></i>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-6 col-md-3 d-grid custom-back-img-container'>
                        <Link className='d-grid link-to-normal custom-back-img'>
                            <img src={require('../../Assets/Images/sweatshirt.png')} alt="" />
                            <span className='fw-semibold px-2 py-1 px-sm-3 py-sm-2 fs-sm-12'>Sweat Shirt</span>
                        </Link>
                    </Col>
                    <Col className='col-6 col-md-3 d-grid custom-back-img-container'>
                        <Link className='d-grid link-to-normal custom-back-img'>
                            <img src={require('../../Assets/Images/0e62ae87da7d32dfb60d6dadc3744346_l.jpg')} alt="" />
                            <span className='fw-semibold px-2 py-1 px-sm-3 py-sm-2 fs-sm-12'>Hoodie</span>
                        </Link>
                    </Col>
                </Row>
                <Row className='gy-5 gy-md-0 pt-4'>
                    <Col className='col-12 col-md-6 custom-back-content-container d-grid d-md-none'>
                        <div className='d-grid align-content-center gap-3 gap-sm-4 text-center p-4 custom-back-content' style={{ backgroundColor: '#b33b72' }}>
                            <h2 className='fw-bold m-0 fs-sm-20'>Explore Women's Clothing</h2>
                            <div className='flex-center-align'>
                                <Link className='fw-semibold flex-center-align fs-sm-14 px-2 py-1 px-sm-3 py-sm-2' style={{ color: '#b33b72' }}>
                                    Browse catalog
                                    <i className="bi bi-caret-right-fill"></i>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-6 col-md-3 d-grid custom-back-img-container'>
                        <Link className='d-grid link-to-normal custom-back-img'>
                            <img src={require('../../Assets/Images/t-shirt.png')} alt="" />
                            <span className='fw-semibold px-2 py-1 px-sm-3 py-sm-2 fs-sm-12'>T-Shirt</span>
                        </Link>
                    </Col>
                    <Col className='col-6 col-md-3 d-grid custom-back-img-container'>
                        <Link className='d-grid link-to-normal custom-back-img'>
                            <img src={require('../../Assets/Images/leggings.png')} alt="" />
                            <span className='fw-semibold px-2 py-1 px-sm-3 py-sm-2 fs-sm-12'>Leggings</span>
                        </Link>
                    </Col>
                    <Col className='col-12 col-md-6 custom-back-content-container d-none d-md-grid'>
                        <div className='d-grid align-content-center gap-3 gap-sm-4 text-center p-3 px-5 custom-back-content' style={{ backgroundColor: '#b33b72' }}>
                            <h2 className='fw-bold m-0'>Explore Women's Clothing</h2>
                            <div className='flex-center-align'>
                                <Link className='fw-semibold flex-center-align fs-sm-14 px-2 py-1 px-sm-3 py-sm-2' style={{ color: '#b33b72' }}>
                                    Browse catalog
                                    <i className="bi bi-caret-right-fill"></i>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Testimonials */}
            <Container fluid className='custom-bg-color'>
                <Testimonials />
            </Container>

            {/* Design your new pieces Banner */}
            <Container className='py-5'>
                <h3 className='fw-semibold m-0 text-center pb-4 pt-4'>Design your new pieces with confidence</h3>
                <div className='flex-center-align'>
                    <div className='d-grid custom-self-home-banner'>
                        <div className='flex-center-align custom-self-home-banner-img-container'>
                            <div className='custom-self-home-banner-img flex-center-align'>
                                <img src={require('../../Assets/Images/design-maker__900.png')} alt="" />
                            </div>
                        </div>
                        <div className='pt-5'>
                            <Row className='gy-4'>
                                <Col className='col-12 col-md-6 d-grid'>
                                    <div className='d-flex align-items-start gap-1'>
                                        <i className="bi bi-check-lg color-info fs-25 fs-sm-20"></i>
                                        <div className='w-100 d-grid gap-1 pt-1'>
                                            <h5 className='fw-semibold m-0 fs-sm-16'>Free and easy to get started</h5>
                                            <p className='p-color fs-14 m-0 fs-sm-12'>Skip expensive subscriptions and complex design software</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6 d-grid'>
                                    <div className='d-flex align-items-start gap-1'>
                                        <i className="bi bi-check-lg color-info fs-25 fs-sm-20"></i>
                                        <div className='w-100 d-grid gap-1 pt-1'>
                                            <h5 className='fw-semibold m-0 fs-sm-16'>Experiment with trendy designs</h5>
                                            <p className='p-color fs-14 m-0 fs-sm-12'>Pick and choose from a collection of clipart, fonts, shapes, and stock photos</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6 d-grid'>
                                    <div className='d-flex align-items-start gap-1'>
                                        <i className="bi bi-check-lg color-info fs-25 fs-sm-20"></i>
                                        <div className='w-100 d-grid gap-1 pt-1'>
                                            <h5 className='fw-semibold m-0 fs-sm-16'>What you see is what you get</h5>
                                            <p className='p-color fs-14 m-0 fs-sm-12'>Use a tool that’s built with the end product in mind</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='col-12 col-md-6 d-grid'>
                                    <div className='d-flex align-items-start gap-1'>
                                        <i className="bi bi-check-lg color-info fs-25 fs-sm-20"></i>
                                        <div className='w-100 d-grid gap-1 pt-1'>
                                            <h5 className='fw-semibold m-0 fs-sm-16'>No time to design? No worries!</h5>
                                            <p className='p-color fs-14 m- fs-sm-120'>Choose what you like from quick designs, tweak if needed, and place your order</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Handle corporate gifts */}
            <div className='custom-gift-style pt-5'>
                <Container className='py-4'>
                    <h3 className='fw-semibold m-0 text-center pb-4 pt-4'>Handle corporate gifts with ease</h3>
                    <Row>
                        <Col className='col-12 col-md-6 d-grid'>
                            <div className='custom-gift-style-img p-3 p-sm-4 flex-center-align'>
                                <div>
                                    <img src={require('../../Assets/Images/merch-sharing__1080.png')} alt="" />
                                </div>
                                <span className='p-2 py-1 py-sm-3 p-sm-3'><GiKnot className='fs-25 fs-sm-14' /></span>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-6 d-grid'>
                            <div className='custom-gift-style-content d-grid gap-2 gap-md-4 align-content-start py-3 py-sm-4 text-center text-md-start'>
                                <h5 className='fw-semibold m-0 '>Share your merch</h5>
                                <p className='fs-sm-14 p-color m-0'>Get your team matching gear for special occasions with MerchShare. Find a product from our catalog, customize it, and send out your template link. Pay for everything or let them pay for themselves. That’s it! Sit back and relax as giftees get their group merch.</p>
                                <div className='flex-align-center pt-2'>
                                    <Link className='py-1 py-sm-2 px-2 px-sm-3 fs-sm-14 fw-semibold link-to-normal'>Learn more</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <BuyInBulkSlider products={ProductSliderList} />

            {/* inspiration List og images */}
            <Container fluid className='px-0 py-5 custom-insta-holder'>
                <h2 className='fw-semibold m-0 text-center pb-5 pt-4 px-3'>Draw inspiration from these fresh custom looks designed with Printfuse</h2>
                <Row className='g-0'>
                    {
                        chunks?.map((item, index) => {
                            return (
                                <Col key={index} className='col-12 col-lg-6 p-0 d-flex'>
                                    {
                                        item.map((subItem, subIndex) => {
                                            return (
                                                <Link key={subIndex} className='w-100 custom-insta-img-holder d-grid'>
                                                    <img src={subItem.thumbnail} alt="" />
                                                    <div className='custom-insta-content d-grid align-content-center text-center gap-1 gap-sm-2'>
                                                        <span className='fs-14 fs-sm-10 fw-light'>@{subItem?.name}</span>
                                                        {
                                                            (subItem?.hashTags) &&
                                                            subItem?.hashTags.map((petaItem, petaIndex) => {
                                                                return (
                                                                    <span key={petaIndex} className='fs-14 fs-sm-10'>#{petaItem}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default HomeSelf;