import React from 'react'
import { Link } from 'react-router-dom'

function NewsLetter() {
    return (
        <div>
            <div className='final-end-banner py-4 py-sm-4 px-4 px-sm-5 flex-center-align'>
                <div className='banner-content d-grid align-content-center gap-3 gap-md-4'>
                    <h2 className='m-0 fw-semibold'>Get the latest updates</h2>
                    <p className='m-0'>Subscribe to get our most-popular proposal eBook and more top revenue content to help you send docs faster.</p>
                    <div className='d-grid d-sm-flex gap-2 custom-form'>
                        <input type="text" className='w-100 px-2 py-2 py-md-3' placeholder='Your email address' />
                        <Link className='text-center py-2 py-md-3 lh-md-lg fw-semibold'>Subscribe</Link>
                    </div>
                    <span className='p-0'>Don't worry we don't spam.</span>
                </div>
                <div className='banner-img d-none d-md-flex'>
                    <img src={require('../Assets/Images/Screenshot 2024-08-29 184024.png')} alt="" />
                </div>
            </div>
        </div>
    )
}

export default NewsLetter