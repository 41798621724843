import React from 'react'
import { Container } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import Slider from 'react-slick';

function CustomerReview() {

    const settingsOne = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        speed: 8000,
        cssEase: "linear",
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "200px",

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 400,
                settings: {
                    centerPadding: "0px",
                }
            }
        ]
    };

    const settingsTwo = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        speed: 8000,
        cssEase: "linear",
        className: "center",
        centerMode: true,
        centerPadding: "200px",
        rtl: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "100px",
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 400,
                settings: {
                    centerPadding: "0px",
                }
            }
        ]
    };

    const listDataOne = [
        {
            name: 'Sarah Edrissi',
            role: 'Lead Marketing',
            review: "We are based in Europe and the latest Data Protection Regulation forces us to look for service suppliers that comply with this regulation. As we look to create our website, this builder is just outstanding!"
        },
        {
            name: 'John Doe',
            role: 'Product Manager',
            review: "This tool has completely changed the way we manage our product launches. The flexibility and ease of use are second to none!"
        },
        {
            name: 'Emily Chang',
            role: 'UX Designer',
            review: "The intuitive design and user-friendly interface have made it easier for our team to collaborate on projects. Highly recommended!"
        },
        {
            name: 'Michael Johnson',
            role: 'Software Engineer',
            review: "As a developer, I appreciate the powerful features and seamless integration with our existing tools. It has significantly improved our workflow!"
        }
    ]

    const listDataTwo = [
        {
            name: 'Laura Thompson',
            role: 'Sales Director',
            review: "Our sales have seen a remarkable increase since we started using this platform. The analytics and insights provided are incredibly valuable."
        },
        {
            name: 'David Kim',
            role: 'Operations Manager',
            review: "The automation features have streamlined our operations, reducing manual work and increasing efficiency across the board."
        },
        {
            name: 'Sophia Martinez',
            role: 'Content Strategist',
            review: "Creating and managing content has never been easier. The intuitive tools and templates save us a lot of time while maintaining high quality."
        },
        {
            name: 'James Anderson',
            role: 'Customer Support Lead',
            review: "Our customer satisfaction rates have improved drastically thanks to the enhanced support features and real-time feedback options."
        }
    ];

    return (
        <div className='pb-5 customer-review-page'>
            <Container fluid className='p-3 pt-lg-0'>
                <Container fluid className='customer-review-page-container pt-5 border-rad-md-half'>
                    <Container className='cs-container-lg py-5'>
                        <div className='flex-center-align pb-4 pb-sm-5'>
                            <div className='text-center d-grid gap-3 gap-sm-4'>
                                <div className='flex-center-align'>
                                    <div className='custom-home-title-light'>
                                        <GoDotFill className='fs-12' />
                                        <span className='fs-12'>Clients feedback</span>
                                    </div>
                                </div>
                                <h1 className='h1 m-0 d-flex justify-content-center gap-3 flex-wrap'>
                                    Don't
                                    <span className='custom-diff-title-2'>just take our words</span>
                                    for it!
                                </h1>
                            </div>
                        </div>
                        <div className="slider-container">
                            <div className='shade-one'></div>
                            <Slider {...settingsOne}>
                                {
                                    listDataOne?.map((item, index) => {
                                        return (
                                            <div key={index} className='custom-review-list-container text-center'>
                                                <div className='custom-review-list-item p-3 p-sm-4 p-lg-5 gap-3 border-rad-md-half'>
                                                    <div className='d-flex'>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                    <div>
                                                        <p className='fs-22 m-0 fs-md-16 fs-sm-14 text-start'>"{item?.review}"</p>
                                                    </div>
                                                    <div className='d-grid gap-1 text-start'>
                                                        <h4 className='fw-semibold'>{item?.name}</h4>
                                                        <p className='m-0'>{item?.role}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                            <div className='shade-two'></div>
                        </div>
                        <div className="slider-container pt-4 pt-sm-5">
                            <div className='shade-one'></div>
                            <Slider {...settingsTwo}>
                                {
                                    listDataTwo?.map((item, index) => {
                                        return (
                                            <div key={index} className='custom-review-list-container text-center'>
                                                <div className='custom-review-list-item p-3 p-sm-4 p-lg-5 gap-3 border-rad-md-half'>
                                                    <div className='d-flex'>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                    <div>
                                                        <p className='fs-22 m-0 fs-md-16 fs-sm-14 text-start'>"{item?.review}"</p>
                                                    </div>
                                                    <div className='d-grid gap-1 text-start'>
                                                        <h4 className='fw-semibold'>{item?.name}</h4>
                                                        <p className='m-0'>{item?.role}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                            <div className='shade-two'></div>
                        </div>

                    </Container>
                </Container>
            </Container>
        </div>
    )
}

export default CustomerReview