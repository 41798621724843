import React from 'react';
import HomeSlider from './HomeSlider';
import CategorySlider from './CategorySlider';
// import ProductListPage from './ProductListPage';
// import VideoContent from './VideoContent';
import { siteConfig } from '../../Config/Config';
import VideoTab from './VideoTab';
import PriceList from './PriceList';
import CustomerReview from '../../Components/CustomerReview';
import FAQ from './FAQ';
import Insights from '../../Components/Insights';
import Banner from './Banner';
import ClientList from './ClientList';
import ProductListSlider from './ProductListSlider';

document.title = `Home | ${siteConfig?.name}`;

function Home() {

    return (
        <div>
            <HomeSlider />
            <CategorySlider />
            <ClientList />
            <VideoTab />
            <ProductListSlider />
            <PriceList />
            <CustomerReview />
            <FAQ />
            <Insights />
            <Banner />
            {/* <ProductListPage /> */}
            {/* <VideoContent /> */}
        </div>
    );
}

export default Home;
