import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PiStarFourFill } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import { SiGoogleforms } from "react-icons/si";
import { SlDiamond } from "react-icons/sl";
import { FaRegHandshake } from "react-icons/fa";
import { LuClipboardSignature } from "react-icons/lu";
import { IoLayersOutline } from "react-icons/io5";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import CommonFAQ from '../../Components/CommonFAQ';
import CommonClient from '../../Components/CommonClient';
import CommonBrands from '../../Components/CommonBrands';
import CommonBanner from '../../Components/CommonBanner';

function FeaturesPage() {

    const featuresData = [
        {
            title: "Share tools quickly and confidently in minutes",
            description: "This powerfull tool eliminates the need to leave Salesforce to get things done as I can create a custom proposal with dynamic pricing tables. You can also customize your own dynamic versions.",
            thumbnail: require('../../Assets/Images/template/Screenshot 2024-09-03 124834.png'),
        },
        {
            title: "Connect every part of your entire business",
            description: "Keep data consistent, with native CRM integrations that streamline your entire Tool workflow.",
            thumbnail: require('../../Assets/Images/template/Screenshot_1.png'),
            label: "See all integrations",
            value: "/page-integrations"
        },
        {
            title: "Maintain compliance and control your apps",
            description: "Improve security and trust with built-in legally binding e-Signatures. Create pre-approved templates, content blocks and lock all legal information to prevent costly mistakes.",
            thumbnail: require('../../Assets/Images/template/Screenshot_2.png'),
        },
        {
            title: "Review quickly and confidently",
            description: "Get real-time access to approvals, comments and version tracking. Smart features like variables and conditional logic help you eliminate Tool errors.",
            thumbnail: require('../../Assets/Images/template/Screenshot_3.png'),
        },
    ]

    const listData = [
        {
            icon: <SiGoogleforms />,
            name: "Proposals",
            descritpion: "Share Tools in minutes with pre-built, customizable templates."
        },
        {
            icon: <SlDiamond />,
            name: "Quotes",
            descritpion: "Find out in real time when they’re opened, viewed and signed."
        },
        {
            icon: <FaRegHandshake />,
            name: "Contracts",
            descritpion: "Use our native CRM integrations to pull customer data into your Tools fast."
        },
        {
            icon: <LuClipboardSignature />,
            name: "eSignatures",
            descritpion: "Keep your data secure with our legally-binding e-Signature software."
        },
        {
            icon: <IoLayersOutline />,
            name: "Forms",
            descritpion: "Share via direct link or by embedding them on your website, no coding necessary."
        },
        {
            icon: <RiMoneyRupeeCircleFill />,
            name: "Payments",
            descritpion: "Collect them instantly right from your contract, while increasing your close rate by 36%."
        },
    ]

    return (
        <div className='custom-features-page'>

            <Container className='cs-container-xl pb-4 pb-sm-5'>
                <Row className='gy-4 gy-md-5'>
                    <Col className='col-12 pb-0 pt-4 pt-sm-5 text-center features-title-style d-grid'>
                        <div className='py-md-4 d-grid gap-4'>
                            <h1 className='display-4 m-0 fw-semibold'>Why Printfuse?</h1>
                            <PiStarFourFill className='first-icon d-none d-lg-flex' />
                            <PiStarFourFill className='second-icon d-none d-lg-flex' />
                            <div className='flex-center-align'>
                                <p className='p-color m-0 cs-container-md fs-sm-12 fs-md-14'>
                                    We focus on helping you to make useful content more accessible with an utlimate goal for a good sharing profit as a content creator.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12'>
                        <Container className='cs-container-lg'>
                            <Row className='gy-4 gy-md-5 features-list-style'>
                                {
                                    featuresData.map((item, index) => (
                                        <Col key={index} className='col-12 d-grid'>
                                            <div className='features-list-style-item p-3 p-md-4 gap-3'>
                                                <div className='content-holder gap-3 gap-md-4 px-2 px-md-0'>
                                                    <h5 className='color-temp m-0 fw-semibold'>{`${((index + 1) < 10) ? '0' : ''}${index + 1}.`}</h5>
                                                    <h2 className='h1 fw-semibold m-0 fs-sm-16 pe-0 pe-md-5'>
                                                        {item?.title}
                                                    </h2>
                                                    <p className='p-color fs-sm-14 pe-0 pe-md-5 m-0'>{item?.description}</p>
                                                    {
                                                        item?.label &&
                                                        <div className='d-flex'>
                                                            <Link className='color-temp fw-semibold text-decoration-none d-flex gap-2'>
                                                                {item?.label}
                                                                <i className="bi bi-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='img-holder'>
                                                    <img src={item?.thumbnail} alt="" />
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='py-4 py-sm-5 diff-list'>
                <Container className='cs-container-lg py-4 py-sm-5'>
                    <Row className='gy-3 gy-sm-4'>
                        <Col className='col-12 flex-center-align pb-2 pb-sm-3 pb-lg-4'>
                            <h2 className='h1 m-0 fw-semibold text-center'>
                                Review quickly and <br /> confidently using <span className='color-temp'>Printfuse.</span>
                            </h2>
                        </Col>
                        {
                            listData.map((item, index) => (
                                <Col key={index} className='col-12 col-sm-6 col-lg-4 d-grid'>
                                    <Link className='diff-list-items gap-2 gap-sm-3 p-3 p-sm-4'>
                                        <div className='flex-between-align'>
                                            <span className='h1 fs-sm-40'>{item?.icon}</span>
                                            <i className="h3 bi bi-arrow-up-right"></i>
                                        </div>
                                        <div className='d-grid gap-1'>
                                            <h5 className='fw-semibold m-0 fs-sm-14'>{item?.name}</h5>
                                            <p className='m-0 fs-sm-12'>{item?.descritpion}</p>
                                        </div>
                                    </Link>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </Container>

            <div className='py-4 py-sm-5'>
                <div className='py-4 py-sm-5'>
                    <CommonFAQ />
                </div>
            </div>
            <CommonClient />
            <div className='pt-5'>
                <CommonBrands />
            </div>

            <div className='pb-4 pb-sm-5 px-2 px-sm-0'>
                <CommonBanner
                    title={"Create stunning websites that fits your needs."}
                    buttonText={"Try printfuse today"}
                    buttonDescription={"14-day trial, no credit card required."}
                    imgURL={require('../../Assets/Images/template/Untitled design (8).png')}
                    imgRatio={"1/1"}
                />
            </div>

        </div>
    )
}

export default FeaturesPage