import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { frontEnd_API, header, SERVER_URL } from '../../../Config/Config';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import NewsLetter from '../../../Components/NewsLetter';

function SingleBlogDetails() {

    const params = useParams();
    const [data, setData] = useState();
    const [socialList, setSocialList] = useState();
    const getCategoryBlog = async () => {
        try {
            const { data } = await axios.get(`${frontEnd_API.blogList}/detail/${params?.blogId}`, header)
            console.log("data::", data);
            setData(data?.data)
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    const getSocialList = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getSocial, header)
            console.log("data::", data);
            setSocialList(data?.data)
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getCategoryBlog();
        getSocialList();
    }, [])

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: data?.relatedBlogData?.length > 5 ? 5 : data?.relatedBlogData?.length > 0 ? data?.relatedBlogData?.length : 1,
        slidesToScroll: 1,
        initialSlide: 0,
        // centerMode: true,
        // className: "flex-center-align",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: data?.relatedBlogData?.length > 3 ? 3 : data?.relatedBlogData?.length > 0 ? data?.relatedBlogData?.length : 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: data?.relatedBlogData?.length > 2 ? 2 : data?.relatedBlogData?.length > 0 ? data?.relatedBlogData?.length : 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className='custom-single-blog-style py-5'>
            <Container className="cs-container-xl">
                <Row className='gy-4 gy-sm-5'>
                    <Col classnName="col-12">
                        <Container className='cs-container-md d-grid gap-3 gap-sm-4'>
                            <h1 className='display-3 m-0 fw-semibold text-center'>{data?.name}</h1>
                            <div className='flex-center-align flex-wrap gap-2'>
                                {
                                    (socialList?.length > 0) &&
                                    socialList.map((item, index) => (
                                        <Link
                                            to={item?.value}
                                            target='_blank'
                                            className='icon-list-item ' key={index}
                                        >
                                            <i className={`bi bi-${item?.label}`} />
                                        </Link>
                                    ))
                                }
                            </div>
                        </Container>
                    </Col>
                    <Col className='col-12'>
                        <div className='blog-img-holder'>
                            <img src={SERVER_URL + data?.thumbnail} alt="" />
                        </div>
                    </Col>
                    <Col className='col-12'>
                        <p className='fs-18 fs-sm-16 fs-sm-14 blog-descripiton'>{`${data?.description}`}</p>
                    </Col>
                    {
                        (data?.relatedBlogData?.length > 0) &&
                        <Col className='col-12'>
                            <hr className='mt-0' />
                            <h3 className='fw-semibold m-0 fs-sm-20 mb-3 mb-sm-4'>Related to this topic:</h3>
                            <div className="custom-slider-container">
                                <Slider {...settings}>
                                    {
                                        data?.relatedBlogData?.map((item, index) => (
                                            <div key={index} className='custom-related-slider d-grid gap-2 gap-sm-3'>
                                                <div className='related-slider-img-holder'>
                                                    <img src={SERVER_URL + item?.thumbnail} alt="" />
                                                </div>
                                                <h4 className='fw-semibold m-0 fs-md-18 fs-sm-16'>{item?.name}</h4>
                                                <span></span>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Col>
                    }
                    <Col className='col-12 pt-4 pt-sm-5'>
                        <NewsLetter />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SingleBlogDetails