import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CommonBanner(props = {}) {
    return (
        <Container className='custom-seller-category-banner-final cs-container-xl'>
            <Row className='py-4 py-sm-4 px-4 px-sm-5 flex-center-align'>
                <Col className='col-12 col-md-8'>
                    <div className='banner-content d-grid align-content-center gap-3 gap-md-4 text-center text-md-start'>
                        {props?.title && <h2 className='h1 m-0 fw-semibold'>{props?.title}</h2>}
                        {props?.description && <p className='m-0'>{props?.description}</p>}
                        <div className='d-grid d-sm-flex gap-2 custom-form'>
                            {props?.buttonText && <Link className='text-center py-2 py-md-3 lh-md-lg fw-semibold text-capitalize'>{props?.buttonText}</Link>}
                        </div>
                        {props?.buttonDescription && <span className='p-0 fs-14 fs-sm-12 p-color'>{props?.buttonDescription}</span>}
                    </div>
                </Col>
                <Col className='col-md-4'>
                    <div className='banner-img d-none d-md-flex' style={(props?.imgRatio) && { aspectRatio: props?.imgRatio }}>
                        <img src={props?.imgURL} alt="" />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default CommonBanner
