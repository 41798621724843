import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/sass/style.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from './Routes/routes';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@qpokychuk/graphik-lcg/index.css';
import '@qpokychuk/graphik-lcg/normal.css';
import '@qpokychuk/graphik-lcg/italic.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'aos/dist/aos.css';
import AOS from 'aos';

function App() {
  const user = useSelector((state) => state.user.value);
  const location = useLocation();
  
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);

  useEffect(() => {
    if (user) {
      if (location.pathname.endsWith('/signup') || location.pathname.endsWith('/signin')) {
        navigate('/user');
      }
    }

    if (!user) {
      const { pathname } = location;

      const isRestrictedPath =
        pathname.startsWith('/user/') ||
        pathname === '/user';

      const isSignUpOrSignInPage =
        pathname.endsWith('/signup') ||
        pathname.endsWith('/signin');

      if (isRestrictedPath && !isSignUpOrSignInPage) {
        navigate('/signin');
      }
    }

  }, [user, location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const showHeaderFooter = (location.pathname !== '/signin' && location.pathname !== '/signup');

  return (
    <>
      {showHeaderFooter && <Header />}

      <Routes>
        {routes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Routes>

      {showHeaderFooter && <Footer />}
    </>
  );
}

export default App;