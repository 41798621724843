import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { siteConfig } from '../../Config/Config';

function HelpCenter() {

    const helpContent = [
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim.png'),
            title: 'Get started & connect a store'
        },
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim (1).png'),
            title: 'Design & create'
        },
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim (2).png'),
            title: 'Manage products & orders'
        },
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim (3).png'),
            title: 'Fulfillment & shipping'
        },
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim (4).png'),
            title: 'Payment, Billing & taxes'
        },
        {
            img: require('../../Assets/Images/imgpsh_fullsize_anim (5).png'),
            title: 'About Printfuse & Print on demand'
        },
    ]

    document.title = `Help Center | ${siteConfig?.name}`;
    return (
        <div className='help-center-style'>

            {/* Top Banner */}
            <div className='flex-center-align top-banner py-3 py-sm-5'>
                <div className='d-grid py-4 py-sm-5 px-2 gap-4 gap-sm-5 text-center'>
                    <h1 className='m-0 fw-semibold fs-sm-25'>Hi, how can we help you today?</h1>
                    <div className='flex-between-align gap-1 help-search-bar'>
                        <label htmlFor="search" className='bi bi-search'></label>
                        <input type="text" id='search' className='w-100' placeholder='Search topics and articles' autoComplete='off' />
                    </div>
                    <div className='d-grid text-center gap-3'>
                        <h5 className='fw-semibold'>Start with popular searches</h5>
                        <div className='d-grid d-sm-flex flex-center-align gap-2 search-lists'>
                            <div className='d-flex gap-2'>
                                <Link className='d-flex gap-1 p-2 px-3 fs-14 fs-md-12'><i className='bi bi-search' />Order Status</Link>
                                <Link className='d-flex gap-1 p-2 px-3 fs-14 fs-md-12'><i className='bi bi-search' />Etsy Integration</Link>
                            </div>
                            <div className='d-flex gap-2'>
                                <Link className='d-flex gap-1 p-2 px-3 fs-14 fs-md-12'><i className='bi bi-search' />Shipping setup</Link>
                                <Link className='d-flex gap-1 p-2 px-3 fs-14 fs-md-12'><i className='bi bi-search' />Order routing</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Featured Articles */}
            <div className='featured-div'>
                <Container className='py-5'>
                    <h2 className='m-0 fw-semibold text-center pb-5'>Featured Articles</h2>
                    <Row className='gy-3'>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>What is the Profit Calculator?</span>
                        </Link>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>What should I keep in mind when selling on eBay US?</span>
                        </Link>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>TikTok Shop Fulfillment Policy Update</span>
                        </Link>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>What should I keep in mind when selling on TikTok Shop US?</span>
                        </Link>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>What are the guidelines for publishing adidas® products to my Etsy store?</span>
                        </Link>
                        <Link className='col-12 col-sm-6 col-lg-4 link-to-normal d-flex align-items-start gap-3'>
                            <i class="bi bi-check-circle" />
                            <span>How can I report an issue with my order?</span>
                        </Link>
                    </Row>
                </Container>
            </div>

            {/* Design Tabs */}
            <Container className='help-designs-tab py-5'>
                <Row className='gy-4'>
                    {
                        helpContent.map((item, index) => {
                            return (
                                <Link key={index} className='col-12 col-sm-6 col-md-4 link-to-normal help-designs-tab-item d-grid pt-3'>
                                    <div className='d-grid text-center gap-3 w-100 help-designs-tab-item-contain'>
                                        <div className='flex-center-align custom-img-main-container text-center gap-3'>
                                            <div className='custom-img-container'>
                                                <img src={item.img} alt="" />
                                            </div>
                                        </div>
                                        <div className='flex-center-align custom-cnt-main-container text-center gap-3'>
                                            <div className='custom-cnt-container'>
                                                <h5 className='fw-semibold'>{item.title}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </Row>
            </Container>

            {/* Start Selling Tab */}
            <div className='px-2 py-5'>
                <Container className=''>
                    <Row className='custom-selling-tab flex-between-align p-3 p-sm-4 p-lg-5 py-4'>
                        <Col className='col-12 col-lg-8 d-grid gap-3 gap-sm-4 custom-selling-tab-cnt'>
                            <h4 className='m-0 fw-semibold'>Start selling with Printfuse</h4>
                            <p className='p-color m-0 fs-sm-14'>Learn how to create and sell your products on popular eCommerce platforms like Etsy and Shopify.</p>
                            <div className='pb-3'>
                                <Link className='fw-semibold'>See our guides</Link>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-4 custom-selling-tab-img-container d-none d-lg-block'>
                            <div className='custom-selling-tab-img d-flex'>
                                <div className='custom-selling-tab-img-part-one'></div>
                                <div className='custom-selling-tab-img-part-two flex-center-align'>
                                    <div className='d-grid custom-selling-tab-img-part-two-innner px-3 align-content-center gap-3 h-100'>
                                        <span className='fs-12'>The Essential Guide:</span>
                                        <h5 className='fw-semibold'>How to sell print on demand products with Printuse</h5>
                                        <div className='flex-between-align gap-2 justify-content-start'>
                                            <div className='custom-selling-tab-img-part-one-img'>
                                                <img src={require('../../Assets/Images/try-logo.png')} alt="" />
                                            </div>
                                            <div className='custom-selling-tab-img-part-two-img'>
                                                <img src={require('../../Assets/Images/printfuse.png')} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Still need help? */}
            <div className='py-5'>
                <div className='still-help-tab'>
                    <Container>
                        <Row className='py-5'>
                            <Col className='col-12 col-lg-5 d-grid d-lg-none'>
                                <div className='flex-between-align'>
                                    <div className='custom-img-container'>
                                        <img src={require('../../Assets/Images/d30844c8f8077f1007d214f9d9cc12fef93bd92f.png')} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-7 d-grid align-content-center gap-4 gap-sm-5'>
                                <h1 className='fw-bold m-0'>Still need help?</h1>
                                <h3 className='fw-semibold m-0'>We're here to empower Printfuse Merchants by delivering a delightful <span className='color-info'>support experience</span> that minimizes effort and maximizes success.</h3>
                                <div className='d-grid d-sm-flex flex-between-align gap-4'>
                                    <div className='w-100 d-grid custom-message-btn'>
                                        <p className='p-color'>Get live 1:1 support with one of our helpful Merchant Support agents.</p>
                                        <Link className='fw-semibold flex-center-align gap-2'><i class="bi bi-chat-left-text"></i> Chat now</Link>
                                    </div>
                                    <div className='w-100 d-grid custom-message-btn'>
                                        <p className='p-color'>Send us a message and we'll get back to you in a snap.</p>
                                        <Link className='fw-semibold flex-center-align gap-2'><i class="bi bi-envelope-fill"></i> Message us</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-5 d-none d-lg-grid'>
                                <div className='flex-between-align'>
                                    <div className='custom-img-container'>
                                        <img src={require('../../Assets/Images/d30844c8f8077f1007d214f9d9cc12fef93bd92f.png')} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* New to PrintFuse */}
            <div className='py-5 px-2'>
                <Container className='new-to-printfuse-tab half-border-rad'>
                    <Row className=''>
                        <Col className='col-12 col-lg-7 d-grid align-content-center gap-4 px-4 py-4 py-lg-0 px-sm-5'>
                            <h2 className='fw-bold m-0'>New to Printfuse? I Want To Make Your POD Business a Success</h2>
                            <p className='p-color m-0 fs-sm-14'>Join me, Martin, Printify's in-house Expert, for a live Q&A session. We'll cover topics like account setup, order management, product selection, sales tips, and much, much more.</p>
                            <div className='d-grid d-sm-flex flex-between-align'>
                                <div className='w-100 d-grid custom-message-btn gap-3'>
                                    <span className='fw-semibold d-flex gap-2 fs-18'><i className="bi bi-calendar-check" />Weekly on Mondays and Wednesdays</span>
                                    <div className='d-flex'>
                                        <Link className='fw-semibold flex-center-align gap-2'><i class="bi bi-chat-left-text"></i> Chat now</Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-5'>
                            <div className='flex-between-align'>
                                <div className='custom-img-container'>
                                    <img src={require('../../Assets/Images/Untitled design.png')} alt="" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default HelpCenter