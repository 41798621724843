import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function ClientList() {
    const imgList = [
        require("../../Assets/Images/Screenshot 2024-08-29 123637.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123708.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123811.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123732.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123750.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123811.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123732.png"),
        require("../../Assets/Images/Screenshot 2024-08-29 123637.png"),
    ]
    return (
        <div className='py-4 py-sm-5 pt-0 custom-client-list'>
            <Container className='cs-container-lg py-2 py-sm-3 d-grid gap-3 gap-sm-5' data-aos="fade-up">
                <h4 className='fw-semibold m-0 text-center'>Trusted by thousands of clients more than 4,000+</h4>
                <Row className='gy-4 flex-center-align'>
                    {
                        imgList.map((item, index) => (
                            <Col key={index} className='col-4 col-md-3 col-lg-3'>
                                <Link className='img-holder'>
                                    <img src={item} alt="" />
                                </Link>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}

export default ClientList