import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom'

function Insights() {

    const listDataOne = [
        {
            image: require('../Assets/Images/template/img-02.jpg'),
            date: 'Apr 24, 2024',
            label: "Migrating to Linear 101"
        },
        {
            image: require('../Assets/Images/template/img-03.jpg'),
            date: 'Apr 22, 2024',
            label: "Building your API stack"
        },
        {
            image: require('../Assets/Images/template/img-04.jpg'),
            date: 'Apr 26, 2024',
            label: "UX review presentations"
        }
    ]
    const listDataTwo = [
        {
            image: require('../Assets/Images/template/img-05.jpg'),
            date: 'Apr 24, 2024',
            label: "Migrating to Linear 101"
        },
        {
            image: require('../Assets/Images/template/img-06.jpg'),
            date: 'Apr 22, 2024',
            label: "Building your API stack"
        },
        {
            image: require('../Assets/Images/template/img-07.jpg'),
            date: 'Apr 26, 2024',
            label: "UX review presentations"
        }
    ]

    return (
        <div className='pt-5 custom-insights'>
            <Container className='cs-container-xl'>
                <Row className='justify-content-center gy-3'>
                    <Col className='col-12 d-flex justify-content-center justify-content-md-between flex-wrap gap-3 align-items-end pb-4' data-aos="fade-up">
                        <div className='d-grid gap-3'>
                            <div className='d-flex justify-content-center justify-content-md-start'>
                                <div className='custom-home-title-light'>
                                    <GoDotFill className='fs-12' />
                                    <span className='fs-12'>insights</span>
                                </div>
                            </div>
                            <h2 className='fw-semibold m-0 text-center text-md-start'>Latest posts, tutorials <span className='custom-diff-title'>and updates</span></h2>
                        </div>
                        <Link className='custom-insights-btn d-flex fw-medium gap-2'>View all posts <i className="bi bi-arrow-right"></i></Link>
                    </Col>
                    <Col className='col-12 col-sm-6 col-lg-4 d-grid' data-aos="fade-up">
                        <div className='custom-insights-first p-3 d-grid gap-3'>
                            <div className='img-holder'>
                                <img src={require('../Assets/Images/template/img-01.jpg')} alt="" />
                            </div>
                            <h4 className='fw-semibold m-0'>UX review presentations</h4>
                            <p className='p-color fs-14 fs-sm-12 m-0 lh-sm'>Join the thousands of satisfied users and take your business to the next level today and the power of streamlined business operations.</p>
                            <div className='d-flex align-items-center gap-2'>
                                <div className='avatar-img-holder'>
                                    <img src={require('../Assets/Images/template/img-01.jpg')} alt="" />
                                </div>
                                <div className='d-grid'>
                                    <h6 className='fw-semibold m-0'>David</h6>
                                    <p className='p-color m-0 fs-12 fs-sm-10'>Apr 26, 2024</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-sm-6 col-lg-4 d-grid align-content-between gap-3' data-aos="fade-up" data-aos-delay="150">
                        {
                            listDataOne?.map((item, index) => {
                                return (
                                    <div key={index} className='custom-insights-first p-3 d-flex gap-2'>
                                        <div className='avatar-img-holder' style={{ borderRadius: '.5rem', width: '85px' }}>
                                            <img src={item?.image} alt="" />
                                        </div>
                                        <div className='d-grid gap-1 align-content-start'>
                                            <span className='p-color fs-12 fs-sm-10'>{item?.date}</span>
                                            <h6 className='m-0 fw-semibold'>{item?.label}</h6>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                    <Col className='col-12 col-sm-6 col-lg-4 d-grid align-content-between gap-3' data-aos="fade-up" data-aos-delay="300">
                        {
                            listDataTwo?.map((item, index) => {
                                return (
                                    <div key={index} className='custom-insights-first p-3 d-flex gap-2'>
                                        <div className='avatar-img-holder' style={{ borderRadius: '.5rem', width: '85px' }}>
                                            <img src={item?.image} alt="" />
                                        </div>
                                        <div className='d-grid gap-1 align-content-start'>
                                            <span className='p-color fs-12 fs-sm-10'>{item?.date}</span>
                                            <h6 className='m-0 fw-semibold'>{item?.label}</h6>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Insights