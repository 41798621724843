import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import { testimonialsList } from '../../Data/localData';

function Testimonials() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    return (
        <div className='pb-5 pb-md-3'>
            <Container className='py-4 py-lg-5'>
                <h3 className='fw-semibold text-center pb-3'>Our Testimonials</h3>
                <div className='slider-container custom-product-slider'>
                    <Slider {...settings}>
                        {
                            testimonialsList.map((item, index) => {
                                return (
                                    <div key={index} className='d-grid'>
                                        <Row className='row'>
                                            <Col className='col-md-1 col-xl-2'></Col>
                                            <Col className='col-12 col-md-5 col-xl-4 custom-test-cnt p-3 px-4'>
                                                <i className='custom-test-coma fw-bold'>"</i>
                                                {item.review}
                                            </Col>
                                            <Col className='col-12 col-md-5 col-xl-4 d-grid justify-content-center pt-2 pt-lg-0'>
                                                <div className='d-flex align-items-center custom-test-img gap-3'>
                                                    <div className='custom-div-img'>
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                    <div className='d-grid align-content-center gap-0 gap-md-1'>
                                                        <h5 className='text-uppercase m-0 fw-bold fs-md-16'>{item.name}</h5>
                                                        <span className='fs-md-14'>{item.role}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className='col-md-1 col-xl-2'></Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div>
            </Container>
        </div>
    )
}

export default Testimonials