import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BuyInBulkSlider from '../../Components/BuyInBulkSlider';
import { ProductSliderList } from '../../Data/localData';
import Review from '../../Components/Review';

function CompareToOther() {

    const info = [
        {
            title: "How Can Printfuse Boost My Print-on-Demand Business?",
            image: require('../../Assets/Images/How-Can-Printfuse-Boost-My-Print-on-Demand-Business-768x447.jpg'),
            label: [
                {
                    icon: 'bi-tag',
                    title: 'Value for Your Brand',
                    description: 'No other platform has lower priced neck labels or packaging inserts made 100% on-demand.'
                },
                {
                    icon: "bi-chat-left-dots",
                    title: "Get Dedicated Help",
                    description: "Want an industry expert on hand to help you fine-tune your store? Say hello to your Printfuse account manager."
                },
                {
                    icon: "bi-patch-check-fill",
                    title: "Perfect Quality on Autopilot",
                    description: "Printfuse’s Quality Promise means you can relax when it comes to product quality."
                },
                {
                    icon: "bi-currency-rupee",
                    title: "Get Your Money Back",
                    description: "We’ll give you a rebate on every product moved to Printfuse in your first 3 months."
                },
                {
                    icon: "bi-hand-thumbs-up-fill",
                    title: "We’ll Help You Move",
                    description: "Our dedicated migration managers take all the effort out of switching to a better platform."
                },
            ]
        },
        {
            title: "How Can Printfuse Boost My eCommerce Business?",
            image: require('../../Assets/Images/How-Can-Printfuse-Boost-My-eCommerce-Business-768x447.jpg'),
            label: [
                {
                    icon: "bi-currency-rupee",
                    title: "Max Profit, Zero Risk",
                    description: "Every product is made on-demand, so you don’t have to buy any inventory."
                },
                {
                    icon: "bi-globe",
                    title: "Global Reach",
                    description: "With over 115 printing locations, we can take your business anywhere you want it to go."
                },
                {
                    icon: "bi-box2",
                    title: "Never Go Out of Stock",
                    description: "With over 75 different Print Partners, you don’t have to worry about empty shelves."
                },
                {
                    icon: "bi-gift",
                    title: "Make Even More of the Holidays",
                    description: "With Printfuse Express Delivery, your Christmas selling window just got 7 days longer"
                },
                {
                    icon: "bi-diagram-3-fill",
                    title: "More Products = More Chances to Sell",
                    description: "With more than 900 products, you won’t find more options to add revenue anywhere else."
                },
            ]
        }
    ]

    const features = [
        {
            title: 'Sample orders',
            description: 'Curious about print on demand product quality? With Printful, you can order samples with 20% off and free shipping from the nearest fulfillment center, and make sure the products you sell on your store meet the quality standards of your brand.',
            image: require('../../Assets/Images/back-image2.png')
        },
        {
            title: 'Bulk order discounts',
            description: 'Get a discount of up to 55% when ordering 25+ of the same item (with the chance to mix size, color, and design). Use it for ordering promo merch, giveaway gifts, photoshoot supplies, and more. Check our bulk order discount guide for details.',
            image: require('../../Assets/Images/32-Company-Swag-Ideas-Best-Branded-Merch-for-2024-1024x575.jpg')
        },
    ]

    const rating = [
        {
            name: 'Alex Martinez',
            website: 'Urban Threads Co.',
            description: "Printfuse has been a game-changer for our business over the past two years. The ease of use, high-quality products, and reliable shipping have made it incredibly simple for us to deliver exceptional clothing and accessories to our customers. We appreciate the continuous updates and new features that keep enhancing our experience with Printfuse.",
            image: require('../../Assets/Images/Andris-Mucenieks.jpg')
        },
        {
            name: 'Priya Kapoor',
            website: 'Artistic Impressions',
            description: "We've been partnering with Printfuse for about a year now, and it has significantly boosted our brand's reach. The ability to customize a wide range of products, coupled with efficient shipping, has delighted our customers. We are thrilled with the new product additions and features that Printfuse keeps introducing.",
            image: require('../../Assets/Images/animal-rescues.png')
        },
        {
            name: "Liam O'Sullivan",
            website: 'Celtic Creations',
            description: "For the past 18 months, Printfuse has been instrumental in helping us provide top-notch merchandise to our audience. The platform's user-friendly interface, combined with their high-quality print-on-demand products and timely shipping, has made our business thrive. We love how Printfuse is always innovating and adding new features to support our growth.",
            image: require('../../Assets/Images/Anita-Njoki.jpg')
        }
    ]

    return (
        <div className='custom-compare'>

            <div className='py-5 custom-compare-reason'>
                <Container className='cs-container-lg'>
                    <div className='text-center d-grid gap-2'>
                        <h1 className='m-0 fw-bold'>What Sets the Standard Compared to Others?</h1>
                        <p className='p-color'>Printfuse surpasses other services</p>
                    </div>
                </Container>
                <Container className='cs-container-lg py-5 px-4'>
                    <Row className='custom-compare-row'>
                        <Col className='col-12 col-md-6 d-grid px-3 px-sm-5'>
                            <div className='d-grid gap-3 align-content-start'>
                                <div className='d-grid text-center custom-compare-icon'>
                                    <i class="bi bi-check-circle-fill flex-center-align text-success align-items-start fs-45" />
                                    <h4 className='fw-semibold'>Good print examples</h4>
                                </div>
                                <div className='d-grid gap-2 pb-5'>
                                    <div className='custom-img-holder'>
                                        <img src={require('../../Assets/Images/good t-shirt.png')} alt="" />
                                    </div>
                                    <p className='m-0 fs-sm-14'>Printfuse T-shirts offer vibrant, long-lasting colors and detailed prints that stay sharp after many washes. The fabric is soft, durable, and maintains its shape, ensuring a comfortable fit. Our meticulous craftsmanship guarantees a high-quality product every time.</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-12 col-md-6 d-grid px-3 px-sm-5'>
                            <div className='d-grid gap-3 align-content-start'>
                                <div className='d-grid text-center custom-compare-icon'>
                                    <i class="bi bi-x-circle-fill text-danger flex-center-align align-items-start fs-45" />
                                    <h4 className='fw-semibold'>Bad print examples</h4>
                                </div>
                                <div className='d-grid gap-2 pb-3 pb-sm-5'>
                                    <div className='custom-img-holder'>
                                        <img src={require('../../Assets/Images/bad t-shirt.png')} alt="" />
                                    </div>
                                    <p className='m-0 fs-sm-14'>T-shirts from other services have duller colors and prints that fade and crack quickly. The fabric is rougher and wears out faster, losing its shape and comfort. These T-shirts fail to match the high standards of Printfuse.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='py-5'>
                <Container className='cs-container-lg custom-benifits'>
                    <Row className='gy-4 gy-md-0 gx-0 gx-md-5'>
                        <div className='pb-5'>
                            <h2 className='fw-bold pb-5 m-0 text-center'>Whether You’re a Print-on-Demand Veteran or Newbie, Printfuse was Made to Work with Your Business</h2>
                        </div>
                        {
                            info.map((item, index) => {
                                return (
                                    <Col key={index} className='col-12 col-md-6'>
                                        <div className='d-grid gap-5'>
                                            <div>
                                                <h4 className='m-0 fw-semibold text-center'>
                                                    {item?.title}
                                                </h4>
                                            </div>
                                            <div className='custom-img-holder'>
                                                <img src={item?.image} alt="" />
                                            </div>
                                            <div className='d-grid gap-4 gap-sm-5'>
                                                {
                                                    item?.label
                                                    && item?.label.map((subItem, subIndex) => {
                                                        return (
                                                            <div key={subIndex} className='d-flex gap-3'>
                                                                <i className={`bi ${subItem.icon} d-flex color-info fs-25`} />
                                                                <div className='d-grid gap-2'>
                                                                    <h5 className='m-0 fw-semibold fs-sm-14'>{subItem.title}</h5>
                                                                    <p className='m-0 p-color fs-sm-12'>{subItem.description}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div></div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>

            <div className='py-5'>
                <Container>
                    <Row className='gy-5'>
                        <h3 className='fw-semibold m-0 py-4 text-center'>Extra features you get with Printfuse</h3>

                        {
                            features.map((item, index) => {
                                return (
                                    <Col key={index} className='col-12'>
                                        <Row className={`gy-3 ${(index % 2 === 1) && 'pf-flex-row-reverse pf-flex-md-column-reverse'}`}>
                                            <Col className='col-12 col-md-5 d-grid'>
                                                <div className={`img-holder-5-3`}>
                                                    <img src={item.image} alt="" />
                                                </div>
                                            </Col>
                                            <Col className='col-12 col-md-7 d-grid px-3'>
                                                <div className='d-grid align-content-center text-center text-md-start gap-2'>
                                                    <h5 className='m-0 fw-semibold fs-sm-16'>{item.title}</h5>
                                                    <p className='p-color m-0 fs-14  fs-sm-12'>{item.description}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>

            <BuyInBulkSlider products={ProductSliderList} />

            <Review review={rating} />
        </div>
    )
}

export default CompareToOther