import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { frontEnd_API } from '../../Config/Config'
import { storeUser } from '../../Store/Slices/UserSlice'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { ToastContainer } from 'react-toastify'

function SigninPage() {

    const points = [
        {
            title: 'Achieve Excellence',
            description: 'Connect seamlessly with top ecommerce platforms'
        },
        {
            title: 'Ignite Your Imagination',
            description: 'Use our cutting-edge Design Studio to create standout products'
        },
        {
            title: 'Discover 300+ High-Quality Products',
            description: 'T-shirts starts from just $349'
        },
    ]

    return (
        <div>
            <div className='d-grid align-items-center custom-signin-page'>
                <div className='flex-center-align px-3'>
                    <Container className='py-4'>
                        <Row className='px-2 py-4 px-sm-4 custom-signin-row'>
                            <div>
                                <Link className='bi bi-x-lg custom-close-btn' to={'/'} />
                            </div>
                            <Col className='d-none d-lg-grid col-lg-6 ps-0 ps-lg-3'>
                                <div className='custom-signin-img'>
                                    <img src={require('../../Assets/Images/signin-back.png')} alt="" />
                                </div>
                                <div className='flex-center-align pb-4'>
                                    <div className='d-grid gap-2'>
                                        {
                                            points.map((item, index) => {
                                                return (
                                                    <div key={index} className='d-flex gap-2'>
                                                        <i class="bi bi-check-lg d-flex color-info fs-18" />
                                                        <div className='d-grid gap-1'>
                                                            <h6 className='fw-semibold m-0 fs-16'>{item.title}</h6>
                                                            <p className='m-0 p-color fs-14'>{item.description}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-6 d-grid gap-2 gap-sm-5 px-2 pe-lg-3 align-content-center'>
                                <div className='d-grid gap-2 text-center text-lg-start'>
                                    <h2 className='fw-semibold m-0'>Welcome Back !</h2>
                                </div>
                                <div className='fs-14'>
                                    <FormComponent
                                        action={frontEnd_API.signin}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={false}
                                        submitBTNAlign={'justify-content-center custom-submit-btn'}
                                        loadPage={true}
                                        button={'Sign in'}
                                        formMessage={"Login Successfully !"}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'email',
                                                key: 'email',
                                                required: true,
                                                pattern: pattern.email,
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                pattern: pattern.password,
                                            }
                                        ]}
                                    />
                                    <div className='d-grid gap-3 pt-sm-3 pt-4'>
                                        <span className='fs-14 text-center'>
                                            Create New Account? <Link className='text-decoration-none' to={'/signup'}>Sign up</Link>
                                        </span>
                                    </div>
                                    <hr className='mx-5 border-bg' style={{ opacity: 1 }} />
                                    <div className='text-center'>
                                        Log in As <Link className='text-decoration-none fw-semibold' target='_blank' to={'https://app.printfuse.in/signin'}>Seller</Link>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SigninPage
