import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import Redirection from '../../../Components/Redirection/Redirection';
import { BsDot } from 'react-icons/bs';
import NewsLetter from '../../../Components/NewsLetter';
import axios from 'axios';
import { frontEnd_API, header, SERVER_URL } from '../../../Config/Config';

function BlogCategory() {
    const params = useParams();
    const [catName, setCatName] = useState();
    const [data, setData] = useState();
    const [loader, setLoader] = useState(true);
    const getCategoryBlog = async () => {
        try {
            const check = params?.insightsName.replace(/~/g, '-').replace(/-/g, ' ');
            setCatName(check);
            const { data } = await axios.put(`${frontEnd_API.blogList}/${check}`, {}, header)
            console.log("catName::", catName);
            setData(data?.data)
            setLoader(false);
        }
        catch (e) {
            console.log("err::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getCategoryBlog();
    }, [])

    return (
        <div>
            <Container className='cs-container-xl py-4 py-sm-5 custom-blog-home'>
                <Row className='gy-3 gy-md-4 gy-xl-5 d-flex justify-content-center'>

                    {
                        (loader) ?
                            <div className='flex-center-align min-25vh'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <Col className='col-12 text-center d-grid gap-2'>
                                    <h1 className='text-capitalize fw-semibold m-0'>Category: {catName}</h1>
                                    <p className='m-0 p-color fs-sm-12'> Showed posts under "{catName}" category.</p>
                                </Col>
                                {
                                    (data?.length > 0) &&
                                    data.map((item, index) => (
                                        <Col key={index} className='col-12 col-md-4 d-grid'>
                                            <div className='custom-insights-list-item p-3 d-grid gap-3 gap-sm-4'>
                                                <Link to={`${item?.id}`} className='img-holder-in'>
                                                    <img src={SERVER_URL + item?.thumbnail} alt="" />
                                                    <span className='fw-semibold p-2 fs-12 lh-sm'>{item?.blogCategoryName}</span>
                                                </Link>
                                                <div className='d-grid text-center gap-1'>
                                                    <Link to={`${item?.id}`} className='fw-semibold h4 fs-sm-18'>{item?.name}</Link>
                                                    {/* <div className='flex-center-align gap-1 d-none d-md-flex'>
                                          <div className='first-item-content-avatar'>
                                              <img src={item?.avatar} alt="" />
                                          </div>
                                          <h6 className='m-0 fs-14 fw-semibold'>{item?.name}</h6>
                                          <BsDot className='p-color' />
                                          <span className='p-colot fs-12'>{item?.date}</span>
                                      </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </>
                    }
                    <Col className='col-12 pt-4 pt-sm-5'>
                        <NewsLetter />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BlogCategory