import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { AiFillThunderbolt } from "react-icons/ai";
import { FaWaveSquare } from 'react-icons/fa';
import { GoDotFill, GoGoal } from 'react-icons/go';
import { GoStack } from "react-icons/go";
import { HiOutlinePuzzlePiece } from "react-icons/hi2";
import { IoEarthOutline } from 'react-icons/io5';
import { WiMoonAltThirdQuarter } from "react-icons/wi";

function VideoTab() {
    const [show, setShow] = useState(false);

    const data = [
        {
            icon: <AiFillThunderbolt />,
            label: "Drag-and-Drop Interface"
        },
        {
            icon: <GoStack />,
            label: "Pre-Designed Templates"
        },
        {
            icon: <HiOutlinePuzzlePiece />,
            label: "Fully Responsive Design"
        },
        {
            icon: <GoGoal />,
            label: "Website Customization"
        },
        {
            icon: <FaWaveSquare />,
            label: "UI Elements Library"
        },
        {
            icon: <IoEarthOutline />,
            label: "Global & Local Styles"
        },
        {
            icon: <WiMoonAltThirdQuarter />,
            label: "Third-Party Integrations"
        }
    ]

    return (
        <div className='py-5 py-sm-5 pt-0 pt-sm-5 custom-video-tab'>
            <Container className='cs-container-xl' data-aos="zoom-in" data-aos-duration="1500">
                <div className='custom-video-tab-container border-rad-md-half'>
                    <video autoPlay muted loop preload="none">
                        <source src={require('../../Assets/Video/def033b350b307698516b62e43a3cf0d.mp4')} type="video/mp4" />
                    </video>
                    <div className='custom-back'></div>
                    <div className='custom-play-btn' onClick={() => setShow(true)}>
                        <i className="bi bi-play fs-45"></i>
                    </div>
                </div>
            </Container>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Body className='bg-dark custom-modal-video p-0'>
                    <Container className='custom-modal-video'>
                        <section className='d-grid align-content-center'>
                            <div className='flex-center-align'>
                                <span>
                                    <video autoPlay muted loop preload="none" controls>
                                        <source src={require('../../Assets/Video/def033b350b307698516b62e43a3cf0d.mp4')} type="video/mp4" />
                                    </video>
                                </span>
                            </div>
                            <i className='bi bi-x-lg fs-25' onClick={() => setShow(false)}></i>
                        </section>
                    </Container>
                </Modal.Body>
            </Modal>

            <div className='pt-5 custom-list-of-features'>
                <Container className='cs-container-lg pb-3 py-sm-5'>
                    <div className='flex-center-align pb-4 pb-sm-5' data-aos="fade-up">
                        <div className='text-center d-grid gap-3 gap-sm-4'>
                            <div className='flex-center-align'>
                                <div className='custom-home-title-light'>
                                    <GoDotFill className='fs-12' />
                                    <span className='fs-12'>key features</span>
                                </div>
                            </div>
                            <p className='m-0 fs-25 fs-sm-16'><b className='fw-semibold'>Easy-to-use</b> and powerful page builder, your gateway to <b className='fw-semibold'>intuitive tools</b> and <b className='fw-semibold'>expansive customization</b> options that transform how you <b className='fw-semibold'>build websites</b>.</p>
                        </div>
                    </div>
                    <Row className='gy-4'>
                        {
                            data.map((item, index) => (
                                <Col key={index} className='col-6 col-lg-3 d-grid' data-aos="fade-up">
                                    <div className='fetures-final-list d-grid gap-4 p-2 p-sm-4'>
                                        <span>{item?.icon}</span>
                                        <p className='m-0 fw-medium fs-20 fs-sm-12'>{item?.label}</p>
                                    </div>
                                </Col>
                            ))
                        }
                        <Col className='col-6 col-lg-3 d-grid' data-aos="fade-up">
                            <div className='fetures-final-list d-grid gap-4 p-2 p-sm-4'>
                                <div className='flex-center-align'>
                                    <span><i className="bi bi-arrow-up-right"></i></span>
                                </div>
                                <p className='m-0 fw-medium p-color text-center fs-12'>View all features</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default VideoTab