import React from 'react'
import UserHeader from './UserHeader'
import { Route, Routes } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import userRoutes from '../../Routes/userRoutes'


function Index() {
    return (
        <div>
            <Container className='py-4 py-sm-5 change-container-size'>
                <Row className='g-4'>
                    <Col className='col-12 col-lg-3 px-md-3'>
                        <UserHeader />
                    </Col>
                    <Col className='col-12 col-lg-9 px-md-3'>
                        <Routes>
                            {
                                userRoutes.map((item, index) => {
                                    return (
                                        <Route key={index} path={item.path} element={item.element} />
                                    )
                                })
                            }
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Index
