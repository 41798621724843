import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick'

function CommonBrands() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            }
        ]
    };

    const imgList = [
        require("../Assets/Images/Screenshot 2024-08-29 123637.png"),
        require("../Assets/Images/Screenshot 2024-08-29 123708.png"),
        require("../Assets/Images/Screenshot 2024-08-29 123732.png"),
        require("../Assets/Images/Screenshot 2024-08-29 123750.png"),
        require("../Assets/Images/Screenshot 2024-08-29 123811.png"),
    ]
    return (
        <div>
            <Container className='cs-container-lg py-sm-5 pb-5 common-brand'>
                <h5 className='text-center m-0 fw-semibold pb-3 pb-sm-4'>Trusted by well-known brands.</h5>
                <div className="slider-container">
                    <Slider {...settings}>
                        {
                            imgList.map((item, index) => (
                                <div key={index}>
                                    <div className='common-brand-img-holder'>
                                        <img src={item} alt="" />
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </Container>
        </div>
    )
}

export default CommonBrands