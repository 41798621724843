// ApiData
const BASE_URL = (window?.location?.hostname === 'printfuse.in') ? "https://printfuse.in/" : "http://localhost:3000/"
const SERVER_URL = (window?.location?.hostname === 'printfuse.in') ? "https://api.printfuse.in/" : "http://192.168.0.190:4000/"
const API_SERVER_URL = `${SERVER_URL}`;
const FRONTEND_API_URL = `${API_SERVER_URL}frontend/`;
const BACKEND_API_URL = `${API_SERVER_URL}backend/`;


// frontend auth APIs routes

// frontend API routes
const frontEnd_API = {

    // auth module
    signin: `${FRONTEND_API_URL}auth/signin`,
    signup: `${FRONTEND_API_URL}auth/signup`,
    signout: `${FRONTEND_API_URL}auth/signout`,
    postUpdateProfile: `${FRONTEND_API_URL}auth/profile`,
    postUpdatePassword: `${FRONTEND_API_URL}auth/changepassword`,
    getProfile: `${FRONTEND_API_URL}auth/profile`,
    getIdentity: `${FRONTEND_API_URL}home/identity`,
    getSocial: `${FRONTEND_API_URL}home/social`,
    getcategory: `${FRONTEND_API_URL}home/category`,
    gettag: `${FRONTEND_API_URL}home/tag`,
    getGlobal: `${FRONTEND_API_URL}home/global`,
    checkDomain: `${FRONTEND_API_URL}home/checkdomain`,
    blogcategory: `${FRONTEND_API_URL}home/blogcategory`,
    postAddress: `${FRONTEND_API_URL}address`,
    getAddress: `${FRONTEND_API_URL}address`,
    deleteAddress: `${FRONTEND_API_URL}address`,
    primaryAddress: `${FRONTEND_API_URL}address/primary`,
    blogList: `${FRONTEND_API_URL}blog`,
}

const backEnd_API = {
    postAdmin: `${BACKEND_API_URL}address`
}


// Site Confige

const siteConfig = {
    name: 'Printfuse'
};

const constConfig = {
    user: 'USER',
    seller: 'SELLER'
}

// localStorageNames
const storage = {
    user: 'user',
    store: 'activeStore'
}

const token = JSON.parse(localStorage?.getItem(storage.user))
    ? `Bearer ${JSON.parse(localStorage?.getItem(storage.user))?.token}`
    : 'essentials';

const storeCode = JSON.parse(localStorage?.getItem(storage.user))
    ? `${JSON.parse(localStorage?.getItem(storage.user))?.storeCode}`
    : '';

const header = {
    headers: {
        'token': token,
        'storecode': storeCode,
        'Content-Type': 'application/json'
    }
}




export { BASE_URL, SERVER_URL, API_SERVER_URL, FRONTEND_API_URL, token, storeCode, frontEnd_API, header, storage, backEnd_API, constConfig, siteConfig };