const headerContent = [
    {
        label: 'Features',
        path: '/features',
        image: require('../Assets/Images/banner-1-gallery-2.jpg'),
        // data: [
        //     { label: 'Sell with printfuse', path: '/sell-with-printfuse' },
        //     { label: 'Create a merch store', path: '/create-store', image: require('../Assets/Images/Heading (2).png') },
        //     { label: 'Switch to printfuse', path: '/switch-to-printfuse' },
        //     { label: 'Compare to others', path: '/compare-to-other' }
        // ]
    },
    {
        label: 'Pricing',
        path: '/pricing',
        image: require('../Assets/Images/banner-1-gallery-2.jpg'),
        // data: [
        //     {
        //         label: 'For individuals',
        //         path: '/',
        //         image: require('../Assets/Images/lega-sell.jpg'),
        //     },
        //     {
        //         label: 'For nonprofits',
        //         path: '/',
        //         image: require('../Assets/Images/Heading.png'),
        //     },
        //     { label: 'peer-to-peer fundraising', path: '/' },
        //     { label: 'Discover a cause', path: '/' }
        // ]
    },
    {
        label: 'Insights',
        path: '/insights',
        image: require('../Assets/Images/banner-1-gallery-4.jpg'),
        // data: [
        //     { label: 'custom Shirts', path: '/', image: require('../Assets/Images/x-sales-3.jpg') },
        //     { label: 'custom T-shirts', path: '/', image: require('../Assets/Images/download (1).jpeg') },
        //     { label: 'custom Company Shirts', path: '/' },
        //     { label: 'custom hoodies', path: '/' },
        //     { label: 'custom hats', path: '/' },
        //     { label: 'custom mugs', path: '/', image: require('../Assets/Images/banner-1-gallery-5.jpg') },
        //     { label: 'custom phone case', path: '/' }
        // ]
    },
    {
        label: 'About',
        path: '/about',
        image: require('../Assets/Images/banner-1-gallery-5.jpg'),
        // data: [
        //     { label: 'Blog', path: '/blog' },
        //     { label: 'Help center', path: '/help-center' },
        //     { label: 'Contact Us', path: '/contact-us' },
        //     { label: 'my requests', path: '/my-requests' },
        // ]
    },
    {
        label: 'Contact',
        path: '/contact-us',
        image: require('../Assets/Images/banner-1-gallery-5.jpg'),
    },
    // {
    //     label: 'more',
    //     path: '/',
    //     image: require('../Assets/Images/banner-1-gallery-1.jpg'),
    //     data: [
    //         {
    //             label: "men's clothing",
    //             path: '/',
    //             image: require('../Assets/Images/card-templates-personal.jpg'),
    //             data: [
    //                 { label: "shirts", path: '/', },
    //                 { label: "t-shirts", path: '/', },
    //                 { label: "All-over Shirts", path: '/', },
    //                 { label: "Polo Shirts", path: '/', },
    //                 { label: "Tank tops", path: '/', },
    //                 { label: "3/4 sleeve shirts", path: '/', },
    //                 { label: "long sleeve shirts", path: '/', },
    //                 { label: "Jackets & vests", path: '/', },
    //                 { label: "Hoodies", path: '/', },
    //                 { label: "Sweatshirts", path: '/', },
    //                 { label: "Bottoms", path: '/', },
    //                 { label: "Underwear", path: '/', },
    //                 { label: "Shorts", path: '/', },
    //                 { label: "pants", path: '/', }
    //             ]
    //         },
    //         {
    //             label: "women's clothing",
    //             path: '/',
    //             image: require('../Assets/Images/catalog-thumb-homepage.jpg'),
    //             data: [
    //                 { label: "shirts", path: '/', },
    //                 { label: "t-shirts", path: '/', },
    //                 { label: "All-over Shirts", path: '/', },
    //                 { label: "Polo Shirts", path: '/', },
    //                 { label: "Tank tops", path: '/', },
    //                 { label: "3/4 sleeve shirts", path: '/', },
    //                 { label: "long sleeve shirts", path: '/', },
    //                 { label: "Jackets & vests", path: '/', },
    //                 { label: "Hoodies", path: '/', },
    //                 { label: "Sweatshirts", path: '/', },
    //                 { label: "Bottoms", path: '/', },
    //                 { label: "Underwear", path: '/', },
    //                 { label: "Shorts", path: '/', },
    //                 { label: "pants", path: '/', }
    //             ]
    //         },
    //         {
    //             label: "Kid's & youth clothing",
    //             path: '/',
    //             image: require('../Assets/Images/menu-banner-1.jpg'),
    //             data: [
    //                 { label: "shirts", path: '/', },
    //                 { label: "t-shirts", path: '/', },
    //                 { label: "All-over Shirts", path: '/', },
    //                 { label: "3/4 sleeve shirts", path: '/', },
    //                 { label: "long sleeve shirts", path: '/', },
    //                 { label: "Hoodies", path: '/', },
    //                 { label: "Sweatshirts", path: '/', },
    //                 { label: "Baby bodysuits", path: '/', }
    //             ]
    //         },
    //         {
    //             label: "Accessories",
    //             path: '/',
    //             image: require('../Assets/Images/menu-banner-2.jpg'),
    //             data: [
    //                 { label: "Patches", path: '/' },
    //                 { label: "Pins", path: '/' },
    //                 { label: "bags", path: '/' },
    //                 { label: "Tote bags", path: '/' },
    //                 { label: "Backpacks", path: '/' },
    //                 { label: "Handbags", path: '/' },
    //                 { label: "Flip flops", path: '/' },
    //                 { label: "Shoes", path: '/' },
    //                 { label: "socks", path: '/' },
    //                 { label: "Phone cases", path: '/' },
    //                 { label: "Earphones cases", path: '/' },
    //                 { label: "Laptop cases", path: '/' }
    //             ]
    //         },
    //         {
    //             label: "Home & living",
    //             path: '/',
    //             image: require('../Assets/Images/menu-banner-3.jpg'),
    //             data: [
    //                 { label: "Puzzles", path: '/' },
    //                 { label: "Wall art", path: '/' },
    //                 { label: "Posters", path: '/' },
    //                 { label: "Framed posters", path: '/' },
    //                 { label: "Canvas prints", path: '/' },
    //                 { label: "Home decor", path: '/' },
    //                 { label: "Drinkware", path: '/' },
    //                 { label: "Candles", path: '/' },
    //                 { label: "Water bottles", path: '/' },
    //                 { label: "Mugs", path: '/' },
    //                 { label: "tumblers", path: '/' },
    //                 { label: "Coasters", path: '/' },
    //                 { label: "glassware", path: '/' },
    //                 { label: "Stationery", path: '/' },
    //                 { label: "Postcards", path: '/' },
    //                 { label: "Ball pen", path: '/' },
    //                 { label: "Stickers", path: '/' },
    //                 { label: "Towels", path: '/' },
    //                 { label: "Pet Products", path: '/' },
    //                 { label: "Beauty", path: '/' }
    //             ]
    //         },
    //         {
    //             label: "Hats",
    //             path: '/',
    //             image: require('../Assets/Images/menu-banner-4.jpg'),
    //             data: [
    //                 { label: 'Embroidered hats', path: '/' },
    //                 { label: 'Beanies', path: '/' },
    //                 { label: 'Dad hats / baseball caps', path: '/' },
    //                 { label: 'Snapbacks', path: '/' },
    //                 { label: 'Trucker Hats', path: '/' },
    //                 { label: '5-panel hats', path: '/' },
    //                 { label: 'Mesh hats', path: '/' },
    //                 { label: 'Bucket hats', path: '/' },
    //                 { label: 'Visors', path: '/' }
    //             ]
    //         },
    //     ]
    // },
]

const categorySliderData = [
    {
        title: 'Printed T-shirts',
        image: require('../Assets/Images/card-templates-personal.jpg'),
    },
    {
        title: 'Printed Full Sleeve T-shirts',
        image: require('../Assets/Images/catalog-thumb-homepage.jpg'),
    },
    {
        title: 'Printed T-shirts',
        image: require('../Assets/Images/lega-fundraising.png'),
    },
    {
        title: 'Printed T-shirts',
        image: require('../Assets/Images/card-templates-personal.jpg'),
    },
];

const productImageList = [
    {
        label: 'T-Shirt',
        image: require('../Assets/Images/N6HZFCUw2JdppjOUXsfZvmnsV8.jpg')
    },
    {
        label: 'Hoodies',
        image: require('../Assets/Images/wFuZLxdpqx6KUijvqngNySBBfY.jpg')
    },
    {
        label: 'Tote Bags',
        image: require('../Assets/Images/fOkvFUOdTY4srRYv8dC0FaCOgY.jpg')
    },
    {
        label: 'Mugs',
        image: require('../Assets/Images/RWp7WBDpDLF4Zfm2lB0xzd7loQU.jpg')
    },
    {
        label: 'Long Sleeve Tees',
        image: require('../Assets/Images/t5lNoz2e5cB2C2Dr3eawkvEQ2U.jpg')
    },
    {
        label: 'Eco-Friendly',
        image: require('../Assets/Images/gdeqLZffzya7oT3iVuvOFoLgoNw.jpg')
    },
    {
        label: 'Comfort Colors',
        image: require('../Assets/Images/ORLU1ibjDfn0lTmsaWCF0MPDG4.jpg')
    },
    {
        label: 'Sweatshirt',
        image: require('../Assets/Images/fpatIOVyqfV1mhY2IV6Nsf61KFk.jpg')
    }
]

const subHeaderContent = [
    { label: 'sell online', path: '/' },
    { label: 'order for yourself', path: '/self' }
];

const sliderData = [
    {
        label: '',
        image: require('../Assets/Images/hero-image.webp')
    },
    {
        label: '',
        image: require('../Assets/Images/hero-image.webp')
    }
]

const ProductSliderList = [
    {
        id: 1,
        name: "Urban Groove: City Lights and Cool Vibes",
        shortName: 'Urban Groove',
        link: '/self',
        thumbnail: require('../Assets/Images/654de688e68ada3dc707f26d.png'),
        hoverImg: require('../Assets/Images/654b9e92bd3091aa8a08a5b9.png'),
        price: 5499,
        mrp: 7499,
        startPrice: 5499,
        endPrice: 7499,
        variation:
        {
            name: "color",
            data: ['red', 'blue', 'yellow', 'black', 'grey']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        id: 2,
        name: "Cosmic Threads: The Universe Wears This",
        shortName: 'Cosmic Threads',
        link: '/self',
        thumbnail: require('../Assets/Images/65439a6b875d6752d6056b52.png'),
        hoverImg: require('../Assets/Images/65439a7db56afc593101e296.png'),
        price: 4499,
        mrp: 6499,
        startPrice: 4499,
        endPrice: 6499,
        variation:
        {
            name: "color",
            data: ['blue', 'black', 'white', 'black',]
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        id: 3,
        name: "Rebel Chic: Fashion with an Attitude",
        shortName: 'Rebel Chic',
        link: '/self',
        thumbnail: require('../Assets/Images/654e2446c4fbc0eeb2052d24.png'),
        hoverImg: require('../Assets/Images/654e2447bdde291499078577.png'),
        price: 7999,
        mrp: 9999,
        startPrice: 7999,
        endPrice: 9999,
        variation:
        {
            name: "color",
            data: ['red', 'black', 'blue', 'yellow', 'white',]
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        id: 4,
        name: "Chill Vibes: Relaxed and Ready to Roll",
        shortName: 'Chill Vibes',
        link: '/self',
        thumbnail: require('../Assets/Images/6596da61addbfbabd207c438.png'),
        hoverImg: require('../Assets/Images/6596da645b637814630fa225.png'),
        price: 4550,
        mrp: 5050,
        startPrice: 4550,
        endPrice: 5050,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'yellow']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        id: 5,
        name: "Mystic Harmony: Spiritual and Stylish",
        shortName: 'Mystic Harmony',
        link: '/self',
        thumbnail: require('../Assets/Images/6373c097a8189010e801fc83.png'),
        hoverImg: require('../Assets/Images/6358d706f5f53c73470fcf6c.png'),
        price: 7450,
        mrp: 8550,
        startPrice: 7450,
        endPrice: 8550,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'blue']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        id: 6,
        name: "Sunset Drifter: Riding the Horizon Waves",
        shortName: 'Sunset Drifter',
        link: '/self',
        thumbnail: require('../Assets/Images/6295ce24da5a4ace6e067552.png'),
        hoverImg: require('../Assets/Images/634693f90175c940820995a3.png'),
        price: 2150,
        mrp: 3450,
        startPrice: 2150,
        endPrice: 3450,
        variation:
        {
            name: "color",
            data: ['red', 'cream', 'grey']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
]

const ProductSliderListTwo = [
    {
        name: "Urban Groove: City Lights and Cool Vibes",
        thumbnail: require('../Assets/Images/65df03af6e08ed69540c7afc.png'),
        hoverImg: require('../Assets/Images/65df03af7315699f5c061d14.png'),
        price: 5499,
        mrp: 7499,
        startPrice: 5499,
        endPrice: 7499,
        variation:
        {
            name: "color",
            data: ['red', 'blue', 'yellow', 'black', 'grey']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        name: "Cosmic Threads: The Universe Wears This",
        thumbnail: require('../Assets/Images/651e95425ac111facc013a50.png'),
        hoverImg: require('../Assets/Images/651e954f5ac111facc013a51.png'),
        price: 4499,
        mrp: 6499,
        startPrice: 4499,
        endPrice: 6499,
        variation:
        {
            name: "color",
            data: ['blue', 'black', 'white', 'black',]
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        name: "Rebel Chic: Fashion with an Attitude",
        thumbnail: require('../Assets/Images/6290c12c348d9769b50ac8fd.png'),
        hoverImg: require('../Assets/Images/635948ff1393bac9e109eeab.png'),
        price: 7999,
        mrp: 9999,
        startPrice: 7999,
        endPrice: 9999,
        variation:
        {
            name: "color",
            data: ['red', 'black', 'blue', 'yellow', 'white',]
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        name: "Chill Vibes: Relaxed and Ready to Roll",
        thumbnail: require('../Assets/Images/6596da61addbfbabd207c438.png'),
        hoverImg: require('../Assets/Images/6596da645b637814630fa225.png'),
        price: 4550,
        mrp: 5050,
        startPrice: 4550,
        endPrice: 5050,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'yellow']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        name: "Mystic Harmony: Spiritual and Stylish",
        thumbnail: require('../Assets/Images/6373c097a8189010e801fc83.png'),
        hoverImg: require('../Assets/Images/6358d706f5f53c73470fcf6c.png'),
        price: 7450,
        mrp: 8550,
        startPrice: 7450,
        endPrice: 8550,
        variation:
        {
            name: "color",
            data: ['grey', 'black', 'blue']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
    {
        name: "Sunset Drifter: Riding the Horizon Waves",
        thumbnail: require('../Assets/Images/6295ce24da5a4ace6e067552.png'),
        hoverImg: require('../Assets/Images/634693f90175c940820995a3.png'),
        price: 2150,
        mrp: 3450,
        startPrice: 2150,
        endPrice: 3450,
        variation:
        {
            name: "color",
            data: ['red', 'cream', 'grey']
        },
        newVariation: [
            {
                label: 'color',
                data: ['red', 'blue', 'yellow', 'black', 'grey']
            },
            {
                label: 'sizes',
                data: ['S', 'M', 'L', 'XL', 'XXL', '3XL', '5XL', '6XXL']
            },
        ]
    },
]

const testimonialsList = [
    {
        image: require('../Assets/Images/trusted-3-desk@2x.jpg'),
        name: 'Sarah Johnson',
        role: 'Graphic Designer',
        review: 'Absolutely love the quality of the t-shirts and the ease of designing! The print is vibrant and lasts wash after wash.'
    },
    {
        image: require('../Assets/Images/testi-avatar-9.jpg'),
        name: 'David Martinez',
        role: 'Marketing Manager',
        review: 'The customization options are fantastic! I created unique team shirts for our company event and everyone was thrilled.'
    },
    {
        image: require('../Assets/Images/testi-avatar-5.jpg'),
        name: 'Emily Davis',
        role: 'Freelance Artist',
        review: 'The platform is so user-friendly. I designed a few shirts for my art portfolio and they turned out amazing. Highly recommend!'
    },
    {
        image: require('../Assets/Images/testi-avatar-4.jpg'),
        name: 'Michael Brown',
        role: 'High School Teacher',
        review: "Designed shirts for our school's charity event. The kids loved them and the quality was top-notch. Will definitely order again."
    }
]

const allProductData = [
    {
        label: 'New in',
        data: [
            {
                name: 'Urban Groove: City Lights and Cool Vibes',
                description: 'Experience the pulse of the city with designs that capture the essence of urban living, combining modern aesthetics with a relaxed feel.',
                startingPrice: 5499,
                endingPrice: 7499,
                colors: ['red', 'blue', 'yellow'],
                thumbnail: require('../Assets/Images/download (1).jpeg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Cosmic Threads: The Universe Wears This',
                description: 'Dive into the cosmos with t-shirts inspired by galaxies, stars, and the mysteries of the universe. Perfect for those who love to dream big.',
                startingPrice: 4499,
                endingPrice: 6499,
                colors: ['blue', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/download (2).jpeg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Rebel Chic: Fashion with an Attitude',
                description: 'Stand out with edgy designs that scream individuality and confidence. This collection is for the bold and fearless.',
                startingPrice: 7999,
                endingPrice: 8799,
                colors: ['red', 'black', 'blue'],
                thumbnail: require('../Assets/Images/download.jpeg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Chill Vibes: Relaxed and Ready to Roll',
                description: 'Embrace the laid-back lifestyle with comfortable, easy-going t-shirts that are perfect for unwinding and taking it easy.',
                startingPrice: 4550,
                endingPrice: 5050,
                colors: ['grey', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/download (1).jpeg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Mystic Harmony: Spiritual and Stylish',
                description: 'Find your inner peace with designs that blend spiritual symbols and harmonious patterns, perfect for those on a journey of self-discovery.',
                startingPrice: 7450,
                endingPrice: 8550,
                colors: ['grey', 'black', 'blue'],
                thumbnail: require('../Assets/Images/download (2).jpeg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Sunset Drifter: Riding the Horizon Waves',
                description: 'Capture the beauty of a setting sun with t-shirts that feature warm, vibrant colors and serene landscapes, ideal for dreamers and wanderers.',
                startingPrice: 2150,
                endingPrice: 3450,
                colors: ['red', 'cream', 'grey'],
                thumbnail: require('../Assets/Images/download.jpeg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Mystic Harmony: Spiritual and Stylish',
                description: 'Find your inner peace with designs that blend spiritual symbols and harmonious patterns, perfect for those on a journey of self-discovery.',
                startingPrice: 7450,
                endingPrice: 8550,
                colors: ['grey', 'black', 'blue'],
                thumbnail: require('../Assets/Images/download (2).jpeg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Sunset Drifter: Riding the Horizon Waves',
                description: 'Capture the beauty of a setting sun with t-shirts that feature warm, vibrant colors and serene landscapes, ideal for dreamers and wanderers.',
                startingPrice: 2150,
                endingPrice: 3450,
                colors: ['red', 'cream', 'grey'],
                thumbnail: require('../Assets/Images/download.jpeg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
        ]
    },
    {
        label: 'BestSeller',
        data: [
            {
                name: 'Urban Groove: City Lights and Cool Vibes',
                description: 'Experience the pulse of the city with designs that capture the essence of urban living, combining modern aesthetics with a relaxed feel.',
                startingPrice: 5499,
                endingPrice: 7499,
                colors: ['red', 'blue', 'yellow'],
                thumbnail: require('../Assets/Images/trusted-1.jpg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Cosmic Threads: The Universe Wears This',
                description: 'Dive into the cosmos with t-shirts inspired by galaxies, stars, and the mysteries of the universe. Perfect for those who love to dream big.',
                startingPrice: 4499,
                endingPrice: 6499,
                colors: ['blue', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/trusted-2.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Rebel Chic: Fashion with an Attitude',
                description: 'Stand out with edgy designs that scream individuality and confidence. This collection is for the bold and fearless.',
                startingPrice: 7999,
                endingPrice: 8799,
                colors: ['red', 'black', 'blue'],
                thumbnail: require('../Assets/Images/trusted-3.jpg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Chill Vibes: Relaxed and Ready to Roll',
                description: 'Embrace the laid-back lifestyle with comfortable, easy-going t-shirts that are perfect for unwinding and taking it easy.',
                startingPrice: 4550,
                endingPrice: 5050,
                colors: ['grey', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/testimonial-bottom.jpg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Mystic Harmony: Spiritual and Stylish',
                description: 'Find your inner peace with designs that blend spiritual symbols and harmonious patterns, perfect for those on a journey of self-discovery.',
                startingPrice: 7450,
                endingPrice: 8550,
                colors: ['grey', 'black', 'blue'],
                thumbnail: require('../Assets/Images/support@2x.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Sunset Drifter: Riding the Horizon Waves',
                description: 'Capture the beauty of a setting sun with t-shirts that feature warm, vibrant colors and serene landscapes, ideal for dreamers and wanderers.',
                startingPrice: 2150,
                endingPrice: 3450,
                colors: ['red', 'cream', 'grey'],
                thumbnail: require('../Assets/Images/supply-hero@2x.jpg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Urban Groove: City Lights and Cool Vibes',
                description: 'Experience the pulse of the city with designs that capture the essence of urban living, combining modern aesthetics with a relaxed feel.',
                startingPrice: 5499,
                endingPrice: 7499,
                colors: ['red', 'blue', 'yellow'],
                thumbnail: require('../Assets/Images/trusted-1.jpg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Cosmic Threads: The Universe Wears This',
                description: 'Dive into the cosmos with t-shirts inspired by galaxies, stars, and the mysteries of the universe. Perfect for those who love to dream big.',
                startingPrice: 4499,
                endingPrice: 6499,
                colors: ['blue', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/trusted-2.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
        ]
    },
    {
        label: 'Featured',
        data: [
            {
                name: 'Urban Groove: City Lights and Cool Vibes',
                description: 'Experience the pulse of the city with designs that capture the essence of urban living, combining modern aesthetics with a relaxed feel.',
                startingPrice: 5499,
                endingPrice: 7499,
                colors: ['red', 'blue', 'yellow'],
                thumbnail: require('../Assets/Images/single-product-1-1.jpg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Cosmic Threads: The Universe Wears This',
                description: 'Dive into the cosmos with t-shirts inspired by galaxies, stars, and the mysteries of the universe. Perfect for those who love to dream big.',
                startingPrice: 4499,
                endingPrice: 6499,
                colors: ['blue', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/single-product-2-1.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Rebel Chic: Fashion with an Attitude',
                description: 'Stand out with edgy designs that scream individuality and confidence. This collection is for the bold and fearless.',
                startingPrice: 7999,
                endingPrice: 8799,
                colors: ['red', 'black', 'blue'],
                thumbnail: require('../Assets/Images/single-product-3-1.jpg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Chill Vibes: Relaxed and Ready to Roll',
                description: 'Embrace the laid-back lifestyle with comfortable, easy-going t-shirts that are perfect for unwinding and taking it easy.',
                startingPrice: 4550,
                endingPrice: 5050,
                colors: ['grey', 'black', 'yellow'],
                thumbnail: require('../Assets/Images/single-product-4-1.jpg'),
                images: [
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                ]
            },
            {
                name: 'Mystic Harmony: Spiritual and Stylish',
                description: 'Find your inner peace with designs that blend spiritual symbols and harmonious patterns, perfect for those on a journey of self-discovery.',
                startingPrice: 7450,
                endingPrice: 8550,
                colors: ['grey', 'black', 'blue'],
                thumbnail: require('../Assets/Images/single-product-6-1.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Sunset Drifter: Riding the Horizon Waves',
                description: 'Capture the beauty of a setting sun with t-shirts that feature warm, vibrant colors and serene landscapes, ideal for dreamers and wanderers.',
                startingPrice: 2150,
                endingPrice: 3450,
                colors: ['red', 'cream', 'grey'],
                thumbnail: require('../Assets/Images/single-product-10-1.jpg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
            {
                name: 'Mystic Harmony: Spiritual and Stylish',
                description: 'Find your inner peace with designs that blend spiritual symbols and harmonious patterns, perfect for those on a journey of self-discovery.',
                startingPrice: 7450,
                endingPrice: 8550,
                colors: ['grey', 'black', 'blue'],
                thumbnail: require('../Assets/Images/single-product-91.jpg'),
                images: [
                    require('../Assets/Images/download (2).jpeg'),
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                ]
            },
            {
                name: 'Sunset Drifter: Riding the Horizon Waves',
                description: 'Capture the beauty of a setting sun with t-shirts that feature warm, vibrant colors and serene landscapes, ideal for dreamers and wanderers.',
                startingPrice: 2150,
                endingPrice: 3450,
                colors: ['red', 'cream', 'grey'],
                thumbnail: require('../Assets/Images/single-product-111.jpg'),
                images: [
                    require('../Assets/Images/download.jpeg'),
                    require('../Assets/Images/download (1).jpeg'),
                    require('../Assets/Images/download (2).jpeg'),
                ]
            },
        ]
    },
]

const setColors = [
    { name: 'red', code: '#FF0000' },
    { name: 'green', code: '#00FF00' },
    { name: 'blue', code: '#0000FF' },
    { name: 'yellow', code: '#FFFF00' },
    { name: 'cyan', code: '#00FFFF' },
    { name: 'magenta', code: '#FF00FF' },
    { name: 'black', code: '#000000' },
    { name: 'white', code: '#FFFFFF' },
    { name: 'gray', code: '#808080' },
    { name: 'orange', code: '#FFA500' },
    { name: 'purple', code: '#800080' },
    { name: 'brown', code: '#A52A2A' },
    { name: 'pink', code: '#FFC0CB' },
    { name: 'lime', code: '#00FF00' },
    { name: 'navy', code: '#000080' },
    { name: 'olive', code: '#808000' },
    { name: 'maroon', code: '#800000' },
    { name: 'teal', code: '#008080' },
    { name: 'silver', code: '#C0C0C0' },
];

const productBarData = [
    {
        name: 'branding',
        data: [
            { label: 'Gift message', type: 'checkbox' },
            { label: 'inserts', type: 'checkbox' },
            { label: 'Neck labels', type: 'checkbox' },
        ],
    },
    {
        name: 'printArea',
        data: [
            { label: 'Above pocket', type: 'checkbox' },
            { label: 'All over print', type: 'checkbox' },
            { label: 'Back side', type: 'checkbox' },
            { label: 'Front side', type: 'checkbox' },
            { label: 'Neck labels inner', type: 'checkbox' },
            { label: 'Neck labels outer', type: 'checkbox' },
            { label: 'Pockets', type: 'checkbox' },
            { label: 'Sleeve left', type: 'checkbox' },
            { label: 'Sleeve right', type: 'checkbox' },
        ],
    },
    {
        name: 'providers',
        data: [
            { label: 'Awkward Styles', type: 'checkbox' },
            { label: 'ArtsAdd', type: 'checkbox' },
            { label: 'Duplims', type: 'checkbox' },
            { label: 'Fulfill Engines', type: 'checkbox' },
            { label: 'FYBY', type: 'checkbox' },
            { label: 'HFT71', type: 'checkbox' },
            { label: 'Ideju Druka', type: 'checkbox' },
            { label: 'Ink Blot', type: 'checkbox' },
            { label: 'JAMS Designs', type: 'checkbox' },
            { label: 'Custom-Prints', type: 'checkbox' },
            { label: 'Monster Digital', type: 'checkbox' },
            { label: 'MWW On Demand', type: 'checkbox' },
            { label: 'OTP designers', type: 'checkbox' },
            { label: 'PRIMA Printing', type: 'checkbox' },
        ],
    },
    {
        name: 'Sizes',
        data: [
            { label: '2XS', type: 'checkbox' },
            { label: 'XS', type: 'checkbox' },
            { label: 'S', type: 'checkbox' },
            { label: 'M', type: 'checkbox' },
            { label: 'L', type: 'checkbox' },
            { label: 'XL', type: 'checkbox' },
            { label: '2XL', type: 'checkbox' },
            { label: '3XL', type: 'checkbox' },
            { label: '4XL', type: 'checkbox' },
            { label: '5XL', type: 'checkbox' },
            { label: '6XL', type: 'checkbox' },
        ],
    },
    {
        name: 'price',
        data: [
            { start: 0, end: 499, label: 'Min - ₹499', type: 'radio' },
            { start: 500, end: 999, label: '₹500 - ₹999', type: 'radio' },
            { start: 1000, end: 1499, label: '₹1000 - ₹1499', type: 'radio' },
            { start: 1500, end: 1999, label: '₹1500 - ₹1999', type: 'radio' },
            { start: 2000, end: 50000, label: '₹2000 - Max', type: 'radio' },
        ],
    },
    {
        name: 'thickness',
        data: [
            { label: 'Extra light fabric', type: 'checkbox' },
            { label: 'Heavy fabric', type: 'checkbox' },
            { label: 'Light fabric', type: 'checkbox' },
            { label: 'Medium fabric', type: 'checkbox' },
            { label: 'Medium heavy fabric', type: 'checkbox' },
        ],
    },
    {
        name: 'discount',
        data: [
            { label: 'Bulk discount eligible', type: 'checkbox' },
        ],
    }
];

const customFilter = [
    {
        title: 'type',
        data: [
            { value: 'orderInvoice', label: 'Order invoice' },
            { value: 'creditNote', label: 'Credit note' },
            { value: 'subscriptionInvoice', label: 'Subscription invoice' }
        ],
    },
    {
        title: 'state',
        data: [
            { value: 'gujarat', label: 'Gujarat' },
            { value: 'maharashtra', label: 'Maharashtra' },
            { value: 'karnataka', label: 'Karnataka' },
            { value: 'tamil_nadu', label: 'Tamil Nadu' },
            { value: 'kerala', label: 'Kerala' },
            { value: 'rajasthan', label: 'Rajasthan' },
            { value: 'uttar_pradesh', label: 'Uttar Pradesh' },
            { value: 'punjab', label: 'Punjab' },
            { value: 'west_bengal', label: 'West Bengal' },
            { value: 'andhra_pradesh', label: 'Andhra Pradesh' }
        ],
    },
    {
        title: 'Store',
        data: [
            { value: 'newcreated', label: 'New Created' },
            { value: 'myShirtFactory', label: 'My Shirt Factory' }
        ]
    }
]

const orderList = [
    {
        orderNo: 123456,
        orderType: 'manual',
        orderDate: 'Jul 10, 2024 at 06:10 PM',
        userName: 'user',
        userMobile: '1234567890',
        userEmail: 'user@gmail.com',
        productionCost: '600',
        shippingCost: '149',
        totalCost: '749',
        productName: 'Lame Black Full-sleeve t-shirt',
        productImg: require('../Assets/Images/download (1).jpeg'),
        productSize: 'M',
        productColor: 'Black',
        productSKU: 'SKU 123456',
        tracking: { value: 'pending', label: 'pending', status: 1 },
        orderStatus: { value: 'on hold', label: 'on hold', status: 1 },
        productionStatus: { value: 'pending', label: 'pending', status: 1 }
    },
    {
        orderNo: 123457,
        orderType: 'sample',
        orderDate: 'Jul 08, 2024 at 11:10 AM',
        userName: 'sample user',
        userMobile: '9185456564',
        userEmail: 'sampleuser@gmail.com',
        productionCost: '744',
        shippingCost: '241',
        totalCost: '985',
        productName: 'Lame Black Full-sleeve t-shirt',
        productImg: require('../Assets/Images/download (2).jpeg'),
        productSize: 'M',
        productColor: 'Black',
        productSKU: 'SKU 123456',
        tracking: { value: 'pending', label: 'pending', status: 1 },
        orderStatus: { value: 'on hold', label: 'on hold', status: 1 },
        productionStatus: { value: 'pending', label: 'pending', status: 1 }
    }
]

const subscriptionProduct = [
    {
        thumbnail: require('../Assets/Images/1.jpg'),
        title: 'AOP Tote Bag',
        regularPrice: 699,
        pPrice: 559,
    },
    {
        thumbnail: require('../Assets/Images/2.jpg'),
        title: 'Women’s The Boyfriend Tee',
        regularPrice: 800,
        pPrice: 640,
    },
    {
        thumbnail: require('../Assets/Images/3.jpg'),
        title: 'Mugs',
        regularPrice: 620,
        pPrice: 496,
    },
    {
        thumbnail: require('../Assets/Images/4.jpg'),
        title: 'Shower Curtains',
        regularPrice: 2800,
        pPrice: 2240,
    },
]

const blogData = [
    {
        name: 'Business Tips & Ideas',
        data: [
            {
                thumbnail: require('../Assets/Images/2024-Color-Trends-The-Ultimate-Palette-for-Creative-Inspiration-Teaser.jpg'),
                readTime: '5 minute read',
                title: 'Color Trends 2024: The Ultimate Palette for Creative Inspiration',
                description: "Explore 2024 color trends to boost your brand's appeal and marketing. Dive into the latest hues to enhance visual storytelling and consumer connection.",
                profileThumb: require('../Assets/Images/Zane-Hewitt.jpg'),
                profileName: 'Zane Bratuskina',
                blogDate: 'Jul 11, 2024'
            },
            {
                thumbnail: require('../Assets/Images/Top-15-Back-To-School-Bestsellers-Free-Designs.jpg'),
                readTime: '7 minute read',
                title: 'Don’t Miss: Top 15 Back-To-School Bestsellers + Free Designs',
                description: "Launch a successful back-to-school campaign with the top 15 bestsellers and inspiring designs – free by Printfuse.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 9, 2024'
            },
            {
                thumbnail: require('../Assets/Images/How-to-Make-Stickers-to-Sell-With-Print-on-Demand.jpg'),
                readTime: '10 minute read',
                title: 'How to Make Stickers to Sell With Print on Demand',
                description: "Discover how to make stickers to sell with Print on Demand (POD) and enter the sticker market one masterpiece at a time.",
                profileThumb: require('../Assets/Images/Vita-Jaunberzina-Stevens.jpg'),
                profileName: 'Vita Stevens',
                blogDate: 'Jul 8, 2024'
            },
            {
                thumbnail: require('../Assets/Images/2024-Color-Trends-The-Ultimate-Palette-for-Creative-Inspiration-Teaser.jpg'),
                readTime: '5 minute read',
                title: 'Color Trends 2024: The Ultimate Palette for Creative Inspiration',
                description: "Explore 2024 color trends to boost your brand's appeal and marketing. Dive into the latest hues to enhance visual storytelling and consumer connection.",
                profileThumb: require('../Assets/Images/Zane-Hewitt.jpg'),
                profileName: 'Zane Bratuskina',
                blogDate: 'Jul 11, 2024'
            },
            {
                thumbnail: require('../Assets/Images/Top-15-Back-To-School-Bestsellers-Free-Designs.jpg'),
                readTime: '7 minute read',
                title: 'Don’t Miss: Top 15 Back-To-School Bestsellers + Free Designs',
                description: "Launch a successful back-to-school campaign with the top 15 bestsellers and inspiring designs – free by Printfuse.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 9, 2024'
            },
            {
                thumbnail: require('../Assets/Images/How-to-Make-Stickers-to-Sell-With-Print-on-Demand.jpg'),
                readTime: '10 minute read',
                title: 'How to Make Stickers to Sell With Print on Demand',
                description: "Discover how to make stickers to sell with Print on Demand (POD) and enter the sticker market one masterpiece at a time.",
                profileThumb: require('../Assets/Images/Vita-Jaunberzina-Stevens.jpg'),
                profileName: 'Vita Stevens',
                blogDate: 'Jul 8, 2024'
            },
        ]
    },
    {
        name: 'eCommerce 101',
        data: [
            {
                thumbnail: require('../Assets/Images/How-to-Make-Stickers-to-Sell-With-Print-on-Demand.jpg'),
                readTime: '10 minute read',
                title: 'How to Make Stickers to Sell With Print on Demand',
                description: "Discover how to make stickers to sell with Print on Demand (POD) and enter the sticker market one masterpiece at a time.",
                profileThumb: require('../Assets/Images/Vita-Jaunberzina-Stevens.jpg'),
                profileName: 'Vita Stevens',
                blogDate: 'Jul 8, 2024'
            },
            {
                thumbnail: require('../Assets/Images/15-Back-To-School-Marketing-Ideas-for-a-Successful-2023-Seasonal-Campaign.jpg'),
                readTime: '10 minute read',
                title: '15 Back-To-School Marketing Ideas for a Successful 2024 Seasonal Campaign',
                description: "Find useful stats from previous years and 15 ideas for your back-to-school marketing campaign.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 4, 2024'
            },
            {
                thumbnail: require('../Assets/Images/How-to-Start-a-Notebook-Business-With-Print-on-Demand.jpg'),
                readTime: '9 minute read',
                title: 'How to Start a Notebook Business With Print on Demand',
                description: "Welcome to our step-by-step guide to starting a notebook business with Print on Demand in 2024.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jun 28, 2024'
            },
            {
                thumbnail: require('../Assets/Images/How-to-Make-Stickers-to-Sell-With-Print-on-Demand.jpg'),
                readTime: '10 minute read',
                title: 'How to Make Stickers to Sell With Print on Demand',
                description: "Discover how to make stickers to sell with Print on Demand (POD) and enter the sticker market one masterpiece at a time.",
                profileThumb: require('../Assets/Images/Vita-Jaunberzina-Stevens.jpg'),
                profileName: 'Vita Stevens',
                blogDate: 'Jul 8, 2024'
            },
            {
                thumbnail: require('../Assets/Images/15-Back-To-School-Marketing-Ideas-for-a-Successful-2023-Seasonal-Campaign.jpg'),
                readTime: '10 minute read',
                title: '15 Back-To-School Marketing Ideas for a Successful 2024 Seasonal Campaign',
                description: "Find useful stats from previous years and 15 ideas for your back-to-school marketing campaign.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 4, 2024'
            },
            {
                thumbnail: require('../Assets/Images/How-to-Start-a-Notebook-Business-With-Print-on-Demand.jpg'),
                readTime: '9 minute read',
                title: 'How to Start a Notebook Business With Print on Demand',
                description: "Welcome to our step-by-step guide to starting a notebook business with Print on Demand in 2024.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jun 28, 2024'
            }
        ]
    },
    {
        name: 'Product Inspiration',
        data: [
            {
                thumbnail: require('../Assets/Images/Top-15-Back-To-School-Bestsellers-Free-Designs.jpg'),
                readTime: '7 minute read',
                title: 'Don’t Miss: Top 15 Back-To-School Bestsellers + Free Designs',
                description: "Launch a successful back-to-school campaign with the top 15 bestsellers and inspiring designs – free by Printfuse.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 9, 2024'
            },
            {
                thumbnail: require('../Assets/Images/32-Company-Swag-Ideas-Best-Branded-Merch-for-2024.jpg'),
                readTime: '15 minute read',
                title: '32 Company Swag Ideas: Best Branded Merch for 2024',
                description: "Stay ahead of the game and increase brand awareness with the 32 best company swag ideas for 2024.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jul 3, 2024'
            },
            {
                thumbnail: require('../Assets/Images/The-Top-20-Personalized-School-Supplies-for-2023.jpg'),
                readTime: '8 minute read',
                title: 'The Top 20 Personalized School Supplies for 2024',
                description: "A list of the top 20 personalized school supplies that are sure to get an A+ from the toughest crowd – your and your customers’ little ones.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jul 20, 2024'
            },
            {
                thumbnail: require('../Assets/Images/Top-15-Back-To-School-Bestsellers-Free-Designs.jpg'),
                readTime: '7 minute read',
                title: 'Don’t Miss: Top 15 Back-To-School Bestsellers + Free Designs',
                description: "Launch a successful back-to-school campaign with the top 15 bestsellers and inspiring designs – free by Printfuse.",
                profileThumb: require('../Assets/Images/Baiba-Posele-Profile.jpg'),
                profileName: 'Baiba Blain',
                blogDate: 'Jul 9, 2024'
            },
            {
                thumbnail: require('../Assets/Images/32-Company-Swag-Ideas-Best-Branded-Merch-for-2024.jpg'),
                readTime: '15 minute read',
                title: '32 Company Swag Ideas: Best Branded Merch for 2024',
                description: "Stay ahead of the game and increase brand awareness with the 32 best company swag ideas for 2024.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jul 3, 2024'
            },
            {
                thumbnail: require('../Assets/Images/The-Top-20-Personalized-School-Supplies-for-2023.jpg'),
                readTime: '8 minute read',
                title: 'The Top 20 Personalized School Supplies for 2024',
                description: "A list of the top 20 personalized school supplies that are sure to get an A+ from the toughest crowd – your and your customers’ little ones.",
                profileThumb: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
                profileName: 'Leon Walker',
                blogDate: 'Jul 20, 2024'
            }
        ]
    }
]

const articleData = [
    {
        readTime: '10 minute read',
        title: 'Best Eco-Friendly Gifts for 2024',
        thumbnail: require('../Assets/Images/Janis-Lazda.jpg'),
        name: 'Janis Lazda',
        postDate: 'Oct 2, 2023'
    },
    {
        readTime: '6 minute read',
        title: 'Olympic Gold Medalist, Environmental Activist ...',
        thumbnail: require('../Assets/Images/Anita-Njoki.jpg'),
        name: 'Anita Njoki',
        postDate: 'Jun 21, 2022'
    },
    {
        readTime: '10 minute read',
        title: 'Create Your First Design: Graphic Design Tips for POD',
        thumbnail: require('../Assets/Images/Leon-Walker-Profile-1.jpg'),
        name: 'Leon Walker',
        postDate: 'Jan 27, 2022'
    }
]

const instaUserData = [
    {
        thumbnail: require('../Assets/Images/mschibious.webp'),
        name: 'samplename',
        hashTags: ['chibiart', 'stickershop', 'cuteart']
    },
    {
        thumbnail: require('../Assets/Images/yourpassport.webp'),
        name: 'artlover123',
        hashTags: ['digitalart', 'illustration', 'artsy']
    },
    {
        thumbnail: require('../Assets/Images/thelittleredballoonshop.webp'),
        name: 'creative_mind',
        hashTags: ['design', 'creative', 'artwork']
    },
    {
        thumbnail: require('../Assets/Images/the-crafty-ginger.webp'),
        name: 'stickerqueen',
        hashTags: ['stickers', 'vinyl', 'decal']
    },
    {
        thumbnail: require('../Assets/Images/tallrealitees.webp'),
        name: 'colorfuldreams',
        hashTags: ['rainbow', 'colorful', 'painting']
    },
    {
        thumbnail: require('../Assets/Images/peaceloverclothing.webp'),
        name: 'artsy_vibes',
        hashTags: ['artlife', 'gallery', 'modernart']
    },
    {
        thumbnail: require('../Assets/Images/momonthebeats.webp'),
        name: 'inked_artist',
        hashTags: ['tattoo', 'ink', 'bodyart']
    },
    {
        thumbnail: require('../Assets/Images/katarinaarriagamusic.webp'),
        name: 'doodle_master',
        hashTags: ['doodle', 'sketch', 'drawing']
    },
    {
        thumbnail: require('../Assets/Images/janis-lazovskis.webp'),
        name: 'pixel_perfect',
        hashTags: ['pixelart', 'retro', '8bit']
    },
    {
        thumbnail: require('../Assets/Images/itsmaryamsalam.webp'),
        name: 'artistic_flair',
        hashTags: ['flair', 'artistic', 'creativity']
    },
    {
        thumbnail: require('../Assets/Images/hustleandloveco.webp'),
        name: 'watercolor_world',
        hashTags: ['watercolor', 'painting', 'artist']
    },
    {
        thumbnail: require('../Assets/Images/giuliogroebert.webp'),
        name: 'urban_artist',
        hashTags: ['graffiti', 'urbanart', 'streetart']
    },
    {
        thumbnail: require('../Assets/Images/eldiariode_marlen.webp'),
        name: 'crafty_hand',
        hashTags: ['handmade', 'crafts', 'diy']
    },
    {
        thumbnail: require('../Assets/Images/dlysi.webp'),
        name: 'charcoal_creations',
        hashTags: ['charcoal', 'sketching', 'drawing']
    },
    {
        thumbnail: require('../Assets/Images/birdskeptnorth.webp'),
        name: 'pastel_dreams',
        hashTags: ['pastel', 'softcolors', 'art']
    },
    {
        thumbnail: require('../Assets/Images/becomfynyc.webp'),
        name: 'minimalist_art',
        hashTags: ['minimalist', 'simple', 'design']
    }
];

const userSideBar = [
    {
        icon: 'bi-house-door',
        label: 'dashboard',
        link: '/user'
    },
    {
        icon: 'bi-person-circle',
        label: 'Account Details',
        link: '/user/account'
    },
    {
        icon: 'bi-geo-alt',
        label: 'Addresses',
        link: '/user/address'
    },
    {
        icon: 'bi-wallet2',
        label: 'My Orders',
        link: '/user/orders'
    },
    {
        icon: 'bi-bag',
        label: 'My cart',
        link: '/user/cart'
    },
    {
        icon: 'bi-key',
        label: 'Change Password',
        link: '/user/password'
    },
]

const canvasSidebarData = [
    {
        title: 'Product',
        icon: 'bi-tags'
    },
    {
        title: 'layers',
        icon: 'bi-layers-half'
    },
    {
        title: 'Uploads',
        icon: 'bi-upload'
    },
    {
        title: 'Text',
        icon: 'bi-fonts'
    },
    {
        title: 'Clipart',
        icon: 'bi-emoji-smile'
    },
    {
        title: 'Quick Designs',
        icon: 'bi-magic'
    },
    {
        title: 'Premium',
        icon: 'bi-shield-shaded'
    },
    {
        title: 'Fill',
        icon: 'bi-paint-bucket'
    },
    {
        title: 'Help',
        icon: 'bi-question-square-fill'
    },
]

const productSingleData = {
    id: 1,
    name: 'Urban Groove: City Lights and Cool Vibes',
    skuCode: 'Gildan5000',
    thumbnail: require('../Assets/Images/654e2446c4fbc0eeb2052d24 (2).png'),
    images: [
        require('../Assets/Images/654e2446c4fbc0eeb2052d24 (2).png'),
        require('../Assets/Images/654e2447bdde291499078577 (1).png'),
        require('../Assets/Images/654b9e92bd3091aa8a08a5b9 (1).png'),
        require('../Assets/Images/654de688e68ada3dc707f26d (1).png'),
        require('../Assets/Images/257f60bfec8aee8d747a980f3c8a220c_l.png'),
        require('../Assets/Images/75cabe24ecebd907bff91df515cdf9e4_l.png'),
    ],
    providers: [
        {
            id: 1,
            name: 'Marco-Fine Arts',
            location: 'Madhya Pradesh',
            price: 5499,
            shipping: [
                {
                    label: 'economy',
                    value: 125,
                    time: '4-5 days'
                },
                {
                    label: 'standard',
                    value: 200,
                    time: '2-3 days'
                },
                {
                    label: 'express',
                    value: 400,
                    time: '1-2 days'
                }
            ],
            productionTime: 1.2,
            printArea: [
                'Back Side',
                'Front Side',
                'Neck label inner',
            ],
            variant: {
                color: [
                    { label: 'Red', code: '#FF0000', id: 1 },
                    { label: 'Green', code: '#008000', id: 2 },
                    { label: 'Blue', code: '#0000FF', id: 3 },
                    { label: 'Yellow', code: '#FFFF00', id: 4 },
                    { label: 'Orange', code: '#FFA500', id: 5 },
                    { label: 'Purple', code: '#800080', id: 6 },
                    { label: 'Pink', code: '#FFC0CB', id: 7 },
                    { label: 'Brown', code: '#A52A2A', id: 8 },
                    { label: 'Gray', code: '#808080', id: 9 },
                    { label: 'Black', code: '#000000', id: 10 },
                    { label: 'White', code: '#FFFFFF', id: 11 },
                    { label: 'Cyan', code: '#00FFFF', id: 12 },
                    { label: 'Magenta', code: '#FF00FF', id: 13 },
                    { label: 'Lime', code: '#00FF00', id: 14 },
                    { label: 'Maroon', code: '#800000', id: 15 },
                    { label: 'Navy', code: '#000080', id: 16 },
                    { label: 'Olive', code: '#808000', id: 17 },
                    { label: 'Teal', code: '#008080', id: 18 },
                    { label: 'Silver', code: '#C0C0C0', id: 19 },
                    { label: 'Gold', code: '#FFD700', id: 20 }
                ],
                sizes: [
                    { label: 'Small', code: 'S', id: 1 },
                    { label: 'Medium', code: 'M', id: 2 },
                    { label: 'Large', code: 'L', id: 3 },
                    { label: 'X-Large', code: 'X', id: 4 },
                    { label: 'XX-Large', code: 'XL', id: 5 }
                ]
            },
            rating: [
                {
                    label: 'quality',
                    value: 4.7
                },
                {
                    label: 'production',
                    value: 4.5
                },
                {
                    label: 'stock',
                    value: 5
                }
            ]
        },
        {
            id: 2,
            name: 'Monster Digital',
            location: 'Gujarat',
            price: 7499,
            shipping: [
                {
                    label: 'economy',
                    value: 125,
                    time: '4-5 days'
                },
                {
                    label: 'standard',
                    value: 200,
                    time: '2-3 days'
                },
                {
                    label: 'express',
                    value: 400,
                    time: '1-2 days'
                }
            ],
            productionTime: 1.5,
            printArea: [
                'Back Side',
                'Front Side'
            ],
            variant: {
                color: [
                    { label: 'Yellow', code: '#FFFF00', id: 4 },
                    { label: 'Orange', code: '#FFA500', id: 5 },
                    { label: 'Purple', code: '#800080', id: 6 },
                    { label: 'Pink', code: '#FFC0CB', id: 7 },
                    { label: 'Brown', code: '#A52A2A', id: 8 },
                    { label: 'Gray', code: '#808080', id: 9 },
                    { label: 'Black', code: '#000000', id: 10 },
                    { label: 'White', code: '#FFFFFF', id: 11 },
                    { label: 'Maroon', code: '#800000', id: 15 },
                    { label: 'Navy', code: '#000080', id: 16 },
                    { label: 'Olive', code: '#808000', id: 17 },
                    { label: 'Teal', code: '#008080', id: 18 },
                ],
                sizes: [
                    { label: 'Medium', code: 'M', id: 2 },
                    { label: 'Large', code: 'L', id: 3 },
                    { label: 'X-Large', code: 'XL', id: 4 },
                    { label: 'XX-Large', code: 'XXL', id: 5 },
                    { label: 'XXX-Large', code: '3XL', id: 6 }
                ]
            },
            rating: [
                {
                    label: 'quality',
                    value: 4.5
                },
                {
                    label: 'production',
                    value: 4.2
                },
                {
                    label: 'stock',
                    value: 4.7
                }
            ]
        },
    ],
    features: [
        {
            title: 'Shoulder tape',
            description: 'Twill tape covers the shoulder seams to stabilize the back of the garment and prevent stretching'
        },
        {
            title: 'Without side seams',
            description: 'Knitted in one piece using tubular knit, it reduces fabric waste and makes the garment more attractive'
        },
        {
            title: 'Ribbed knit collar without seam',
            description: 'Ribbed knit makes the collar highly elastic and helps retain its shape'
        },
        {
            title: 'Fabric',
            description: 'Made from specially spun fibers that make a very strong and smooth fabric that is perfect for printing. The "Natural" color is made with unprocessed cotton, which results in small black flecks throughout the fabric'
        },
        {
            title: 'Fiber composition',
            description: 'Solid colors are 100% cotton; Heather colors and Tweed are 50% cotton, 50% polyester; Sport Grey and Antique colors are 90% cotton, 10% polyester;'
        },
    ],
    description: "The unisex heavy cotton tee is the basic staple of any wardrobe. It is the foundation upon which casual fashion grows. All it needs is a personalized design to elevate things to profitability. The specially spun fibers provide a smooth surface for premium printing vividity and sharpness. No side seams mean there are no itchy interruptions under the arms. The shoulders have tape for improved durability.",
    care: [
        {
            label: 'Machine wash',
            value: 'warm (max 40C or 105F)'
        },
        {
            label: 'Non-chlorine',
            value: 'bleach as needed'
        },
        {
            label: 'Tumble dry',
            value: 'medium'
        },
        {
            label: 'Do not iron'
        },
        {
            label: 'Do not dryclean'
        }
    ]
};

const sizeChart = [
    {
        label: 'S',
        value: [18.00, 28.00, 15.10, 1.50]
    },
    {
        label: 'M',
        value: [20.00, 28.00, 16.50, 1.50]
    },
    {
        label: 'L',
        value: [22.00, 28.00, 18.00, 1.50]
    },
    {
        label: 'XL',
        value: [24.00, 28.00, 19.50, 1.50]
    },
    {
        label: 'XXL',
        value: [26.00, 29.00, 21.00, 1.50]
    },
    {
        label: '3XL',
        value: [28.00, 30.00, 22.40, 1.50]
    },
    {
        label: '4XL',
        value: [30.00, 31.00, 23.70, 1.50]
    },
    {
        label: '5XL',
        value: [32.00, 32.00, 25.00, 1.50]
    },
]

export {
    headerContent,
    subHeaderContent,
    sliderData,
    ProductSliderList,
    ProductSliderListTwo,
    testimonialsList,
    allProductData,
    productBarData,
    setColors,
    categorySliderData,
    productImageList,
    customFilter,
    orderList,
    subscriptionProduct,
    blogData,
    articleData,
    instaUserData,
    userSideBar,
    canvasSidebarData,
    productSingleData,
    sizeChart
};