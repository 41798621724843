import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PiStarFourFill } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import CommonFAQ from '../../Components/CommonFAQ'
import CommonInsights from '../../Components/CommonInsights'
import CommonBrands from '../../Components/CommonBrands'
import Slider from 'react-slick'
import { BsQuote } from "react-icons/bs";
import CommonClient from '../../Components/CommonClient'

function Integrations() {

    const integrationsData = [
        {
            name: "slack",
            tag: "Productivity",
            description: "Notify your teammates of the latest activities with instant Slack messages.",
            thumbnail: require('../../Assets/Images/template/back-company-1.png')
        },
        {
            name: "salesforce",
            tag: "CRM",
            description: "Leave a lasting impression all done inside Salesforce CPQ platform.",
            thumbnail: require('../../Assets/Images/template/back-company-2.png')
        },
        {
            name: "stripe",
            tag: "Payment",
            description: "Collect credit card and ACH payments within your sales documents.",
            thumbnail: require('../../Assets/Images/template/back-company-3.png')
        },
        {
            name: "paypal",
            tag: "Payment",
            description: "Provide a world-class checkout experience for your customers.",
            thumbnail: require('../../Assets/Images/template/back-company-4.png')
        },
        {
            name: "hubspot",
            tag: "CRM",
            description: "Expand the ROI from your CRM and unlock easy generation and capabilities.",
            thumbnail: require('../../Assets/Images/template/back-company-5.png')
        },
        {
            name: "zapier",
            tag: "Integration platforms",
            description: "Create custom, automated workflows using your favorite Zaps.",
            thumbnail: require('../../Assets/Images/template/back-company-6.png')
        }
    ]

    return (
        <div className='custom-integrations-page'>
            <Container className='cs-container-xl pb-4 pb-sm-5'>
                <Row className='gy-4 gy-md-5'>
                    <Col className='col-12 pb-0 pt-4 pt-sm-5 text-center integrations-title-style d-grid'>
                        <div className='py-md-4 d-grid gap-4'>
                            <h1 className='display-4 m-0 fw-semibold'>Integrations</h1>
                            <PiStarFourFill className='first-icon d-none d-lg-flex' />
                            <PiStarFourFill className='second-icon d-none d-lg-flex' />
                            <div className='flex-center-align'>
                                <p className='p-color m-0 cs-container-md fs-sm-12 fs-md-14'>
                                    Boost your efficiency with integrations that will simplify your workflow.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 integrations-list-style'>
                        <Row className='gy-3 gy-md-4'>
                            {
                                integrationsData.map((item, index) => (
                                    <Col key={index} className='col-12 col-sm-6 col-lg-4 d-grid'>
                                        <div className='integrations-list-style-item p-3 p-md-4 gap-2 gap-sm-3 gap-lg-4'>
                                            <div className='flex-between-align'>
                                                <div className='d-grid gap-1 text-start'>
                                                    <h5 className='fw-semibold m-0 text-capitalize'>{item?.name}</h5>
                                                    <span className='p-color fs-14 fs-sm-12'>{item?.tag}</span>
                                                </div>
                                                <div className='img-holder'>
                                                    <img src={item?.thumbnail} alt="" />
                                                </div>
                                            </div>
                                            <p className='m-0 text-start p-color'>{item?.description}</p>
                                            <Link className='d-flex gap-2 color-temp text-decoration-none fw-semibold'>
                                                Explore
                                                <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col className='col-12 pt-4 pt-sm-5'>
                        <CommonFAQ />
                    </Col>
                    <Col className='col-12 pt-4 pt-sm-5'>
                        <CommonClient />
                    </Col>
                </Row>
            </Container>
            <CommonBrands />
            <CommonInsights />
        </div>
    )
}

export default Integrations