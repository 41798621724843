import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom'

function FAQ() {

    const listData = [
        {
            question: 'Do I need to know about how to code?',
            answer: 'Yes, you need to have a fair amount of knowledge in dealing with HTML/CSS as well as JavaScript in order to be able to use Lexend.'
        },
        {
            question: 'Is Lexend free to use?',
            answer: 'Lexend offers both free and premium plans. The free plan includes basic features, while the premium plan provides access to advanced tools and customization options.'
        },
        {
            question: 'Can I integrate Lexend with my existing website?',
            answer: 'Yes, Lexend can be easily integrated into your existing website. It supports various platforms and provides plugins for seamless integration.'
        },
        {
            question: 'Is Lexend compatible with mobile devices?',
            answer: 'Absolutely! Lexend is fully responsive and works smoothly on all mobile devices, ensuring that your website looks great on any screen size.'
        },
        {
            question: 'Does Lexend offer customer support?',
            answer: 'Yes, Lexend offers 24/7 customer support through chat, email, and phone. There’s also an extensive knowledge base available for self-help.'
        },
        {
            question: 'How secure is my data with Lexend?',
            answer: 'Lexend takes security very seriously. Your data is encrypted and securely stored, and regular backups are performed to ensure data integrity.'
        },
        {
            question: 'Can I customize the templates provided by Lexend?',
            answer: 'Yes, Lexend allows you to fully customize the templates to match your brand’s identity. You can modify layouts, colors, fonts, and more.'
        },
        {
            question: 'Is there a trial period for Lexend’s premium plan?',
            answer: 'Yes, Lexend offers a 14-day free trial for its premium plan, allowing you to explore all the advanced features before committing to a subscription.'
        },
        {
            question: 'What payment methods does Lexend accept?',
            answer: 'Lexend accepts various payment methods including credit cards, PayPal, and bank transfers. Payment options are secure and flexible.'
        },
        {
            question: 'Can I cancel my Lexend subscription at any time?',
            answer: 'Yes, you can cancel your subscription at any time without any penalties. Your account will remain active until the end of your current billing cycle.'
        }
    ];

    return (
        <div className='py-0 py-md-5 custom-faq-tab'>
            <Container className='pt-0 pb-5 py-sm-5 cs-container-xl'>
                <Row>
                    <Col className='col-12 col-lg-6 d-grid align-content-start'>
                        <div className='custom-sticky-title'>
                            <div className='d-flex pb-4 pb-sm-5'>
                                <div className='lg-max-w-750px max-w-600px d-grid gap-3 gap-sm-4'>
                                    <div className='d-flex justify-content-center justify-content-lg-start'>
                                        <div className='custom-home-title-light'>
                                            <GoDotFill className='fs-12' />
                                            <span className='fs-12'>FAQs</span>
                                        </div>
                                    </div>
                                    <h2 className='fw-semibold m-0 text-center text-lg-start'>Frequenlty Asked Questions</h2>
                                    <p className='m-0 p-color text-center text-lg-start'>We’re looking for people who share our vision! most of our time used to be taken up by most of who created new websites for thier businesses.</p>
                                    <div className='d-flex justify-content-center justify-content-lg-start'>
                                        <Link className='custom-faq-btn d-flex fw-medium gap-2'>Still have a question? <i className="bi bi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-6 d-grid custom-faq-list'>
                        <Accordion alwaysOpen>
                            {
                                listData.map((item, index) => {
                                    return (
                                        <Accordion.Item eventKey={index} key={index}>
                                            <Accordion.Header>{item?.question}</Accordion.Header>
                                            <Accordion.Body>
                                                {item?.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FAQ