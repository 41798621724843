import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoShirt } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { IoHomeSharp } from "react-icons/io5";
import { FaBeerMugEmpty } from 'react-icons/fa6'

function ProductListSlider() {

    const clothing = [
        {
            "label": "t-shirts",
            "value": 16
        },
        {
            "label": "hoodies",
            "value": 17
        },
        {
            "label": "sweatshirts",
            "value": 18
        },
        {
            "label": "long sleeves",
            "value": 19
        },
        {
            "label": "tank tops",
            "value": 20
        },
        {
            "label": "skirts and dresses",
            "value": 21
        },
        {
            "label": "sportswear",
            "value": 22
        },
        {
            "label": "bottoms",
            "value": 23
        },
        {
            "label": "swimwear",
            "value": 24
        },
        {
            "label": "shoes",
            "value": 25
        }
    ]

    const accessories = [
        {
            "label": "mugs",
            "value": 51
        },
        {
            "label": "glassware",
            "value": 52
        },
        {
            "label": "bottles and tumblers",
            "value": 53
        },
        {
            "label": "canvas",
            "value": 54
        },
        {
            "label": "posters",
            "value": 55
        },
        {
            "label": "postcards",
            "value": 56
        },
        {
            "label": "ornaments",
            "value": 57
        },
        {
            "label": "journals & notebooks",
            "value": 58
        },
        {
            "label": "magnets and stickers",
            "value": 59
        },
        {
            "label": "home decor",
            "value": 60
        },
        {
            "label": "bathroom",
            "value": 61
        },
        {
            "label": "towels",
            "value": 62
        },
        {
            "label": "blankets",
            "value": 63
        },
        {
            "label": "pillow and covers",
            "value": 64
        },
        {
            "label": "games",
            "value": 65
        },
        {
            "label": "rungs and mats",
            "value": 66
        },
        {
            "label": "seasonal decorations",
            "value": 67
        }
    ]

    const home = [
        {
            "label": "mugs",
            "value": 51
        },
        {
            "label": "glassware",
            "value": 52
        },
        {
            "label": "bottles and tumblers",
            "value": 53
        },
        {
            "label": "canvas",
            "value": 54
        },
        {
            "label": "posters",
            "value": 55
        },
        {
            "label": "postcards",
            "value": 56
        },
        {
            "label": "ornaments",
            "value": 57
        },
        {
            "label": "journals & notebooks",
            "value": 58
        },
        {
            "label": "magnets and stickers",
            "value": 59
        },
        {
            "label": "home decor",
            "value": 60
        },
        {
            "label": "bathroom",
            "value": 61
        },
        {
            "label": "towels",
            "value": 62
        },
        {
            "label": "blankets",
            "value": 63
        },
        {
            "label": "pillow and covers",
            "value": 64
        },
        {
            "label": "games",
            "value": 65
        },
        {
            "label": "rungs and mats",
            "value": 66
        },
        {
            "label": "seasonal decorations",
            "value": 67
        }
    ]

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        initialSlide: 0,
        infinite: true,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    var reverseSettings = {
        ...settings,
        rtl: true
    };

    return (
        <div className='pb-5 pt-0 custom-product-list-slider'>
            <Container className='cs-container-xl py-2 py-sm-3' data-aos="fade-up">
                <h4 className='fw-semibold m-0 text-center pb-3 pb-sm-4'>125+ Various Clothing & Home related items</h4>
                <Row className='gy-3 gy-md-4 custom-slider-list'>
                    <Col className='col-12'>
                        <div className="slider-container">
                            <Slider {...settings} className='align-items-center'>
                                {
                                    clothing.map((item, index) => (
                                        <div key={index} className='item-holder text-center p-2 py-2 py-md-3'>
                                            <div className='flex-center-align flex-wrap gap-2'>
                                                <IoShirt className='fs-20 color-temp fs-sm-12' />
                                                <h6 className='m-0 text-capitalize fs-sm-12'>
                                                    {(item?.label?.length > 12) ? `${item?.label.substring(0, 12)}...` : item?.label}
                                                </h6>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </Col>
                    <Col className='col-12'>
                        <div className="slider-container">
                            <Slider {...reverseSettings} className='align-items-center'>
                                {
                                    accessories.map((item, index) => (
                                        <div key={index} className='item-holder text-center p-2 py-2 py-md-3'>
                                            <div className='flex-center-align flex-wrap gap-2'>
                                                <FaBeerMugEmpty className='fs-20 color-temp fs-sm-12' />
                                                <h6 className='m-0 text-capitalize fs-sm-12'>
                                                    {(item?.label?.length > 12) ? `${item?.label.substring(0, 12)}...` : item?.label}
                                                </h6>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </Col>
                    <Col className='col-12'>
                        <div className="slider-container">
                            <Slider {...settings} className='align-items-center'>
                                {
                                    home.map((item, index) => (
                                        <div key={index} className='item-holder text-center p-2 py-2 py-md-3'>
                                            <div className='flex-center-align flex-wrap gap-2'>
                                                <IoHomeSharp className='fs-20 color-temp fs-sm-12' />
                                                <h6 className='m-0 text-capitalize fs-sm-12'>
                                                    {(item?.label?.length > 12) ? `${item?.label.substring(0, 12)}...` : item?.label}
                                                </h6>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ProductListSlider