import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom'

function PriceList() {

    const listData = [
        [
            "Use on 1 website",
            "Personal License",
            "20 Basic Elements",
            "Basic Dynamic Data",
            "Basic Design Library",
            "Basic support"
        ],
        [
            "Use on 1 website",
            "Personal License",
            "100 pro Elements",
            "Full Dynamic Data",
            "Full Design Library",
            "Priority support"
        ],
        [
            "Use on unlimited websites",
            "Commercial License",
            "100 Basic Elements",
            "Full Dynamic Data",
            "Full Design Library",
            "Priority support"
        ]
    ]

    return (
        <div className='custom-price-list'>
            <Container fluid className='p-0 p-lg-3'>
                <Container fluid className='custom-img-container p-0'>
                    <Container className='custom-img-section'>
                        <div className='custom-img-section-contain-color'></div>
                        <div className='custom-img-section-contain'></div>
                        <div className='custom-img-section-contain-blend-center'></div>
                        <div className='custom-img-section-contain-blend-left'></div>
                        <div className='custom-img-section-contain-blend-right'></div>
                        <div className='custom-slider-content pt-5 gap-4 gap-sm-4 d-grid'>
                            {/* Content Part */}
                            <div className='custom-slider-content-list flex-center-align align-items-start' data-aos="fade-up">
                                <div className='custom-slider-content-list-first lg-max-w-750px max-w-600px gap-4 d-grid'>
                                    <div className='flex-center-align'>
                                        <div className='custom-home-title'>
                                            <GoDotFill className='fs-12' />
                                            <span className='fs-12'>Pricing</span>
                                        </div>
                                    </div>
                                    <div className='text-center custom-slider-heading d-grid gap-4 px-4 px-sm-0'>
                                        <h1 className='h1 m-0 d-flex justify-content-center gap-2 flex-wrap'>
                                            Simple
                                            <span>scalable</span>
                                            pricing.
                                        </h1>
                                        <p className='m-0 d-none d-md-block'>No extra charges. No hidden fees.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Image Part */}
                            <Container className='custom-slider-content-list-second cs-container-xl p-0 pb-5 text-center'>
                                <Row className='justify-content-center gy-4 gy-xl-0'>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="0">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                <b className='fw-semibold fs-20'>Free</b>
                                                <h2 className='m-0 display-4 fw-semibold'>
                                                    ₹0
                                                    <span className='h2 fw-semibold'>/ mo</span>
                                                </h2>
                                                <p className='m-0 px-sm-4'>
                                                    Build a <b className='fw-semibold'>hobby site</b> with every basic features.
                                                </p>
                                                <Link className='fw-medium fs-18 fs-sm-16'>Get started</Link>
                                                <span className='fs-sm-14'>Free Forever!</span>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                {
                                                    listData[0].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-25 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="150">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                <b className='fw-semibold fs-20'>Pro</b>
                                                <h2 className='m-0 display-4 fw-semibold'>
                                                    ₹499
                                                    <span className='h2 fw-semibold'>/ mo</span>
                                                </h2>
                                                <p className='m-0 px-sm-4'>
                                                    Build a <b className='fw-semibold'>one website</b> with every pro feature.
                                                </p>
                                                <Link className='fw-medium fs-18 fs-sm-16'
                                                    style={{ backgroundColor: '#12715B', color: 'white' }}
                                                >Get started</Link>
                                                <span className='fs-sm-14'>Billed annually.</span>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                {
                                                    listData[1].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-25 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='custom-lable fs-14 fw-medium'>Save $25</div>
                                        </div>
                                    </Col>
                                    <Col className='col-12 col-sm-6 col-xl-4' data-aos="fade-up" data-aos-delay="300">
                                        <div className='price-list-item p-3'>
                                            <div className='first-content d-grid p-3 p-sm-4 gap-3'>
                                                <b className='fw-semibold fs-20'>Agency</b>
                                                <h2 className='m-0 display-4 fw-semibold'>
                                                    ₹1499
                                                    <span className='h2 fw-semibold'>/ mo</span>
                                                </h2>
                                                <p className='m-0 px-sm-4'>
                                                    Build a <b className='fw-semibold'>unlimited websites</b> every pro feature.
                                                </p>
                                                <Link className='fw-medium fs-18 fs-sm-16'>Get started</Link>
                                                <span className='fs-sm-14'>Billed annually.</span>
                                            </div>
                                            <div className='second-content d-grid p-3 pt-sm-4 gap-3'>
                                                {
                                                    listData[2].map((item, index) => {
                                                        return (
                                                            <div key={index} className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <i className="bi bi-check fs-25 fs-sm-14"></i>
                                                                </span>
                                                                <p className='m-0'>{item}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <span className='text-center text-light my-5'>Prices exclude any applicable taxes.</span>
                                </Row>
                            </Container>
                        </div>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}

export default PriceList