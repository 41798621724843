import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { PiStarFourFill } from 'react-icons/pi'
import { BsDot } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Redirection from '../../../Components/Redirection/Redirection';
import NewsLetter from '../../../Components/NewsLetter';
import { frontEnd_API, header, SERVER_URL } from '../../../Config/Config';
import axios from 'axios';

function BlogHome() {

    const [data, setData] = useState();
    const [loader, setLoader] = useState(true);
    const getCategoryBlog = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.blogcategory, header)
            console.log("data::", data);
            setData(data?.data)
            setLoader(false);
        }
        catch (e) {
            console.log("err::", e);
            setLoader(false);
        }
    }

    useEffect(() => {
        getCategoryBlog();
    }, [])

    const handleRedirect = (url) => {
        if (url) {
            const kebabCaseStr = url.toLowerCase().replace(/-/g, '~').replace(/\s+/g, '-');
            return kebabCaseStr;
        }
    };

    return (
        <div className='custom-blog-home'>
            <Container className='cs-container-xl pb-5'>
                <Row className='gy-4 gy-md-5'>
                    <Col className='col-12 pb-0 pt-4 pt-sm-5 blog-home-style-peta'>
                        <h1 className='display-4 text-center m-0 fw-semibold py-md-4'>Insights</h1>
                        <PiStarFourFill className='first-icon d-none d-md-flex' />
                        <PiStarFourFill className='second-icon d-none d-md-flex' />
                    </Col>
                    <Col className='col-12'>
                        <div className='d-grid p-3 p-sm-4 d-md-flex flex-between-align gap-3 first-item'>
                            <div className='w-100'>
                                <div className='img-holder-square'>
                                    <img src={require('../../../Assets/Images/img-05.jpg')} alt="" />
                                </div>
                            </div>
                            <div className='w-100 d-grid align-content-center gap-2 gap-sm-3 gap-lg-4 text-center first-item-content'>
                                <div className='flex-center-align'>
                                    <span className='p-1 p-lg-2 fs-14 fw-semibold lh-sm btn-top'>Strategy</span>
                                </div>
                                <h2 className='fw-semibold m-0 fs-lg-20'>Maximizing your reach with marketing strategies</h2>
                                <div className='flex-center-align gap-1'>
                                    <div className='first-item-content-avatar'>
                                        <img src={require('../../../Assets/Images/02.png')} alt="" />
                                    </div>
                                    <h6 className='m-0 fs-14 fw-semibold'>David</h6>
                                    <BsDot className='p-color' />
                                    <span className='p-colot fs-12'>Apr 15, 2024</span>
                                </div>
                                <p className='m-0 px-md-4 fs-lg-14'>Nisi dignissim tortor sed quam sed ipsum ut. Dolor sit amet, consectetur adipiscing elit..</p>
                                <div className='flex-center-align'>
                                    <Link className='cnt-btn fw-semibold'>Continue reading</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {
                        (data?.length > 0) &&
                        data.map((item, index) => (
                            <Col key={index} className='col-12 col-md-4 d-grid'>
                                <div className='custom-insights-list-item p-3 d-grid gap-3 gap-sm-4'>
                                    <Link to={handleRedirect(item?.label)} className='img-holder-in'>
                                        <img src={SERVER_URL + item?.thumbnail} alt="" />
                                        <span className='fw-semibold p-2 fs-12 lh-sm'>{item?.label}</span>
                                    </Link>
                                    <div className='d-grid text-center gap-1'>
                                        <Link to={handleRedirect(item?.label)} className='fw-semibold h4 fs-sm-18'>{item?.description}</Link>
                                        {/* <div className='flex-center-align gap-1 d-none d-md-flex'>
                                          <div className='first-item-content-avatar'>
                                              <img src={item?.avatar} alt="" />
                                          </div>
                                          <h6 className='m-0 fs-14 fw-semibold'>{item?.name}</h6>
                                          <BsDot className='p-color' />
                                          <span className='p-colot fs-12'>{item?.date}</span>
                                      </div> */}
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                    <Col className='col-12'>
                        <NewsLetter />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BlogHome